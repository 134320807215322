import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash'
import { indexOf } from 'lodash'
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import Rating from 'react-rating';
import { connect } from "react-redux";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import StarsRating from 'stars-rating';
import { clear_category, clear_subcategory, fetch_categories_data, fetch_detailedstarrating, fetch_sub_categories, filter_categories_data, getCaptcha, get_business_detail, get_business_location_weather, get_business_QA_dropdown, get_business_review_detail, get_business_wall_data, get_discuss_this_page, get_id_from_slug, get_media_children, get_simillar_business, get_tips_and_warnings, post_business_sign_up, post_comment_on_offers, post_discuss_this_page, post_other_offers, post_tips_and_warnings, post_wall_comments, publish_post, put_special_offer, put_wall_reply, save_ratings_vote, share_by_email, add_to_list, remove_from_list, get_working_employee, post_people_working, delete_people, menuItemChangedStatus } from '../../actions/business';
import { embedNewLink } from '../../actions/messages';
import { clear_media_dispatch, delete_mutiple_media, fetch_delete_media, fetch_media_data, fetch_taxonomy_category, fetch_taxonomy_list, media_upload_scroll, single_delete_from_upload, upload_add_captions, upload_media_files, upload_media_filesData } from "../../actions/myProfiles";
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from '../atoms/collapse';
import WebCamRecording from '../oragnisms/businessDetails/webCamRecording';
import { my_profile } from "../../actions/user";
import RatingCollapse from '../atoms/ratingCollapse';
import { get_category_taxonomy_listing, update_caption_and_categories } from '../../actions/itemDetail';
import { mediaThumbnail } from '../../utils/utilities';

const empty_star_rating = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "34px" }}>★</span>);

const full_star_rating = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "34px" }}>★</span>);

const empty_star_rating_below = (<span className="" data-index="4" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(215, 216, 226)", fontSize: "24px" }}>★</span>);

const full_star_rating_below = (<span className="" data-index="0" data-forhalf="★" style={{ position: "relative", overflow: "hidden", cursor: "pointer", display: "block", float: "left", color: "rgb(21, 195, 212)", fontSize: "24px" }}>★</span>);

const group_icons = 'https://stagingdatawikireviews.s3.amazonaws.com/images/group-icon.png';


class CreateReviewBusiness extends Component {
    constructor(props) {
        super(props)

        this.myScrollPhotoRef = React.createRef();
        this.myScrollVideoRef = React.createRef();

        this.refGalleryVideoScroll = React.createRef();
        this.refGalleryImageScroll = React.createRef();

        this.state = {
            showRevTips: false,
            temperature_C_Blue: null,
            temperature_F_Blue: null,
            enableRatingText: null,
            enableHoverBanIcon: false,
            titleRatingText: null,
            tempIcon: null,
            enableStickyHeader: false,
            enableErrorForReview: true,
            embedBody: [],
            stepTwoUploadedVideo: [],
            reviewDynamicItems: {},
            businessId: null,
            viewBusinessAs: 'about',
            createNewDisModalToggle: false,
            exploreMenuItemsModalToggle: false,
            activeMenuType: 'mainMenu',
            mainViewType: 'reviews', //'wall'
            viewEditReviewStep: 'writeReview',
            viewWallUpdatesType: 'all',
            setRatingFrom: 'Overall Rating',
            businessDetails: null,
            simillarBusiness: null,
            tipsAndWarnings: null, //dummyTipsWarning,
            isToggleTips: false,
            isToggleWorkingPeople: false,
            isToggleRecommendPeople: false,
            isToggleWarning: false,
            tipsText: '',
            warningText: null,
            // modalText: '',
            editId: null,
            deleteItem: null,
            deleteOffer: null,
            businessRatingList: [],
            host: window.location.host,
            protocol: window.location.protocol,
            otherOffers: {
                isCheckInOfferToggle: false,
                inStoreCouponToggle: false,
                inOtherOffer: false,
            },
            offerCheck: {
                special_offer: "",
                startDate: "",
                from_date: new Date(),
                endDate: "",
                to_date: new Date(),
                claimDealOption: "",
                terms_details_exclusions: "",
                offer_type: "",
                other_offer: "",
                reviews_userentries: '',
                validateForSubmit: true,
                is_offer_edit: false
            },
            stateOtherOffers: null,
            discussThisPage: null, // dummyDiscussThisPage,
            createNewDiscussion: {
                tittle: '',
                body: '',
                media: []
            },
            filterSelectCompose: {},
            messageText: '',
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            addressnull: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            dataWantsToDelete: [],
            confirmDeleteModal: false,
            limitedPostsModal: false,
            uploadMediaModal: false,
            uploadMediaPostModal: false,
            isModalTrue: false,
            modalText: null,
            modalType: null,
            modalSelected: null,
            itemsAttachment: [],
            reviewsCategories: [],
            itemsIndex: 1,
            showVideoMedia: true, // video option toggle
            businessValues: {
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                verificationCaptcha: ''
            },
            destinationDetails: {
                latitude: 0,
                longitude: 0,
                travelMode: 'DRIVING',
                addressTo: ''

            },
            routes: [],
            setWallDataItems: {},
            avg_rating: 0,
            enableRating: false,
            AbilitytoResolveDisputes: 0,
            TurnaroundTime: 0,
            AttentiontoDetail: 0,
            QualityofDryCleaning: 0,
            QualityofEquipment: 0,
            write_new_review: '',
            writeAReviewTextVideo: '',
            QualityofEquipmentCheck: false,
            reviewAnonymous: false,
            shocialNetworkSharing: false,
            ReviewTipsGuidelines: false,
            enableScrollForWall: true,
            setCurrentWallPage: 1,
            wallLoading: false,
            wallReplyVisibility: {},
            wallReplyText: {},
            deleteComment: null,
            wallCommentEdit: false,
            dicussionThisText: '',
            dicussionThisBody: '',
            profileInfo: null,
            isEditOffer: false,
            vidoeCaptionCategory: '',
            vidoeCaption: '',
            isadhere: true,
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            isImageType: false,
            captionData: "",
            uploadedId: {},
            cattaxListing: {},
            albumCategoryId: "",
            friendsProfileRecommend: null,
            friendsProfileFavourite: null,
            friendsProfileWant: null,
            chartStarRatings: null,
            dropdownSortBy: false,
            clickedOnWriteAReview: -1,

            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            forceShowLoginModal: false,
            openVerifyModal: false,
            opennFormallyNameChangedModal: false,

            cite_source_link: '',
            cite_source_supporting_file: '',
            cite_source_radio: '',
            selectedSiteSourceFilesPreview: [],
            SiteSourcefiles: '',
            submitDisabled: true,
            bussiness_moved: false,
            formally_name_chaged: false,
            business_closed: false,
            get_temp: '',
            working_employee: null,
            peopleWorkingState: {},
            workingEmployee: null,
            categoryItemQueryParams: {
                object_pk: props?.productId,
                content_type: 'reviews.userentries',
                key: 'avg_rating',
            },
            avgRateKey: null,
            ratingOn: false,
            embeddedLink: [],
            rateMenu: [],
            rateMenuContent: [],
            uploadType: "",
            mediaSetMenu: [],
            menuTitle: "",
            menuSubTitle: "",
            menuMediaList: [],
            menuMediaListMedia: {},
            menuObjectPk: "",
            disableCreateReview: true
        }
    }

    setClickedTabOperation = ({ value }) => {
        if (value > 1) {
            this.setState({ clickedOnWriteAReview: value, viewEditReviewStep: "writeReview" });
        } else {
            this.setState({ clickedOnWriteAReview: value });
        }
    }


    fetchDataFromHtmlForm = async ({ businessId }) => {
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let uniqrate = doc.getElementById('uniqrate').value;
                    let csrfmiddlewaretoken = doc.getElementsByName('csrfmiddlewaretoken')[0].value;
                    //let security_hash = doc.getElementsByName('security_hash')[0].value;
                    //let timestamp = doc.getElementsByName('timestamp')[0].value;
                    //let object_pk = doc.getElementsByName('object_pk')[0].value;
                    //let content_type = doc.getElementsByName('content_type')[0].value;
                    let security_hash = "";
                    let timestamp = Date.now();
                    let honeypot = doc.getElementsByName('honeypot')[0].value;
                    let object_pk = this.state.businessId;
                    let content_type = "reviews.userentries";
                    let reviewDynamicItems = {};
                    reviewDynamicItems.uniqrate = uniqrate;
                    reviewDynamicItems.csrfmiddlewaretoken = csrfmiddlewaretoken;
                    reviewDynamicItems.security_hash = security_hash;
                    reviewDynamicItems.timestamp = timestamp;
                    reviewDynamicItems.honeypot = honeypot;
                    reviewDynamicItems.object_pk = object_pk;
                    reviewDynamicItems.content_type = content_type;

                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    updateSecurityHash = async ({ businessId }) => {
        let { reviewDynamicItems } = this.state;
        let url = `/form/reviewnewlisting/business/${businessId}`;
        try {
            const response = await callApi(url, 'GET');
            if (response?.code == 200) {
                let data = response?.data;
                if (data) {
                    const doc = new DOMParser().parseFromString(data, "text/html")
                    let security_hash = doc.getElementsByName('security_hash')[0].value;
                    reviewDynamicItems.security_hash = security_hash;
                    this.setState({ reviewDynamicItems });
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    addUploadedVideo = (item) => {
        let { stepTwoUploadedVideo } = this.state;
        stepTwoUploadedVideo.push(item);
        this.setState({ stepTwoUploadedVideo });

        if (Array.isArray(stepTwoUploadedVideo) && stepTwoUploadedVideo.length > 0) {
            this.setState({ enableErrorForReview: false })
        }

    }

    removeUploadedVideo = () => {
        this.setState({ stepTwoUploadedVideo: [], enableErrorForReview: true });
    }


    handleCloseAndFetchReview = () => {

        let { mediaSetReview } = this.state;
        this.setState({ uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: true, mediaSet: mediaSetReview, taxonomycategoryId: "", fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, categoryType: "", uploadedId: {}, uploadType: '' }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }


    handleCloseAndFetch = () => {
        let { latestUploadedData, fileReviewStatus, mediaSetReview } = this.state;

        if (fileReviewStatus && Object.values(latestUploadedData) && Object.values(latestUploadedData)[0] && Object.values(latestUploadedData)[0].id) {
            Object.values(latestUploadedData).map((list) => {
                mediaSetReview.push({
                    "id": list.id,
                    "file": list.url,
                    "thumbnail": list.thumbnail,
                    "url": list.url,
                })
            })

        }
        this.setState({
            uploadMediaModal: !this.state.uploadMediaModal, fileReviewStatus: false, mediaSetReview, taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}
        }, () => {
            this.clearMediaDispatch();
            this.props.fetch_media_data('image', '', 1, true, true);
            this.props.fetch_media_data('video', '', 1, true, true);
        });
    }


    componentWillMount() {
        this.checksAndRenderById();
    }


    checksAndRenderById = () => {

        let { businessId, mainViewType, businessDetails } = this.props;
        let id = businessId;

        if (id) {

            this.setState({ businessId: id, businessDetails: businessDetails }, () => {
                this.fetchByBusinessId({ businessId: this.state.businessId });
            });
        }


        if (mainViewType && mainViewType != "") {
            this.setState({ mainViewType: mainViewType });
        }
    }

    componentWillReceiveProps = (nextProps) => {

        let { fetchPhotos, fetchPhotosCount, fetchVideos, fetchVideosCount, fetchGalleryScroll, fetchGalleryCount, fetchDeletedData, fetchDeleteCount, activeMyPhotoIndex, latestUploadedData, } = this.state;


        if (nextProps?.chart_star_ratings) {
            this.setState({ chartStarRatings: nextProps?.chart_star_ratings });
        }

        if (nextProps.fetch_photos) {
            let item = nextProps.fetch_photos;
            if (item.album) { fetchPhotos = [] }
            fetchPhotos[item.page] = item;
            fetchPhotosCount = item.count;
            this.setState({ fetchPhotos, fetchPhotosCount, activeMyPhotoIndex, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_videos) {
            let item = nextProps.fetch_videos;
            if (item.album) { fetchVideos = [] }
            fetchVideos[item.page] = item;
            fetchVideosCount = item.count;
            this.setState({ fetchVideos, fetchVideosCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.fetch_taxonomy) {
            let taxCat = nextProps.fetch_taxonomy;
            if (taxCat && Array.isArray(taxCat) && taxCat.length > 0) {
                taxCat = taxCat.filter(dt => dt.label !== null);
            }
            this.setState({ fetchTaxonomy: taxCat })
        }

        if (nextProps.fetch_deleted_data) {
            let item = nextProps.fetch_deleted_data;
            if (!item.type) { fetchDeletedData = {} }
            fetchDeletedData[item.page] = item;
            fetchDeleteCount = item.count;
            this.setState({ fetchDeleteCount, fetchDeletedData, activeMyPhotoIndex: 0 });
        }

        if (nextProps.upload_scroll) {
            let item = nextProps.upload_scroll;
            if (item.album) { fetchGalleryScroll = [] }
            fetchGalleryScroll[item.page] = item;
            fetchGalleryCount = item.count;
            this.setState({ fetchGalleryScroll, fetchGalleryCount, activeMyPhotoIndex: 0 });
        }

        if (nextProps.my_profile && Object.keys(nextProps.my_profile).length > 0) {
            let data = nextProps.my_profile;
            if (data?.user) {
                data['name_full'] = `${data?.user?.first_name} ${data?.user?.last_name}`
            }
            this.setState({
                myProfile: data,
            });
        }

        if (nextProps.upload_media_data) {
            let data = nextProps.upload_media_data;
            if (data.index !== undefined) {
                let idx = data.index;
                latestUploadedData[idx] = data;
                this.setState({ latestUploadedData });
            }
        }

        if (nextProps?.my_profile) {
            this.setState({ profileInfo: nextProps?.my_profile });
        }

        if (nextProps?.cat_taxonomy_list &&
            Array.isArray(nextProps?.cat_taxonomy_list) &&
            nextProps?.cat_taxonomy_list?.length) {
            let cattaxListing = [];
            nextProps.cat_taxonomy_list.forEach(item => {
                cattaxListing = cattaxListing.concat(item.children);
            })
            this.setState({ cattaxListing });
        }

        if (nextProps.fetch_data_categories) {
            let { fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state;
            let taxCatListing = nextProps.fetch_data_categories;
            if (taxCatListing && Array.isArray(taxCatListing) && taxCatListing.length > 0) {
                if (taxCatListing[0].isTypes == "image") {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingImages[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingImages });
                    }

                } else {
                    if (taxCatListing[0].index !== undefined) {
                        let idx = taxCatListing[0].index;
                        fetchTaxonomyListingVideos[idx] = taxCatListing[0].children ? taxCatListing[0].children : [];
                        this.setState({ fetchTaxonomyListingVideos });
                    }
                }
            }

        }

        if (nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)) {
            //this.props.my_profile_info();
            window.location.reload();
        }

        if (nextProps?.toggleStarRating && Object.keys(nextProps?.toggleStarRating).length) {
            const { enable_rating, disable_create_review } = nextProps?.toggleStarRating;
            this.setState({ enableRating: enable_rating, disableCreateReview: disable_create_review });
        }

        if (nextProps.business_reviewDetail && Array.isArray(nextProps.business_reviewDetail.results)) {
            if (!this.state.initialReviewListing) this.setState({ initialReviewListing: nextProps.business_reviewDetail.results })

            if (!this.state.initialReviewListing?.length && nextProps.business_reviewDetail.results?.length)
                this.setState({ initialReviewListing: nextProps.business_reviewDetail.results })

            if (nextProps.business_reviewDetail.results.length > 0) {

                if (!nextProps.business_reviewDetail.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id)) {
                    if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                }
            } else if (nextProps.business_reviewDetail.results.length === 0) {
                if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
            }
        }

        if (nextProps.menuItemChanged !== this.props.menuItemChanged) {
            const { businessId } = this.state
            this.fetchRateMenuItems({ businessId });
        }

        if (nextProps.qa_dropdown &&
            Array.isArray(nextProps.qa_dropdown.res) &&
            nextProps.qa_dropdown.res.length > 0) {
            this.setState({ reviewsCategories: nextProps.qa_dropdown });
        }
    }

    setMediaSetForItems = (e, item) => {
        // e.preventDefault();
        let { mediaSet, mediaSetMenu, otherOffers, offerCheck, uploadType, rateMenuContent, menuTitle, menuSubTitle, menuItemContent, businessId, menuMediaList, menuMediaListMedia } = this.state;
        if (item && item.id && uploadType !== "menuItem") {
            let id = item.id;
            let itemFiltered = mediaSet.find(item => item.id === id);

            if (itemFiltered) {
                const index = mediaSet.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSet.splice(index, 1);
                }
            } else {
                mediaSet.push(item)
            }

            if (otherOffers.inStoreCouponToggle) {
                if (mediaSet.length > 0) { offerCheck['validateForSubmit'] = false; }
                else { offerCheck['validateForSubmit'] = true; }
            }

            this.setState({ mediaSet: [...mediaSet], mediaSetReview: [...mediaSet], offerCheck });
        } else {
            let id = item.id;
            let itemFiltered = mediaSetMenu.find(item => item.id === id);

            if (itemFiltered) {
                const index = mediaSetMenu.indexOf(itemFiltered);
                if (index > -1) {
                    mediaSetMenu.splice(index, 1);
                }
            } else {
                mediaSetMenu.push(item)
            }
            this.setState({ mediaSetMenu: [...mediaSetMenu] })

            if (!!menuMediaListMedia[this.state.menuObjectPk]) {
                const collectedMedia = [...menuMediaListMedia[this.state.menuObjectPk], item]
                const uniqueMedia = [...new Map(collectedMedia?.map(item =>
                    [item['id'], item])).values()];
                menuMediaListMedia[this.state.menuObjectPk] = uniqueMedia
            } else {
                menuMediaListMedia[this.state.menuObjectPk] = [item]
            }
            let rateMenuContentCopy = _.cloneDeep(rateMenuContent)
            rateMenuContentCopy.filter(item => item.menuItem === menuTitle)[0].menuContent.filter(subItem => subItem.menuItemName === menuSubTitle)[0].menuItemContent[5] = item.thumbnail


            this.setState({ rateMenuContent: rateMenuContentCopy })

        }
    }

    handleCaptionByIndex = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData, taxonomycategoryId } = this.state;
        uploadCaptions[index] = value;
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = taxonomycategoryId;
        let id = upload_data.id;
        this.setState({ uploadCaptions, captionData: caption_data }, () => {
            // this.props.upload_add_captions(id, data)});
            this.props.update_caption_and_categories({ id, data });
        })
    }

    checkIsAdded = (item) => {
        let mediaSetContainer
        if (this.state.uploadType === "menuItem") {
            mediaSetContainer = this.state.menuMediaListMedia[this.state.menuObjectPk] || [];
        } else {
            mediaSetContainer = this.state.mediaSet;
        }
        let id = item.id;
        let itemFiltered = mediaSetContainer.find(item => item.id === id);
        if (itemFiltered) {
            return true;
        } else {
            return false;
        }
    }

    removeFromMedia = (e, item, type) => {
        e.preventDefault();
        let { mediaSet } = this.state;
        let id = item.id;
        let itemFiltered = mediaSet.find(item => item.id === id);
        if (itemFiltered) {
            const index = mediaSet.indexOf(itemFiltered);
            if (index > -1) {
                mediaSet.splice(index, 1);
            }
            // call delete api here 
            // delete_mutiple_media
            this.setState({ mediaSet: [...mediaSet], mediaSetReview: [...mediaSet] }, () => {
                this.props.single_delete_from_upload(id, type);
            });
        }

    }

    fetchScrollPhotoMedia = (isVideo) => {
        let { fetchGalleryScroll } = this.state;
        let getDataItems = [];
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            Object.keys(fetchGalleryScroll).forEach(item => {
                if (fetchGalleryScroll[item] && fetchGalleryScroll[item].results !== null) {
                    let data = fetchGalleryScroll[item].results;
                    getDataItems = getDataItems.concat(data);
                }
            });

            if (getDataItems && getDataItems.length > 0) {
                return getDataItems.map(item => {
                    let itemName = (item?.thumbnail)?.split('.');
                    let itemFile = (item?.file)?.split('.');
                    let extName = itemName?.[itemName.length - 1];
                    let extFile = itemFile?.[itemFile.length - 1];
                    let srcFile = extFile !== extName ? item?.thumbnail : imagePreview
                    item['srcFile'] = isVideo ? srcFile : item?.file;
                    return (
                        <Col xs="auto" className="mb-3" >
                            <FormGroup className="checkable-media" check style={{ width: '120px' }}>
                                <Label check>
                                    <Input type="checkbox" onClick={(e) => { this.setMediaSetForItems(e, item) }} checked={this
                                        .checkIsAdded(item)} />
                                    <div className="gallery-media" >
                                        <img className="img-fluid" src={isVideo ? srcFile : item?.file} alt="logo Pics" />
                                    </div>
                                </Label>
                                <div className="font-weight-bold fs-14 text-secondary-dark mb-0 text-truncate">{item.caption ? item.caption : 'No Caption'}</div>
                                <div className="font-weight-normal fs-14 d-block">{item.category ? item.category : 'No Category'}</div>
                            </FormGroup>
                        </Col>
                    )
                })
            }
        } else {
            return getDataItems;
        }
    }

    checkIfVideo = (item) => {
        const imagePreview = `https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/__video.fc79d88d9ecc4e53db53e6d755d577d05bafc9ad.8428893794ac15cc81374a98aa83be7d1d23b844.jpg`

        let itemFile = (item && item.file).split('.');
        // let extName = itemName !== "" ? itemName[itemName.length - 1] : "";
        let extFile = itemFile[itemFile.length - 1];
        let srcFile = imagePreview;

        if (['jpg', 'jpeg', 'png', 'gif'].includes(extFile)) {
            srcFile = item.file;
        }
        return srcFile;
    }

    handleOnFileUploadChange = (e) => {
        let { selectedFilesPreview, files } = this.state;
        const filesSetForUpload = Array.from(e.target.files);
        files = files.concat(filesSetForUpload);
        let self = this;
        this.setState({ files }, () => {
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            })).then(images => {
                let concatWithPrev = selectedFilesPreview.concat(images);
                self.setState({ selectedFilesPreview: concatWithPrev })
            }, error => {
                console.error(error);
            });
        });
        if (files && Array.isArray(files) && files.length > 0) {
            let indexSelected = selectedFilesPreview.length - 1;
            files.forEach(async (item, index) => {
                if (index > indexSelected) {
                    let formData = new FormData();
                    formData.append('file', item);

                    let name = item.name;
                    let split_items = name.split('.');
                    let item_ext = split_items[split_items.length - 1];
                    let isImageTrue = ['jpg', 'png', 'jpeg', 'gif', 'tiff'].includes(item_ext);
                    let url = `/upload/multiuploader/?album=reviews&instance=review_1&image=undefined&doc=undefined`
                    this.setState({ isImageType: isImageTrue })
                    // await this.props.filter_categories_data({ category: 'business', sub_cat: "", level: 1 });
                    if (isImageTrue) {
                        //await this.props.upload_media_files('image', formData, index);
                        await this.props.upload_media_filesData(url, 'image', formData, index);
                        await this.props.get_category_taxonomy_listing('business', 'images', this.state.businessDetails?.taxonomy_list?.[0]?.toLowerCase()?.replace(/&/g, "and"));
                    } else {
                        //await this.props.upload_media_files('video', formData, index);
                        await this.props.upload_media_filesData(url, 'video', formData, index);
                        await this.props.get_category_taxonomy_listing('business', 'videos', this.state.businessDetails?.taxonomy_list?.[0]?.toLowerCase()?.replace(/&/g, "and"));
                    }
                }
            });
        }
    }



    handleChangeTaxonomy = (e) => {
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ fetchGalleryScroll: {}, fetchGalleryAlbum: value, fetchGalleryType: 'image' }, () => this.props.media_upload_scroll({ type: 'image', album: albumType, page: 1, force_update: true }))
    }

    handleChangeCategory = (e, index) => {
        let { latestUploadedData, uploadedId, uploadCaptions } = this.state;
        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        let isImageType = upload_data.media_type;
        uploadedId[index] = id;
        let { value } = e.target;
        let albumType = value !== 'all' ? value : '';
        this.setState({ albumCategoryId: albumType, uploadedId });

        this.props.update_caption_and_categories({ id, data: { "caption": uploadCaptions[index] || "", "category": value } });


        // let categoryId = "business";
        // let subcategoryId = value;
        // if (isImageType == "image") {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'image', index);
        // } else {
        //     this.props.fetch_categories_data(categoryId, subcategoryId, 'video', index);
        // }
    }

    handleChangeTaxonomyData = (e, index) => {
        let { value } = e.target;
        let { uploadCaptions, latestUploadedData } = this.state
        let data = {};
        let upload_data = latestUploadedData[index];
        let caption_data = uploadCaptions[index];
        data.caption = caption_data;
        data.category = value;
        let id = upload_data.id;
        this.setState({ taxonomycategoryId: value, captionData: caption_data }, () => this.props.upload_add_captions(id, data))

    }

    handleChangeVideoTaxonomy = async (e) => {
        let { name, value } = e.target;

        await this.setState({
            [name]: value,
        });


    }

    clearMediaDispatch = () => {
        this.setState({
            latestUploadedData: {}, uploadCaptions: {}, selectedFilesPreview: [], files: [], taxonomycategoryId: "",
            fetchTaxonomyListingImages: {}, fetchTaxonomyListingVideos: {}, captionData: "", uploadedId: {}, cattaxListing: {}, albumCategoryId: ""
        }, () => {
            this.props.clear_media_dispatch(); this.props.clear_category();
            this.props.clear_subcategory();
        });

    }


    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    handleScrollGalleryVideo = () => {
        let { refGalleryVideoScroll } = this;
        // let count = 0;
        let clientHeight = refGalleryVideoScroll.current.clientHeight;
        let scrollHeight = refGalleryVideoScroll.current.scrollHeight;
        const scrollTop = this.refGalleryVideoScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'video', album: '', force_update: false });
        }
    }

    handleScrollGalleryImage = () => {
        let { refGalleryImageScroll } = this;
        let { fetchGalleryAlbum } = this.state;

        let clientHeight = refGalleryImageScroll.current.clientHeight;
        let scrollHeight = refGalleryImageScroll.current.scrollHeight;
        const scrollTop = this.refGalleryImageScroll.current.scrollTop.toFixed() - 1;
        let scrollTopCalulated = scrollHeight - clientHeight;
        let scrollMinimun_8 = scrollTopCalulated - 8;
        let scrollMinimun_6 = scrollTopCalulated - 6;
        let scrollMinimun_5 = scrollTopCalulated - 5;
        let scrollMinimun_3 = scrollTopCalulated - 3;
        let scrollMinimun_1 = scrollTopCalulated - 1;

        if (
            scrollTopCalulated === scrollTop ||
            scrollTop === scrollMinimun_1 ||
            scrollTop === scrollMinimun_3 ||
            scrollTop === scrollMinimun_5 ||
            scrollTop === scrollMinimun_6 ||
            scrollTop === scrollMinimun_8
        ) {
            this.fetchGalleryOnScroll({ type: 'image', album: fetchGalleryAlbum, force_update: false });
        }
    }

    fetchGalleryOnScroll = ({ type, album, force_update }) => {
        let { fetchGalleryScroll, fetchGalleryCount } = this.state
        if (fetchGalleryScroll && Object.keys(fetchGalleryScroll).length > 0) {
            let totalCount = fetchGalleryCount / 10;
            let roundValue = parseInt(totalCount.toFixed());
            let countPhotos = Object.keys(fetchGalleryScroll).length;
            let nextCount = countPhotos + 1;

            if (nextCount <= roundValue) {
                this.props.media_upload_scroll({ type, album, page: nextCount, force_update })
            }
        }
    }

    AddNewEmbedLink = async (e) => {
        e.preventDefault();
        let { embeddedLink, embedBody } = this.state;
        // this.props.embedNewLink({
        //     data: {
        //         "video": `<p><a href=\"${embeddedLink}\">${embeddedLink}</a></p>`,
        //         "exclude_list": [],
        //         "body_preview": `<a class=\"urlive-link urlive-img-small\" href=\"${embeddedLink}\" target=\"_blank\"><div class=\"urlive-img-wrapper\"><img src=\"${embeddedLink}\" style=\"\" class=\"urlive-image\"></div><div class=\"urlive-text-wrapper\"><span class=\"urlive-title\">banner_logo.png</span><span class=\"urlive-url\">${embeddedLink}</span></div></a>`,
        //         "question_rating_category": [],
        //         "messagemedia_set": []
        //     }
        // });
        let thumbnail = await mediaThumbnail(embeddedLink);
        embedBody.push({
            type: "embedded_link",
            url: embeddedLink,
            thumbnail
        });
        this.setState({ embeddedLink: '', embedBody, uploadMediaModal: false, createNewDisModalToggle: false })
    }

    checkImages = (item) => {
        let items = (item.tos).split(',');
        if (items.length > 1) {
            return group_icons;
        } else {
            return item.current_profile_pic;
        }
    }

    handleChangeRatings = (item) => {
        this.setState({ setRatingFrom: item })
    }

    componentDidMount() {
        this.props.my_profile_info();

        let { businessId } = this.state;

        if (businessId) {
            this.fetchByBusinessId({ businessId });
            this.fetchDataFromHtmlForm({ businessId });
            if (this.props.menuItemChanged === 0) {
                this.fetchRateMenuItems({ businessId })
            }
        }
        window.addEventListener("scroll", this.handleScroll, { passive: false });

    }

    fetchRateMenuItems = ({ businessId }) => {
        callApi(`/api/getmenu/?entity=${businessId}&menuType=`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.setState({ rateMenu: response?.results || [] })
                if (response?.results?.length) {
                    this.setState({ activeMenuType: response?.results[0] })
                    const menuItem = response?.results[0]
                    this.fetchRateMenuItemContent({ businessId, menuItem })
                }
            }
        });
    }
    fetchRateMenuItemContent = ({ businessId, menuItem }) => {
        callApi(`/api/getmenu/?entity=${businessId}&menuType=${menuItem}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                let menuContainer = []
                Object.keys(response?.results).forEach((item) => {
                    const tempArray = {
                        menuItem: item,
                        menuContent: this.formatMenuContent(response?.results?.[item])
                    }
                    menuContainer.push(tempArray);
                })
                this.props.menuItemChangedStatus(0);
                this.setState({ rateMenuContent: menuContainer })
            }
        });
    }

    formatMenuContent = (menuConArray) => {
        let tempMenuContent = [];
        menuConArray.forEach(item => {
            for (const [key, value] of Object.entries(item)) {
                const tempArray = {
                    menuItemName: key,
                    menuItemContent: value
                }
                tempMenuContent.push(tempArray)
            }
        })
        return tempMenuContent
    }

    fetchByBusinessId = ({ businessId }) => {
        const { get_business_detail, fetch_taxonomy_category, get_business_QA_dropdown, fetch_detailedstarrating
        } = this.props;

        get_business_detail(businessId);
        fetch_taxonomy_category();
        get_business_QA_dropdown(businessId);
        fetch_detailedstarrating(businessId);
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutWallReplyText);
        window.removeEventListener("scroll", this.handleScroll);
        localStorage.removeItem("openModal");
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.checksAndRenderById();
        }

        if (this.props.business_rating !== prevProps.business_rating) {
            const rating = [];
            Object.keys(this.props.business_rating).map((iteam) => {
                let RatingLabel = "";
                this.props.qa_dropdown && this.props.qa_dropdown.res && this.props.qa_dropdown.res.map((element) => {
                    if (element.name === iteam) {
                        RatingLabel = element.label
                    }
                })

                if (iteam !== "avg_rating" && iteam !== "code") {
                    rating.push({
                        key: iteam,
                        value: null,
                        id: iteam.id,
                        checked: false,
                        label: RatingLabel,
                        name: RatingLabel,
                        isEnable: false
                    })
                }
            })
            this.setState({
                businessRatingList: rating,
                businessRatingListDefaut: rating
            })
        }
    }

    clearReveiwRatingState = async (publishReview=false) => {
        let { chartStarRatings, businessRatingList, businessDetails } = this.state;
        await this.setState({
            enableRating: false,
            avg_rating: 0,
            businessRatingList: this.state.businessRatingListDefaut,
            write_new_review: "",
            mediaSet: [],
            mediaSetReview: [],
            mediaSetCompose: true,
            selectedUpMediaType: 'upload',
            showGalleryType: 'images',
            myProfile: null,
            searchTheater: '',
            fetchPhotos: {},
            fetchPhotosCount: 0,
            fetchVideos: {},
            fetchVideosCount: 0,
            albumType: '',
            fetchTaxonomy: [],
            businessRatingIds: [],
            fetchGalleryScroll: {},
            fetchGalleryCount: 0,
            fetchGalleryType: 'image',
            fetchGalleryAlbum: '',
            selectedFilesPreview: [],
            fetchDeletedData: {},
            fetchDeleteCount: 0,
            latestUploadedData: {},
            uploadCaptions: {},
            taxonomycategoryId: "",
            fetchTaxonomyListingImages: {},
            fetchTaxonomyListingVideos: {},
            categoryType: "",
            captionData: "",
            uploadedId: {},
            avgRateKey: null,
            disableCreateReview: true
        });

        let itemEditSet = [];
        if (chartStarRatings !== null && Object.keys(chartStarRatings).length && itemEditSet.length == 0) {
            businessRatingList.forEach((item) => {
                businessRatingList[businessRatingList.findIndex(itemss => itemss.key == item.key)].value = null
            });
            this.setState({
                businessRatingList
            })
        }
        if (this.props?.feed && this.props?.handleCloseFeed) {
            this.props.handleCloseFeed({ listing_type: businessDetails?.listing_type, listing_id: businessDetails?.id, feed_id: this.props?.feed?.id, publishReview})
        }
    }

    ratingChanged = async (newRating, name, key) => {
        let name_modify = '';
        this.VoteData = true;
        this.setState({ ratingOn: true })
        if (name !== "avg_rating") {
            name_modify = key
            const tempArray = [...this.state.businessRatingList]
            const ratedCategory = tempArray.map(item => {
                if (item.name === name) {
                    return { key: key, name: name, label: name, value: newRating, isEnable: false }
                } else {
                    return { key: item.key, name: item.label, label: item.label, value: item.value, isEnable: false }
                }
            })

            this.setState({ businessRatingList: ratedCategory })
        } else {
            name_modify = "avg_rating"
            let filteredReviewCategories = [];
            this.setState({ avg_rating: newRating, enableRating: true, reviewCategoriesList: filteredReviewCategories })

        }
        this.getSecurityHashToken(name_modify, newRating)
    }
    ratingChangedMenuItem = async (newRating, menuId) => {
        const businessId = this.state.businessId
        const menuItem = this.state.activeMenuType
        callApi(`/api/menurating/?value=${newRating}&menu_id=${menuId}&rating_key=${this.state.avgRateKey}`, "POST", []).then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {
                this.fetchRateMenuItemContent({ businessId, menuItem })
            }
        });
    }

    ratingChangedForCheck = (checked, data) => {

        if (checked) {
            this.state.businessRatingIds.push(data.id);
        } else {
            let el = this.state.businessRatingIds.find(
                (itm) => itm === parseInt(data.id)
            );
            if (el)
                this.state.businessRatingIds.splice(
                    this.state.businessRatingIds.indexOf(el),
                    1
                );
        }
        /*let { businessRatingIds,reviewsCategories } = this.state
       reviewsCategories[reviewsCategories.res.findIndex(item => item.label === data.label)].Checked = checked
       this.setState({
           businessRatingList
       })*/
    }

    handelChangeReview = (e) => {
        // 'QualityofEquipmentCheck'
        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }, () => {
            if(!this.state.isadhere){
                this.setState({ enableErrorForReview: true }) 
            }

            if (this.state.write_new_review?.length >= 50) {
                this.setState({ enableErrorForReview: false })
            }

            if (this.state.write_new_review?.length < 50) {
                this.setState({ enableErrorForReview: true })
            }

        });
    }

    handelChangeReviewVideo = (e) => {
        // 'QualityofEquipmentCheck'
        this.setState({
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }, () => {


        });
    }

    publishPost = async (dataList) => {

        let { businessDetails, menuMediaListMedia, reviewAnonymous, reviewDynamicItems, avg_rating, mediaSetReview, write_new_review, writeAReviewTextVideo, businessId, businessRatingIds, shocialNetworkSharing, isadhere, stepTwoUploadedVideo, embedBody } = this.state
        const { publish_post, twitter_connection, facebook_connection } = this.props;
        let mediaSetDataItems = [];

        if (shocialNetworkSharing === true) {
            if (twitter_connection === false || facebook_connection === false) {
                this.setState({
                    shareSocialMediaModalToggle: true
                })
                return;
            }
        }
        if (stepTwoUploadedVideo?.length == 0) {
            if (write_new_review?.length < 50) {
                this.setState({ enableErrorForReview: true });
                return
            }
        }

        if (mediaSetReview?.length) {
            mediaSetDataItems = mediaSetReview.map(i => i["id"]);
        }

        if (stepTwoUploadedVideo?.length) {
            let itemsGet = stepTwoUploadedVideo.map(data => data.id);
            mediaSetDataItems = mediaSetDataItems.concat(itemsGet);
        }

        const data = {
            associated_to: businessId,
            avg_rate_val: avg_rating,
            followup: false,
            followup_avg_rate: false,
            is_anonymous: reviewAnonymous,
            is_draft: dataList === "draft",
            media: mediaSetDataItems,
            // review: write_new_review,
            review: writeAReviewTextVideo && writeAReviewTextVideo != "" ? writeAReviewTextVideo : write_new_review,
            review_rating_category: businessRatingIds,
            review_voting_key: this.state.avgRateKey,
            social_broadcast: shocialNetworkSharing == true ? true : false,
            social_broadcast_facebook: shocialNetworkSharing == true ? true : false,
            social_broadcast_twitter: shocialNetworkSharing == true ? true : false,
            tips_guide: isadhere
        }

        if (!!Object.keys(menuMediaListMedia).length) {
            data["menu_media_list"] = Object.keys(menuMediaListMedia)?.map(menuItem => {
                return {
                    "type": "media",
                    "entries": this.state.businessId,
                    "userentries_id": this.state.businessId,
                    "taxonomy_id": "",
                    "menu": +menuItem,
                    "multiuploaderfile": menuMediaListMedia[menuItem]
                }
            })
        }

        if (embedBody?.length) {
            data['embed_body'] = embedBody.map(item => {
                return {
                    url: item.url,
                    thumbnail: item.thumbnail
                }
            });
        }

        await publish_post(data, businessId);
        await this.clearReveiwRatingState(true);

        const rating = [];
        Object.keys(this.props.business_rating).map((iteam) => {
            let RatingLabel = "";
            this.props.qa_dropdown && this.props.qa_dropdown.res && this.props.qa_dropdown.res.map((element) => {
                if (element.name === iteam) {
                    RatingLabel = element.label
                }
            })

            if (iteam !== "avg_rating" && iteam !== "code") {
                rating.push({
                    key: iteam,
                    value: null,
                    id: iteam.id,
                    checked: false,
                    label: RatingLabel,
                    isEnable: false
                })
            }
        })
        this.setState({
            businessRatingList: rating,
            businessRatingListDefaut: rating
        })

        // await this.fetchDataFromHtmlForm({ businessId });
        this.setState({ viewEditReviewStep: 'writeReview', embedBody: [], reviewDynamicItems: {}, enableErrorForReview: true, stepTwoUploadedVideo: [], disableCreateReview: true });
        // await get_business_detail(businessId);
        if (this.props?.feed && this.props?.handleCloseFeed) {
            this.props.handleCloseFeed({ listing_type: businessDetails?.listing_type, listing_id: businessDetails?.id, feed_id: this.props?.feed, })
        }
    }

    removeSelectedImage = (e, index) => {
        let { latestUploadedData, selectedFilesPreview, files, uploadedId, fetchTaxonomyListingImages, fetchTaxonomyListingVideos } = this.state

        let upload_data = latestUploadedData[index];
        let id = upload_data.id;
        this.props.single_delete_from_upload(id);

        // delete items from array
        selectedFilesPreview.splice(index, 1);
        files.splice(index, 1); // deleted this because it concat from the upload function.

        // deleted items from json
        delete latestUploadedData[index];
        delete uploadedId[index];
        delete fetchTaxonomyListingImages[index];
        delete fetchTaxonomyListingVideos[index];

        // deleted items from json
        if (latestUploadedData[index] == undefined) {
            let newItems = [];

            // turn json to array for indexes
            Object.values(latestUploadedData).forEach(it => { newItems.push(it) });

            // turn array to json for indexes
            if (newItems && Array.isArray(newItems) && newItems.length) {
                let newItemsJson = {};
                newItems.forEach((nitem, index) => {
                    newItemsJson[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJson).length) {
                    latestUploadedData = newItemsJson;
                }
            }
        }

        if (uploadedId[index] == undefined) {
            let newItemsf = [];

            // turn json to array for indexes
            Object.values(uploadedId).forEach(it => { newItemsf.push(it) });

            // turn array to json for indexes
            if (newItemsf && Array.isArray(newItemsf) && newItemsf.length) {
                let newItemsJsond = {};
                newItemsf.forEach((nitem, index) => {
                    newItemsJsond[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsond).length) {
                    uploadedId = newItemsJsond;
                }
            }
        }

        if (fetchTaxonomyListingImages[index] == undefined) {
            let newItemsImages = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingImages).forEach(it => { newItemsImages.push(it) });

            // turn array to json for indexes
            if (newItemsImages && Array.isArray(newItemsImages) && newItemsImages.length) {
                let newItemsJsonImages = {};
                newItemsImages.forEach((nitem, index) => {
                    newItemsJsonImages[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonImages).length) {
                    fetchTaxonomyListingImages = newItemsJsonImages;
                }
            }
        }

        if (fetchTaxonomyListingVideos[index] == undefined) {
            let newItemsVideos = [];

            // turn json to array for indexes
            Object.values(fetchTaxonomyListingVideos).forEach(it => { newItemsVideos.push(it) });

            // turn array to json for indexes
            if (newItemsVideos && Array.isArray(newItemsVideos) && newItemsVideos.length) {
                let newItemsJsonVideo = {};
                newItemsVideos.forEach((nitem, index) => {
                    newItemsJsonVideo[index] = nitem;
                });

                // turn json to array for indexes
                if (Object.keys(newItemsJsonVideo).length) {
                    fetchTaxonomyListingVideos = newItemsJsonVideo;
                }
            }
        }

        this.setState({ selectedFilesPreview, latestUploadedData, files, uploadedId, fetchTaxonomyListingVideos, fetchTaxonomyListingImages });
    }

    renderPhotosAndVideos = (iteamImage, isTrue) => {
        if (iteamImage?.url) {
            let items = iteamImage?.url.split('.');
            let lastItem = items[items.length - 1];
            if (['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov'].includes(lastItem)) {
                return (
                    <video
                        autoPlay
                        muted
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        controls>
                        <source src={iteamImage?.url} type={`video/${lastItem}`}></source>
                    </video>
                )
            } else {
                return (
                    <img
                        className={isTrue ? "img-fluid img-thumbnail" : "img-thumbnail uploaded-img-video-thumbnail"}
                        src={iteamImage.url}
                        alt=""
                    />)
            }
        }
    }

    handleHoverChange = (rate) => {
        this.enableRatingText = rate;
    }

    forceToggleLoginModal = () => {
        this.setState(prevState => ({
            ...this.prevState,
            forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    checkIfLoggedIn = () => {
        const authId = localStorage.getItem('profileId');
        const { my_profile } = this.props;
        if (my_profile && my_profile.id && authId && (authId == my_profile.id)) {
            return true;
        } else {
            return false;
        }
    }

    checkColorTrue = (item) => {
        const { businessRatingList } = this.state;
        return businessRatingList.filter(itm => itm.key === item)?.[0]?.isEnable || businessRatingList.filter(itm => itm.key === item)?.[0]?.value === 0
    }

    onHoverEnableDisable = (name, value) => {
        const tempArray = [...this.state.businessRatingList]
        const ratedCategory = tempArray.map(item => {
            return { ...item, isEnable: item.key === name ? value : false }
        })
        this.setState({ businessRatingList: ratedCategory })
    }

    findAndSetItem = (item) => {
        let item_modify = '';
        if (item) {
            item_modify = item.replace(/\s/g, '');
            if (item_modify.includes('/')) {
                item_modify = item_modify.replace(/\//g, '')
            }
        }

        let { businessRatingList } = this.state;
        let data = businessRatingList.findIndex(data => {

            let name_modify = '';
            if (data.key) {
                name_modify = data.key.replace(/\s/g, '');
                if (name_modify.includes('/')) {
                    name_modify = name_modify.replace(/\//g, '')
                }
            }

            return name_modify == item_modify;
        });

        if (data > -1) {
            let returnValue = businessRatingList[data];
            if (returnValue?.value > -1) {
                return returnValue?.value;
            }
        }
    }

    getSecurityHashToken = (keyName, newRating) => {
        const url = "/ratings/security_hash/"
        const { categoryItemQueryParams, avgRateKey } = this.state;
        let formDataSecurity = new FormData();

        formDataSecurity.append('object_pk', this.state.businessId);
        formDataSecurity.append('content_type', categoryItemQueryParams.content_type);
        formDataSecurity.append('key', keyName);
        formDataSecurity.append('timestamp', Date.now());
        callApi(url, 'POST', formDataSecurity).then((res) => {
            this.setState({ ratingOn: false })
            if (res.code <= 201) {
                let formDataVote = new FormData();
                formDataVote.append('csrfmiddlewaretoken', res?.csrfmiddlewaretoken);
                formDataVote.append('score', newRating);
                formDataVote.append('content_type', res?.content_type);
                formDataVote.append('object_pk', res?.object_pk);
                formDataVote.append('key', keyName);
                formDataVote.append('timestamp', res?.timestamp);
                formDataVote.append('security_hash', res?.security_hash);
                formDataVote.append('rate_unq_key', !!!avgRateKey ? res?.rate_unq_key : avgRateKey);
                this.save_ratings_vote(formDataVote, res?.rate_unq_key);
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        categoryItemQueryParams: {
                            ...prevState.categoryItemQueryParams,
                            csrfmiddlewaretoken: res?.csrfmiddlewaretoken,
                            rate_unq_key: res?.rate_unq_key,
                            security_hash: res?.security_hash,
                            timestamp: res?.timestamp
                        }
                    }
                })
            }
        });
    }
    save_ratings_vote = async (data, rateKey) => {
        let url = '/ratings/vote/';
        try {
            const response = await callApi(url, 'POST', data);
            if (response?.code == 200) {
                if (response.key === "avg_rating") {
                    this.setState({ voteScore: response.vote_score, avgRateKey: rateKey })
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    removeEmbeddedFromUploaded = (index, item) => {
        let { embedBody } = this.state;
        if (index > -1) {
            embedBody.splice(index, 1);
        }
        this.setState({ embedBody });
        // this.props.removeUploadedMediaSet(item);
    }

    handleDeleteMenuMedia = (menuId, mediaId) => {
        const { menuMediaListMedia } = this.state
        let menuMediaListMediaCopy = { ...menuMediaListMedia }
        const mutateArray = menuMediaListMediaCopy?.[menuId]
        const updatedArray = mutateArray.filter(item => item.id !== mediaId)
        menuMediaListMediaCopy[menuId] = updatedArray
        this.setState({ menuMediaListMedia: menuMediaListMediaCopy })

    }
    render() {
        let {
            enableErrorForReview,
            businessId,
            selectedFilesPreview,
            latestUploadedData,
            fetchTaxonomy,
            mediaSetReview,
            embeddedLink,
            embedBody,
            showVideoMedia,
            write_new_review,
            enableRating,
            shocialNetworkSharing,
            reviewAnonymous,
            businessRatingList,
            reviewsCategories,
            isadhere,
            fetchTaxonomyListingImages,
            fetchTaxonomyListingVideos,
            uploadedId,
            cattaxListing,
            writeAReviewTextVideo,
            ratingOn, rateMenu,
            rateMenuContent,
            stepTwoUploadedVideo,
        } = this.state;



        const { my_profile } = this.props;
        let { businessDetails, enableHoverBanIcon } = this.state;


        let totalLength = selectedFilesPreview.length;
        let objectKeys = [];
        let objectLength = 0;
        let calculatedPercentage = 0;
        if (Object.keys(latestUploadedData).length > 0) {
            objectKeys = Object.keys(latestUploadedData);
            objectLength = objectKeys.length;
            calculatedPercentage = ((objectLength * 100) / totalLength).toFixed();
        };
        return (
            <>
                <div className="mb-3 step-2_Review">
                    {/* write your own review, Part 1 */}
                    <div className={enableRating ? "bg-white p-3 hide-review-block" : "bg-white p-3"}>
                        <div className="d-flex flex-nowrap">
                            <div className="flex-grow-1 mr-3">
                                <h2 className="text-primary mb-2 fs-38">Start your review of {businessDetails?.name}</h2>
                                {/* Only show if posting for the first time */}
                                {
                                    businessDetails?.total_rating?.total_review < 1 &&
                                    <p className="text-dark ff-base fs-16">
                                        <strong>Please share your experience.</strong>
                                        <br />
                                        Be the first to write a review about this listing and you will receive your First to Review badge!
                                    </p>
                                }
                            </div>
                            {/* Only show if posting for the first time */}
                            {
                                businessDetails?.total_rating?.total_review < 1 && <div className="ml-auto col-auto px-0">
                                    <img className="img-fluid" src={require("../../assets/images/icons/first-to-review.png")} alt="First review badge" />
                                </div>
                            }
                        </div>

                        <div className="mt-3">
                            <h6 className="ff-base font-weight-bold text-primary">Step 1. Star Ratings </h6>
                            <hr className="bg-light mt-2" />
                            <p className="text-muted fs-14">
                                {'Roll over the stars and click to rate.'}
                            </p>

                            {/* Star Input */}
                            <div className="d-flex align-items-center">
                                <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                    {'Overall Rating*'}
                                </Label>
                                <div>
                                    <div className="stars-rating-input _lg">
                                        <span
                                            onMouseEnter={() => {
                                                if (!this.checkIfLoggedIn()) {
                                                    this.forceToggleLoginModal();
                                                    return false;
                                                }
                                                this.setState({ enableRatingText: 0, enableHoverBanIcon: true })
                                            }}
                                            onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                            className="zero-rating-input" role="button" onClick={() => this.ratingChanged(0, 'avg_rating')}>
                                            <FontAwesomeIcon
                                                icon="ban"
                                                onClick={() => this.ratingChanged(0, 'avg_rating')}
                                                color={enableHoverBanIcon ? '#15c3d4' : '#d7d8e2'}
                                                title="0"
                                            />
                                        </span>

                                        <span title={this.state.enableRatingText}>
                                            <Rating
                                                fractions={2}
                                                emptySymbol={empty_star_rating}
                                                fullSymbol={full_star_rating}
                                                onHover={(rate) => {
                                                    if (enableHoverBanIcon) {
                                                        this.setState({ enableHoverBanIcon: false });
                                                    }
                                                    if (rate !== this.state.enableRatingText) {
                                                        this.setState({ enableRatingText: rate })
                                                    }
                                                }}
                                                readonly={ratingOn}
                                                initialRating={this.state.avg_rating}
                                                onChange={(newRating) => this.ratingChanged(newRating, 'avg_rating')}
                                            />
                                        </span>

                                        <span className="label-dynamic-rating">
                                            {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                            {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                            {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                            {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                            {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                            {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* write your own review, Part 2 */}
                    <RatingCollapse isOpen={enableRating}>
                        <div>
                            <div className="py-3">
                                <h2 className="text-dark fs-36">You’re reviewing <span className="text-darker">{businessDetails?.name}</span></h2>
                            </div>

                            {/* Step 1 Collapse */}
                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 1. Star Ratings" bodyClass="text-dark" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true} isReviewCollapse={true}>

                                    <p className="text-tertiary fs-14">
                                        Roll over the stars and click to rate. <span className="text-dark ff-alt hover-underline" role="button"
                                            onClick={() => this.setState({ showRevTips: !this.state.showRevTips })}> Review Tips & Guidelines.</span>
                                    </p>

                                    {/* Review Tips & Guidelines */}
                                    {this.state.showRevTips ? <div className="mb-3" id='reviewTipsAndGuideLines'>
                                        <div className="text-right">
                                            <Button color="tertiary" className="text-white" size="sm" onClick={() => this.setState({ showRevTips: false })}><FontAwesomeIcon icon="times" /> </Button>
                                        </div>
                                        <div className="bg-tertiary text-white p-2 fs-14">
                                            <ul className="list-normal mb-0">
                                                <li className="ff-base mb-2">Do not post photos or videos of other copyrighted material that you don’t have permission to use. Do not post photos of people without their permission. If they are children, ask the parents before you post.</li>
                                                <li className="ff-base mb-2">Opinions are almost always protected as free speech except in the case of defamation (a false accusation of an offense or a malicious misrepresentation of someone's words or actions), true threats, solicitations to commit crimes, incitement to imminent lawless action, blackmail, fighting words and obscenity).</li>
                                                <li className="ff-base mb-2">Please omit excessive exaggeration and do not lie.</li>
                                                <li className="ff-base mb-2">Deliberately sabotaging the viability of a business is strongly discouraged.</li>
                                                <li className="ff-base mb-2">Be honest and truthful in your reviews.</li>
                                                <li className="ff-base mb-2">Please be accurate, avoid embellishment and broad generalizations, and to relate only to your first hand experiences.</li>
                                            </ul>
                                        </div>
                                    </div> : ""}

                                    {/* Star Input */}

                                    <div className="d-flex align-items-center">
                                        <Label className="text-dark font-weight-bold text-nowrap mr-3 fs-16">
                                            {'Overall Rating*'}
                                        </Label>
                                        <div>
                                            <div className="stars-rating-input _lg">
                                                <span
                                                    onMouseEnter={() => { this.setState({ enableRatingText: 0, enableHoverBanIcon: true }) }}
                                                    onMouseLeave={() => { this.setState({ enableRatingText: null, enableHoverBanIcon: false }) }}
                                                    className="zero-rating-input"
                                                    role="button">
                                                    <FontAwesomeIcon
                                                        icon="ban"
                                                        onClick={() => this.ratingChanged(0, 'avg_rating')}
                                                        color={(enableHoverBanIcon || this.state.avg_rating === 0) ? '#15c3d4' : '#d7d8e2'}
                                                    />
                                                </span>
                                                <span title={this.state.enableRatingText}>
                                                    <Rating
                                                        fractions={2}
                                                        emptySymbol={empty_star_rating}
                                                        fullSymbol={full_star_rating}
                                                        onHover={(rate) => {
                                                            if (enableHoverBanIcon) {
                                                                this.setState({ enableHoverBanIcon: false });
                                                            }

                                                            if (rate !== this.state.enableRatingText) {
                                                                this.setState({ enableRatingText: rate })
                                                            }
                                                        }}
                                                        readonly={ratingOn}
                                                        initialRating={this.state.avg_rating}
                                                        onChange={(newRating) => {
                                                            this.ratingChanged(newRating, 'avg_rating')
                                                        }}
                                                    />
                                                </span>

                                                <span className="label-dynamic-rating">
                                                    {(this.state.enableRatingText == 0) && 'Oh, Hell No!'}
                                                    {(this.state.enableRatingText == 0.5 || this.state.enableRatingText == 1) && 'Definitely would not recommend!'}
                                                    {(this.state.enableRatingText == 1.5 || this.state.enableRatingText == 2) && 'It could have been a lot better!'}
                                                    {(this.state.enableRatingText == 2.5 || this.state.enableRatingText == 3) && 'Okay!'}
                                                    {(this.state.enableRatingText == 3.5 || this.state.enableRatingText == 4) && 'Very good!'}
                                                    {(this.state.enableRatingText == 4.5 || this.state.enableRatingText == 5) && "Can't get any better than this!"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-2 step-3_Review">
                                        <div className="text-tertiary">
                                            <span className="font-weight-bold">
                                                {'Detailed Rating'}
                                            </span> {'(optional)'}
                                        </div>
                                        <hr className="bg-light" />
                                        <Row className="fs-14 ff-alt">
                                            {businessRatingList.map((iteam) => (
                                                <Col className="mb-2" md={6} lg={4}>
                                                    <div>{iteam.label}</div>
                                                    {/* <div>{iteam.key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}</div> */}
                                                    <div className="stars-rating-input">
                                                        <span className="zero-rating-input" role="button">
                                                            <FontAwesomeIcon
                                                                icon="ban"
                                                                color={this.checkColorTrue(iteam.key) ? '#15c3d4' : '#d7d8e2'}
                                                                onMouseEnter={() => this.onHoverEnableDisable(iteam.key, true)}
                                                                onMouseLeave={() => this.onHoverEnableDisable(iteam.key, false)}
                                                                onClick={() => {
                                                                    this.ratingChanged(0, iteam.label, iteam.key);
                                                                }}
                                                            />
                                                        </span>

                                                        <span title={this.state.titleRatingText}>
                                                            <Rating
                                                                fractions={2}
                                                                emptySymbol={empty_star_rating_below}
                                                                fullSymbol={full_star_rating_below}
                                                                initialRating={!businessRatingList.filter(itm => itm.key === iteam.key)?.[0]?.isEnable ? this.findAndSetItem(iteam.name) : 0}
                                                                onChange={(newRating) => {
                                                                    this.ratingChanged(newRating, iteam.label, iteam.key)
                                                                }}
                                                                readonly={ratingOn}
                                                            />
                                                        </span>
                                                        <span
                                                            onClick={() => {
                                                                this[iteam.label] = false;
                                                                this.ratingChanged(0, iteam.label, iteam.key)
                                                            }}
                                                            className="clear-rating"
                                                            role="button"
                                                            title="Clear">
                                                            <span className="icon-cross"></span>
                                                        </span>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    {businessDetails?.taxonomy_list && indexOf(businessDetails.taxonomy_list, 'Restaurants') > -1 && (rateMenu && rateMenu?.length) ?
                                        <div className="p-3 bg-light">
                                            <CollapseBasic title="You can rate menu items too!" containerClass="bg-transparent type-tertiary" bodyClass="mt-3 text-tertiary" isOpen={false} size="sm">
                                                <div className="mb-4">
                                                    <Nav className="active-transparent border-0" tabs>
                                                        {rateMenu && rateMenu.map((menuItem, index) => {
                                                            return <NavItem>
                                                                <NavLink className="text-truncate"
                                                                    href="#"
                                                                    active={this.state.activeMenuType === menuItem}
                                                                    onClick={() => {
                                                                        this.setState({ activeMenuType: menuItem })
                                                                        this.fetchRateMenuItemContent({ businessId, menuItem })

                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <span className="font-weight-bold ff-base fs-16">
                                                                        {menuItem}
                                                                    </span>
                                                                </NavLink>
                                                            </NavItem>
                                                        })}
                                                    </Nav>
                                                </div>
                                                <TabContent activeTab={this.state.activeMenuType}>
                                                    <TabPane tabId={this.state.activeMenuType}>
                                                        {rateMenuContent && rateMenuContent.map((menu, indexItem) => {
                                                            return <CollapseBasic title={menu.menuItem} containerClass="bg-transparent type-tertiary" bodyClass="fs-14 mt-3 text-tertiary" size="sm" isOpen={false}>
                                                                <Row>
                                                                    {/* Repeat this for items */}
                                                                    {menu?.menuContent?.map((menuDetail, index) => {
                                                                        return <Col className="mb-3" lg={6}>
                                                                            <div className="d-flex flex-nowrap mx-n2">
                                                                                <div className="px-2">
                                                                                    <img className="menu_img" width="50" src={this.state.menuMediaListMedia[menuDetail.menuItemContent[4]]?.[0]?.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                                                </div>
                                                                                <div className="px-2">
                                                                                    <div className="d-flex">
                                                                                        <div className="mr-2">
                                                                                            <span className="text-dark mr-2 font-weight-bold">{menuDetail.menuItemName}</span>
                                                                                            <div>
                                                                                                <div className="stars-rating-input _sm">
                                                                                                    <span className="zero-rating-input" role="button">
                                                                                                        <FontAwesomeIcon icon="ban"
                                                                                                            onClick={() => this.ratingChangedMenuItem(0, menuDetail.menuItemContent[4])}
                                                                                                            color={+menuDetail.menuItemContent[1] === 0 ? '#15c3d4' : '#d7d8e2'}
                                                                                                        />
                                                                                                    </span>
                                                                                                    <StarsRating
                                                                                                        count={5}
                                                                                                        value={+menuDetail.menuItemContent[1]}
                                                                                                        size={18}
                                                                                                        color1={'#d7d8e2'}
                                                                                                        color2={'#15c3d4'}
                                                                                                        onChange={(newRating) => {
                                                                                                            this.ratingChangedMenuItem(newRating, menuDetail.menuItemContent[4])
                                                                                                        }}
                                                                                                    />
                                                                                                    {/* <span className="ml-2 d-inline-block times-2" role="button" title="Clear">
                                                                                                                    <span className="icon-cross"></span>
                                                                                                                </span> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-dark">
                                                                                        <p className="fs-14 mb-0">
                                                                                            {menuDetail.menuItemContent[2]}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="d-flex flex-wrap">
                                                                                        {this.state.menuMediaListMedia[menuDetail.menuItemContent[4]]?.map(img => {
                                                                                            return <div className="px-2 py-2 position-relative" >
                                                                                                <img className='menu_img' width="50" src={img.thumbnail || 'https://stagingdatawikireviews.s3.amazonaws.com/images/placeholder.png'} alt="" />
                                                                                                <FontAwesomeIcon onClick={() => this.handleDeleteMenuMedia(menuDetail.menuItemContent[4], img.id)} color="red" icon="trash-alt" style={{ position: "absolute", top: 0, right: "10px", cursor: "pointer" }} />
                                                                                            </div>
                                                                                        })}
                                                                                    </div>
                                                                                    <div className="mt-1">
                                                                                        <Button color="link" className="text-dark fs-14 font-weight-bold p-0"
                                                                                            onClick={() => {
                                                                                                this.handleCloseAndFetch();
                                                                                                this.setState({
                                                                                                    uploadType: "menuItem",
                                                                                                    menuTitle: menu.menuItem,
                                                                                                    menuSubTitle: menuDetail.menuItemName,
                                                                                                    menuObjectPk: menuDetail.menuItemContent[4]
                                                                                                })
                                                                                            }}>
                                                                                            <FontAwesomeIcon icon="camera" className="mr-2" />
                                                                                            Add Image
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                    })}


                                                                </Row>
                                                            </CollapseBasic>
                                                        })}

                                                    </TabPane>
                                                </TabContent>
                                            </CollapseBasic>
                                        </div> : ''}
                                </CollapseBasic>
                            </div>

                            {/* Step 2 Collapse */}
                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 2. Review" bodyClass="text-dark" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true} isReviewCollapse={true} >
                                    <div className="text-tertiary fs-14 mb-3">
                                        {'Please provide as much information as you can in your review to be as meaningful as possible to others.'}
                                    </div>
                                    <Nav tabs className="mb-3 active-transparent">
                                        <NavItem>
                                            <NavLink href="#"
                                                className="ff-base font-weight-normal"
                                                active={this.state.viewEditReviewStep === "writeReview"}
                                                onClick={() => {

                                                    if ((this.state.clickedOnWriteAReview == 0 || this.state.clickedOnWriteAReview == 1)) {
                                                        this.setState({ clickedOnWriteAReview: 1, enableErrorForReview: write_new_review.length < 50 });
                                                    } else {
                                                        this.setState({ viewEditReviewStep: "writeReview", enableErrorForReview: write_new_review.length < 50 })
                                                    }
                                                }}>
                                                <span className="font-weight-bold">
                                                    {'Write a Review'}
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"
                                                className="ff-base font-weight-normal step-4_Review"
                                                active={this.state.viewEditReviewStep === "recordVideo"}
                                                onClick={() => {
                                                    this.setState({ viewEditReviewStep: "recordVideo", clickedOnWriteAReview: 0, enableErrorForReview: !stepTwoUploadedVideo.length })
                                                }}>{'or record a'}
                                                <span className="font-weight-bold">
                                                    &nbsp; Video Review
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>

                                    <TabContent className="mb-3" activeTab={this.state.viewEditReviewStep}>
                                        <TabPane tabId="writeReview">

                                            <div className="px-2 col">
                                                <div className="fs-14 font-weight-bold text-dark">
                                                    Add minimum 50 Characters
                                                </div>
                                            </div>

                                            <FormGroup className="main-post-formgroup">
                                                <div className="input-label-block">
                                                    <Input
                                                        // style={{ border: enableErrorForReview ? '1px solid red' : '1px solid #ddd' }}
                                                        className="primary"
                                                        type="textarea"
                                                        name="write_new_review"
                                                        rows="4"
                                                        id="write_new_review"
                                                        value={write_new_review}
                                                        onChange={this.handelChangeReview}
                                                        placeholder="&nbsp;"
                                                    />
                                                    {write_new_review.length < 50 && write_new_review.length !== 0 ? <span style={{ color: "red" }}>
                                                        This field contains a minimum of 50 characters.
                                                    </span> : ""}
                                                </div>
                                                <div className="d-flex">
                                                    <div className="px-2 col">
                                                        <div className="characters-length-text">
                                                            Characters: <span>{write_new_review.length}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </TabPane>
                                        <TabPane tabId="recordVideo">
                                            <div>
                                                {this.state.viewEditReviewStep === "recordVideo" && (
                                                    <WebCamRecording
                                                        setClickedTabOperation={this.setClickedTabOperation}
                                                        clickedOnWriteAReview={this.state.clickedOnWriteAReview}
                                                        stepTwoUploadedVideo={this.state.stepTwoUploadedVideo}
                                                        addUploadedVideo={this.addUploadedVideo}
                                                        removeUploadedVideo={this.removeUploadedVideo}
                                                    />
                                                )}
                                                {this.state.viewEditReviewStep === "recordVideo" && (
                                                    <FormGroup className="main-post-formgroup">
                                                        <div className="input-label-block">
                                                            <Input
                                                                // style={{ border: enableErrorForReview ? '1px solid red' : '1px solid #ddd' }}
                                                                className="primary"
                                                                type="textarea"
                                                                name="writeAReviewTextVideo"
                                                                rows="4"
                                                                id="write_new_review_video"
                                                                value={writeAReviewTextVideo}
                                                                onChange={this.handelChangeReviewVideo}

                                                            />

                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="px-2 col">
                                                                <div className="characters-length-text">
                                                                    Characters: <span>{writeAReviewTextVideo.length ? writeAReviewTextVideo.length : '0'}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                )}
                                            </div>

                                        </TabPane>
                                    </TabContent>

                                    <div className="fs-14">
                                        <div className="text-dark">
                                            <span className="font-weight-bold">
                                                Choose Review Categories
                                            </span>
                                            <span>
                                                (Optional)
                                            </span>
                                        </div>
                                        <hr className="bg-light my-2" />
                                        <p className="text-tertiary fs-14 mb-2">
                                            If your review above contains information about a particular element or elements that you would like to highlight, please check the corresponding boxes below.
                                        </p>
                                        <Row>

                                            {reviewsCategories.res && reviewsCategories.res.map((iteam, indext) => {
                                                if (iteam.name !== "avg_rating")
                                                    return (
                                                        <Col xs={6} lg={4} key={indext}>
                                                            <FormGroup check>
                                                                <Label className="text-primary font-weight-bold" check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        id="checkbox2"
                                                                        name={iteam.label}
                                                                        onChange={(e) => this.ratingChangedForCheck(e.target.checked, iteam)}
                                                                    />{' '}
                                                                    {iteam.label}
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                    )
                                            })
                                            }


                                        </Row>
                                    </div>
                                </CollapseBasic>
                            </div>

                            {/* Step 3 Collapse */}
                            <div className="bg-white mb-3">
                                <CollapseBasic title="Step 3. Upload Images and/or Video (optional)" containerClass="type-tertiary type-review bg-transparent" isOpen={true} size="sm" noPadding={true} isReviewCollapse={true}>
                                    <p className="text-tertiary fs-14 mb-3">
                                        Upload images or video to accompany your review. Additional images/videos can be added later.
                                    </p>
                                    {/* Uploaded media shown here */}
                                    <div className="border mb-2">
                                        <Row xs={2} sm={2} md={2} lg={5} noGutters>
                                            {embedBody && Array.isArray(embedBody) && embedBody.length ?
                                                embedBody.map((item, uploaded_index) => (
                                                    <Col key={uploaded_index} className={"mr-4"}>
                                                        <div className="d-flex pr-3 m-2">
                                                            <div>
                                                                {/* {item.type === "embedded_link" &&  */}
                                                                <div className="gallery-media d-flex justify-content-center">
                                                                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                                        <img
                                                                            className="img-fluid img-thumbnail h-100"
                                                                            src={item.thumbnail || require("../../assets/images/embedded_link_img.png")}
                                                                            id={`embedded_link-${uploaded_index}`}
                                                                            alt={item.url}
                                                                        />
                                                                        <UncontrolledTooltip
                                                                            autohide="false"
                                                                            placement="top"
                                                                            target={`embedded_link-${uploaded_index}`}
                                                                        >
                                                                            {item.url}
                                                                        </UncontrolledTooltip>
                                                                    </a>
                                                                </div>
                                                                {/* } */}
                                                            </div>
                                                            <div className="mx-n3 mt-2">
                                                                <Button onClick={() => { this.removeEmbeddedFromUploaded(uploaded_index, item) }} color="delete-media" size="sm" title="Remove Media">
                                                                    <FontAwesomeIcon icon="trash-alt" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )) : null}
                                            {mediaSetReview.map((iteam) => (
                                                <Col className={"mr-4"}>
                                                    <div className="d-flex pr-3 m-2">
                                                        <div className="project-thumbnail position-relative">
                                                            <img className="img-fluid img-thumbnail"
                                                                // src={this.checkIfVideo(iteam)}
                                                                src={iteam?.thumbnail}
                                                                alt="Uploaded media" />
                                                            {iteam.type === "video" &&
                                                                <FontAwesomeIcon color="white" size="lg" icon="play-circle" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />}
                                                        </div>
                                                        <div className="mx-n3 mt-2">
                                                            <Button
                                                                className="position-absolute"
                                                                color="delete-media"
                                                                size="sm"
                                                                title="Remove Media"
                                                                onClick={(e) => { this.removeFromMedia(e, iteam, iteam.type) }}>
                                                                <FontAwesomeIcon icon="trash-alt" />{" "}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <Button color="tertiary" className="font-weight-bold" onClick={this.handleCloseAndFetchReview}>
                                        {'Upload Media'}
                                    </Button>
                                </CollapseBasic>
                            </div>
                            {/* Final Step */}
                            <div className="bg-white p-3">
                                <div className="mb-3 ff-alt">
                                    <FormGroup check>
                                        <Input type="checkbox" id="checkbox2" checked={shocialNetworkSharing} disabled={reviewAnonymous} name="shocialNetworkSharing" onChange={this.handelChangeReview} />
                                        <Label className="text-tertiary font-weight-normal fs-14" check>
                                            {' '}
                                            {'Also post this review on social networks.'}
                                        </Label>
                                    </FormGroup>
                                    <div className="mt-3 pl-4">
                                        <FacebookShareButton
                                            url={`${this.state.protocol}//${this.state.host}/business/${businessId}/${businessDetails?.slug ? businessDetails?.slug : 'null'}`}
                                            quote={`Review of ${businessDetails?.name} in ${businessDetails?.address ? businessDetails.address[0].city : ""}, ${businessDetails?.address ? businessDetails.address[0].state : ""}: ${this.state.write_new_review}`}
                                            hashtag={this.state.fbHashTag}
                                            className="text-dark text-decoration-none mx-2">
                                            <Button color={!(shocialNetworkSharing || this.props.facebook) ? "tertiary reset" : "primary"} disabled={!(shocialNetworkSharing || this.props.facebook)} >
                                                {'Post To Facebook'}
                                            </Button>
                                        </FacebookShareButton>

                                        <TwitterShareButton
                                            url={`${this.state.protocol}//${this.state.host}/business/${businessId}/${businessDetails?.slug ? businessDetails?.slug : 'null'}`}
                                            title={`Review of ${businessDetails?.name} in ${businessDetails?.address ? businessDetails.address[0].city : ""}, ${businessDetails?.address ? businessDetails.address[0].state : ""}: ${this.state.write_new_review}`} via={this.state.shareVia}
                                            hashtags={this.state.twitterHashTags}
                                            className="text-dark text-decoration-none mx-2">
                                            <Button color={!(shocialNetworkSharing || this.props.twitter) ? "tertiary reset" : "primary"} disabled={!(shocialNetworkSharing || this.props.twitter)}>Post To Twitter</Button>
                                        </TwitterShareButton>
                                    </div>
                                </div>
                                {my_profile?.review_statistics?.count && my_profile?.review_statistics?.count > 5 ?
                                    <div className="mb-3">
                                        <FormGroup check className=' step-5_Review'>
                                            <Input type="checkbox" id="checkbox2" name="reviewAnonymous" checked={reviewAnonymous} onChange={(e) => {
                                                this.setState({
                                                    reviewAnonymous: e.target.checked,
                                                    shocialNetworkSharing: false
                                                })
                                            }} />
                                            <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                                Make this review anonymous <span className="font-weight-normal">(we will not show your username). <img src={require("../../assets/images/icons/ovel.png")} alt="" /></span>
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    : null}
                                <div className="mb-3">
                                    <FormGroup check>
                                        <Input type="checkbox" id="checkbox2" checked={isadhere} name="isadhere" onChange={this.handelChangeReview} />
                                        <Label className="text-tertiary font-weight-bold fs-14 ff-alt" check>
                                            {' '}
                                            {'I adhere to the '}
                                            <span className="text-dark" onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({ showRevTips: !this.state?.showRevTips }, () => {
                                                    if (this.state?.showRevTips) {
                                                        document.getElementById('reviewTipsAndGuideLines').scrollIntoView({ behavior: "smooth", block: "center" });
                                                    }
                                                });
                                            }} >
                                                {'Review Tips & Guidelines.'}
                                            </span>
                                        </Label>
                                    </FormGroup>
                                </div>
                                <div className="text-right">

                                    <Button size="lg" color="grey" style={{ color: "white" }} className="mw mr-3"
                                        onClick={() => this.clearReveiwRatingState()}
                                    >Cancel</Button>
                                    <span className="text-right step-7_Review" style={{ display: "inline-block" }}>
                                        <Button
                                            disabled={enableErrorForReview}
                                            size="lg"
                                            color="tertiary"
                                            className={reviewAnonymous ? "mw hide-review-block" : "mw"}
                                            onClick={() => this.publishPost("draft")}>
                                            {'Save Draft'}
                                        </Button>
                                        <Button
                                            disabled={enableErrorForReview}
                                            title={enableErrorForReview ? this.state.viewEditReviewStep === "recordVideo" ? "Video Review Required" : "Text Review field Required" : ""}
                                            size="lg"
                                            color="primary"
                                            className="mw"
                                            onClick={() => this.publishPost("publish")}
                                        >Publish</Button>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </RatingCollapse>
                </div>
                <Modal
                    isOpen={this.state.uploadMediaModal}
                    toggle={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal }, () => { this.clearMediaDispatch() })}>
                    <ModalHeader toggle={() => { this.handleCloseAndFetch() }}>UPLOAD MEDIA</ModalHeader>
                    <ModalBody className="p-3">
                        <Row>
                            <Col xs={"auto"}>
                                <div className="bg-light h-100 p-3">
                                    <Nav tabs className="flex-column active-transparent border-0 m-0">
                                        <NavItem className="text-left">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "upload"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "upload" })
                                                }}
                                            >
                                                {'Upload'}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0">
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "gallery"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "gallery" }, () => { this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true }) })
                                                }}
                                            >
                                                {'Gallery'}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <hr className="my-3 bg-tertiary" />
                                        </NavItem>
                                        <NavItem className="text-left border-0" hidden={this.state.uploadType === "menuItem"}>
                                            <NavLink
                                                className="pl-0 pr-4 border-0"
                                                href="#"
                                                active={this.state.selectedUpMediaType === "embed"}
                                                onClick={() => {
                                                    this.setState({ selectedUpMediaType: "embed" })
                                                }}
                                            >
                                                {'Embed'}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </Col>
                            <Col>
                                <TabContent activeTab={this.state.selectedUpMediaType}>
                                    <TabPane tabId="upload">
                                        {selectedFilesPreview && selectedFilesPreview.length === 0 ?
                                            <div className="mb-3 type-file-block bg-light">
                                                <Input
                                                    type="file"
                                                    name="upload_media_file"
                                                    id="uploadFilesFromSystem"
                                                    accept="image/*, video/*"
                                                    onChange={this.handleOnFileUploadChange}
                                                    multiple
                                                />
                                                <Label for="uploadFilesFromSystem">
                                                    <div className="type-bg"></div>
                                                </Label>
                                            </div>
                                            : null}
                                        <div
                                            className="text-right"
                                            style={{
                                                maxWidth: "120px",
                                                margin: "0 1rem 1rem auto",
                                            }}>
                                            <div className="text-center mb-1 small">
                                                {parseInt(calculatedPercentage) === 100 ? (
                                                    <div className="text-success">
                                                        <FontAwesomeIcon
                                                            icon="check-circle"
                                                            className="mr-1"
                                                        />{" "}
                                                        {'Uploaded'}
                                                    </div>
                                                ) : (
                                                    selectedFilesPreview.length > 0 && (
                                                        <div>

                                                            {` Uploading `}
                                                            <span className="text-success font-weight-bold ff-base">
                                                                {calculatedPercentage ? `${parseInt(calculatedPercentage)} %` : ''}
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {selectedFilesPreview.length > 0 && (
                                                <Progress
                                                    value={parseInt(calculatedPercentage)}
                                                    style={{ height: "8px" }}
                                                />
                                            )}
                                        </div>

                                        <Row>
                                            {selectedFilesPreview && selectedFilesPreview.length > 0 ?
                                                selectedFilesPreview.map((item, index) => (
                                                    <Col className="mb-3" xs="auto" key={index}>
                                                        <div className="text-center bg-light p-2">
                                                            <div className="d-inline-flex pr-3">
                                                                <div>
                                                                    <div
                                                                        className="selectable-media"
                                                                        style={{ cursor: "default" }}
                                                                    >
                                                                        <div className="gallery-media">
                                                                            <img src={item} alt={`selected-${index}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="mx-n3 mt-2">
                                                                    <Button
                                                                        color="delete-media _alt"
                                                                        size="sm"
                                                                        title="Remove Media"
                                                                        onClick={(e) => this.removeSelectedImage(e, index, item)}
                                                                    >
                                                                        <FontAwesomeIcon icon="trash-alt" />{" "}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {latestUploadedData &&
                                                                latestUploadedData[index] &&
                                                                (latestUploadedData[index]['isUpload'] !== undefined || latestUploadedData[index]['isUpload'] !== null) ?
                                                                latestUploadedData[index]['isUpload'] ? (
                                                                    <div className="mt-2">
                                                                        <InputGroup>
                                                                            <Input bsSize="sm" type="text" name="caption" className="caption-input" placeholder="Caption" onBlur={(e) => this.handleCaptionByIndex(e, index)} style={{ maxWidth: '160px' }} />

                                                                        </InputGroup>

                                                                        <Input onChange={(e) => this.handleChangeCategory(e, index)} bsSize="sm" type="select" name="album_type_category" id="selectCategoryTypeData" style={{ maxWidth: '160px' }}>
                                                                            <option value="">Choose Category</option>
                                                                            {cattaxListing && Object.keys(cattaxListing).length > 0 ?
                                                                                Object.values(cattaxListing).map(
                                                                                    (data) => (
                                                                                        <>
                                                                                            <option value={data?.id ? data.id : ""}>{data.category}</option>
                                                                                        </>
                                                                                    ))
                                                                                : null}
                                                                        </Input>

                                                                        {uploadedId[index] == latestUploadedData[index].id && (Object.keys(fetchTaxonomyListingImages).length > 0 || Object.keys(fetchTaxonomyListingVideos).length > 0) ?
                                                                            <Input onChange={(e) => this.handleChangeTaxonomyData(e, index)} bsSize="sm" type="select" name="album_subcategory_select" id="selectAlbumTypeData" style={{ maxWidth: '160px' }}>
                                                                                <option value="">Choose Subcategory</option>
                                                                                {latestUploadedData[index]['media_type'] === "image" && fetchTaxonomyListingImages && Object.keys(fetchTaxonomyListingImages).length > 0 && fetchTaxonomyListingImages[index] ?
                                                                                    fetchTaxonomyListingImages[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                                {latestUploadedData[index]['media_type'] === "video" && fetchTaxonomyListingVideos && Object.keys(fetchTaxonomyListingVideos).length > 0 && fetchTaxonomyListingVideos.length > 0 && fetchTaxonomyListingVideos[index] ?
                                                                                    fetchTaxonomyListingVideos[index].map(item => (
                                                                                        <option value={item.id}>{item.label}</option>
                                                                                    ))
                                                                                    : null}
                                                                            </Input>

                                                                            : null}
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-danger">Error while uploading</small>
                                                                    </div>
                                                                ) : (
                                                                    <div className="mt-2 text-center">
                                                                        <small className="text-info">Uploading...</small>
                                                                    </div>
                                                                )}

                                                        </div>
                                                    </Col>
                                                ))
                                                : null}
                                        </Row>
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.handleCloseAndFetch() }}>
                                                    OK
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="gallery">
                                        <div className="mb-2">
                                            <Nav tabs className="d-inline-flex mb-0">
                                                <NavItem>
                                                    <NavLink href="#"
                                                        active={this.state.showGalleryType === "images"}
                                                        onClick={() => {
                                                            this.setState({ showGalleryType: "images", fetchGalleryType: 'image', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                this.props.media_upload_scroll({ type: 'image', album: '', page: 1, force_update: true })
                                                            })
                                                        }}>
                                                        {'Image Gallery'}
                                                    </NavLink>
                                                </NavItem>
                                                {showVideoMedia === true && (
                                                    <NavItem>
                                                        <NavLink href="#"
                                                            active={this.state.showGalleryType === "videos"}
                                                            onClick={() => {
                                                                this.setState({ showGalleryType: "videos", fetchGalleryType: 'video', fetchGalleryAlbum: '', fetchGalleryScroll: {} }, () => {
                                                                    this.props.media_upload_scroll({ type: 'video', album: '', page: 1, force_update: true })
                                                                })
                                                            }}>
                                                            {'Video Gallery'}
                                                        </NavLink>
                                                    </NavItem>
                                                )}
                                            </Nav>
                                        </div>
                                        <TabContent activeTab={this.state.showGalleryType}>
                                            <TabPane tabId="images">
                                                <div>
                                                    <FormGroup className="form-row justify-content-end">
                                                        <Col xs="auto">
                                                            <Label className="small" for="selectAlbumType">Album Type:</Label>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Input onChange={this.handleChangeTaxonomy} bsSize="sm" type="select" name="album_type_select" id="selectAlbumType" defaultValue="all">
                                                                <option value="all">All</option>
                                                                {fetchTaxonomy && Array.isArray(fetchTaxonomy) && fetchTaxonomy.length > 0 ?
                                                                    fetchTaxonomy.map(item => (
                                                                        <option value={item.category}>{item.label}</option>
                                                                    ))
                                                                    : null}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    ref={this.refGalleryImageScroll}
                                                    onScroll={this.handleScrollGalleryImage}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia()}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                <div className="px-2 ">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'Cancel'}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="videos">
                                                <div
                                                    ref={this.refGalleryVideoScroll}
                                                    onScroll={this.handleScrollGalleryVideo}
                                                    className="px-3 my-3"
                                                    style={{ maxHeight: '350px', overflow: 'auto' }}>
                                                    <Row>
                                                        {this.fetchScrollPhotoMedia(true)}
                                                    </Row>
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <div className="px-2">
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mw"
                                                            onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>
                                                            {'OK'}
                                                        </Button>
                                                    </div>
                                                    <div className="px-2">
                                                        <Button color="primary" size="sm" className="mw" onClick={() => this.setState({ uploadMediaModal: !this.state.uploadMediaModal })}>Cancel</Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </TabPane>
                                    <TabPane tabId="embed">
                                        <div className="text-muted mb-2">
                                            Submit the link for the image or video you want to embed{" "}
                                        </div>
                                        <div className="mb-2">
                                            {/* Repeat this while adding */}
                                            <div className="d-flex mx-n2 mb-2" key={0}>
                                                <div className="flex-fill px-2">
                                                    <Input
                                                        type="url"
                                                        bsSize="sm"
                                                        className="bg-white"
                                                        value={embeddedLink}
                                                        onChange={(e) => { this.setState({ embeddedLink: e.target.value }) }}
                                                        placeholder="Embeded link"
                                                    />
                                                </div>
                                                {/* <div className="px-2">
                                                    <Button
                                                        title="Remove"
                                                        color="danger"
                                                        size="sm"
                                                        hidden={false}
                                                        onClick={() => { }}>
                                                        <FontAwesomeIcon icon="minus" />
                                                    </Button>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <div className="d-flex mx-n2 mb-2">
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    title="Add"
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() => { }}>
                                                    <FontAwesomeIcon icon="plus" />
                                                </Button>
                                            </div>
                                        </div> */}
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={() => { this.setState({ embeddedLink: '', uploadMediaModal: false }) }} >
                                                    {'Cancel'}
                                                </Button>
                                            </div>
                                            <div className="px-2 ml-auto">
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="mw"
                                                    onClick={(e) => { this.AddNewEmbedLink(e) }}>
                                                    {'Insert'}
                                                </Button>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>

        )
    }
}



const mapState = (state) => {
    return {
        loggedInUser: state.user.my_profile,
        facebook: state.connections.enable_facebook,
        twitter: state.connections.enable_tiwitter,
        loggedInUsername: state.user?.my_profile?.user?.username,
        facebook_connection: state.user?.my_profile?.facebook_connection,
        twitter_connection: state.user?.my_profile?.twitter_connection,
        business_details: state.business.business_details,
        chart_star_ratings: state.business.chart_star_ratings,
        fetch_photos: state.myProfile.fetch_photos,
        fetch_videos: state.myProfile.fetch_videos,
        fetch_taxonomy: state.myProfile.fetch_taxonomy,
        upload_scroll: state.myProfile.upload_scroll,
        upload_media_data: state.myProfile.upload_media_data,
        fetch_deleted_data: state.myProfile.fetch_deleted_data,
        captcha_items: state.business.captcha_items,
        my_profile: state.user.my_profile,
        business_rating: state.business.business_rating,
        discuss_this_page: state.business.discuss_this_page,
        videos_children: state.business.videos_children && state.business.videos_children[0],
        qa_dropdown: state.business.qa_dropdown,
        fetch_taxonomy_listData: state.myProfile.fetch_taxonomy_listData,
        new_sub_categories: state.business.new_sub_categories,
        filter_data_category: state.business.filter_data_category,
        fetch_data_categories: state.business.fetch_data_categories,
        friends_profile_recommend: state.shared.friends_profile_recommend,
        friends_profile_want: state.shared.friends_profile_want,
        friends_profile_favourite: state.shared.friends_profile_favourite,
        get_reveiewData: state.user.get_reveiewData,
        auth_data: state.auth.auth_login,
        my_privacy_setting: state.user.my_privacy_setting,
        my_privacy_setting_status: state.user.my_privacy_setting_status,
        toggleStarRating: state.utils.toggleStarRating,
        business_reviewDetail: state.business.business_reviewDetail,
        cat_taxonomy_list: state.item_detail.cat_taxonomy_list,
        menuItemChanged: state.business.menuItemChanged
    }
}

const mapProps = (dispatch) => ({
    get_business_detail: (id) => dispatch(get_business_detail(id)),
    get_business_QA_dropdown: (id) => dispatch(get_business_QA_dropdown(id)),
    fetch_media_data: (type, album, page, user_profile) => dispatch(fetch_media_data(type, album, page, user_profile)),
    fetch_taxonomy_category: () => dispatch(fetch_taxonomy_category()),
    media_upload_scroll: ({ type, album, page, force_update }) => dispatch(media_upload_scroll({ type, album, page, force_update })),
    fetch_delete_media: ({ type, page }) => dispatch(fetch_delete_media({ type, page })),
    upload_media_files: (type, data, index) => dispatch(upload_media_files(type, data, index)),
    upload_add_captions: (id, data) => dispatch(upload_add_captions(id, data)),
    clear_media_dispatch: () => dispatch(clear_media_dispatch()),
    delete_mutiple_media: ({ type, data }) => dispatch(delete_mutiple_media({ type, data })),
    single_delete_from_upload: (id, type) => dispatch(single_delete_from_upload(id, type)),
    embedNewLink: ({ data }) => (dispatch(embedNewLink({ data }))),
    getCaptcha: () => dispatch(getCaptcha()),
    save_ratings_vote: (data) => dispatch(save_ratings_vote(data)),
    publish_post: (data, businessId) => dispatch(publish_post(data, businessId)),
    fetch_detailedstarrating: (data) => dispatch(fetch_detailedstarrating(data)),
    get_business_review_detail: (id, filter) => dispatch(get_business_review_detail(id, filter)),
    get_media_children: (category, type, subCategory) => dispatch(get_media_children(category, type, subCategory)),
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })),
    fetch_taxonomy_list: (category, type) => dispatch(fetch_taxonomy_list(category, type)),
    upload_media_filesData: (url, type, data, index) => dispatch(upload_media_filesData(url, type, data, index)),
    fetch_sub_categories: ({ sub_cat, level }) => dispatch(fetch_sub_categories({ sub_cat, level })),
    filter_categories_data: ({ category, sub_cat, level }) => dispatch(filter_categories_data({ category, sub_cat, level })),
    fetch_categories_data: (categoryId, subcategoryId, type, index) => dispatch(fetch_categories_data(categoryId, subcategoryId, type, index)),
    clear_category: () => dispatch(clear_category()),
    clear_subcategory: () => dispatch(clear_subcategory()),
    my_profile_info: () => dispatch(my_profile()),
    get_category_taxonomy_listing: (categoryType, types, mainTaxonomy) => dispatch(get_category_taxonomy_listing(categoryType, types, mainTaxonomy)),
    update_caption_and_categories: ({ id, data }) => dispatch(update_caption_and_categories({ id, data })),
    menuItemChangedStatus: (data) => dispatch(menuItemChangedStatus(data))
});


export default connect(mapState, mapProps)(CreateReviewBusiness);