import { callApi, responseCodeValidator } from "../utils/apiCaller";
import { getQueryString } from '../utils/validation';

const FETCH_PRODUCTS_COLLAGE_ITEMS = 'FETCH_PRODUCTS_COLLAGE_ITEMS';
const FETCH_PRODUCTS_CATEGORIES = 'FETCH_PRODUCTS_CATEGORIES';
const FETCH_PRODUCTS_SUB_CATEGORIES = "FETCH_PRODUCTS_SUB_CATEGORIES";
const FETCH_PRODUCTS_REVIEWS = "FETCH_PRODUCTS_REVIEWS";
const FETCH_PROFESSIONALS_LIST = "FETCH_PROFESSIONALS_LIST";
const FETCH_PRODUCTS_CATEGORY_COLLAGE_LIST = "FETCH_PRODUCTS_CATEGORY_COLLAGE_LIST";
const FETCH_PRODUCTS_LIST = "FETCH_PRODUCTS_LIST";
const IS_LOADING = "IS_LOADING";
const IS_CATEGORIES_LOADING = "IS_CATEGORIES_LOADING";

export const FETCH_FILTERED_DATA = "FETCH_FILTERED_DATA";

const common_fetch = ({ url, method, data, dispatch_function }) => {
    if (data === undefined) {
        data = {};
    }
    return (dispatch) => {
        if(url.includes("/api/professionalsbase/")) dispatch(setIsLoading(true));
        if(url.includes("/api/prfessionalstarratingfilter/")) dispatch(setIsLoading(true));
        if (url.includes(`/api/taxonomy/`)) dispatch(setCategoriesLoading(true));
        

        callApi(url, method, data).then((response) => {
            if (response && responseCodeValidator(String(response.code))) {
                dispatch(dispatch_function(response));
            }

            if(url.includes("/api/professionalsbase/")) dispatch(setIsLoading(false));
            if(url.includes("/api/prfessionalstarratingfilter/")) dispatch(setIsLoading(false));
            if (url.includes(`/api/taxonomy/`)) dispatch(setCategoriesLoading(false));
        });
    };
};

export const fetch_products_newtrends_dispatch = (data) => ({ type: FETCH_PRODUCTS_COLLAGE_ITEMS, payload: data, });
export const fetch_products_newtrends = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/newtrends/`, data }),
        method: "GET",
        dispatch_function: fetch_products_newtrends_dispatch
    })
);

export const fetch_products_categories_dispatch = (data) => ({ type: FETCH_PRODUCTS_CATEGORIES, payload: data, });
export const fetch_products_categories = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/taxonomy/`, data }),
        method: "GET",
        dispatch_function: fetch_products_categories_dispatch
    })
);

export const fetch_products_sub_categories_dispatch = (data) => ({ type: FETCH_PRODUCTS_SUB_CATEGORIES, payload: data, });
export const fetch_products_sub_categories = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/taxonomy/`, data }),
        method: "GET",
        dispatch_function: fetch_products_sub_categories_dispatch
    })
);

export const fetch_products_reviews_list_dispatch = (data) => ({ type: FETCH_PRODUCTS_REVIEWS, payload: data, });
export const fetch_products_reviews_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_products_reviews_list_dispatch
    })
);


// export const fetch_professionals_list_dispatch = (data) => ({ type: FETCH_PROFESSIONALS_LIST, payload: data, });
// export const fetch_professionals_list = (...data) => (
//     common_fetch({
//         url: getQueryString({ url: `/api/professionaltrends/`, data }),
//         method: "GET",
//         dispatch_function: fetch_professionals_list_dispatch
//     })
// );
export const fetch_professionals_list_dispatch = (data) => ({ type: FETCH_PROFESSIONALS_LIST, payload: data, });
export const fetch_professionals_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/professionalsbase/`, data }),
        method: "GET",
        dispatch_function: fetch_professionals_list_dispatch
    })
);
export const fetch_products_category_collage_items_dispatch = (data) => ({ type: FETCH_PRODUCTS_CATEGORY_COLLAGE_LIST, payload: data, });
export const fetch_products_category_collage_items = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/newtrends/`, data }),
        method: "GET",
        dispatch_function: fetch_products_category_collage_items_dispatch
    })
);


export const fetch_products_list_dispatch = (data) => ({ type: FETCH_PRODUCTS_LIST, payload: data, });
export const fetch_products_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_products_list_dispatch
    })
);

export const fetch_friendspick_products_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/friendspick/`, data }),
        method: "GET",
        dispatch_function: fetch_products_list_dispatch
    })
);

export const fetch_recommended_products_list = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_products_list_dispatch
    })
);

export const fetch_filtered_products_dispatch = (data) => ({ type: FETCH_FILTERED_DATA, payload: data, });
export const fetch_filtered_products = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_filtered_products_dispatch
    })
);

export const fetch_filtered_products_category = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/search/`, data }),
        method: "GET",
        dispatch_function: fetch_products_list_dispatch
    })
);

export const fetch_filtered_professionals = (...data) => (
    common_fetch({
        url: getQueryString({ url: `/api/prfessionalstarratingfilter/`, data }),
        method: "GET",
        dispatch_function: fetch_professionals_list_dispatch
    })
);

export const setIsLoading = (data) => {
    return {
        type: IS_LOADING,
        payload: data
    }
}

export const setCategoriesLoading = (data) => {
    return {
        type: IS_CATEGORIES_LOADING,
        payload: data
    }
}
