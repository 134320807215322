import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    Input,
    Label,    
    ModalHeader,
    ModalFooter,
  } from "reactstrap";
  import Loaderimg from "../../../assets/images/w-brand-black.jpg";
  import { Link } from "react-router-dom";
import { Ratings } from "../../molecules/homepage/ShowRatings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Carousel as ReactRespCarousel } from "react-responsive-carousel";
import {
    add_dispute_review,
    get_dispute_discussion,
    add_dispute_reply,
    delete_dispute,
    delete_dispute_reply,
    dispute_modal_status
  } from "../../../actions/dispute";
import Moment from "react-moment";
import DeleteBtn from "../../atoms/deleteBtn";
import ReactHtmlParser from "react-html-parser";


class DisputedReview extends Component {
    constructor(props) {
        super(props)
        this.state = {  
            voteReviewModalToggleFour: false,
            disputeReview: {
                review_id: "",
                reason: "This review is fake",
                comment: "",
                dispute_file: [],
              },  
              reviewAdded: '',
              reviewId: "",
                all_disputes_list: [],
                disputed_tab: false,
                user_names_list: [],
                get_dispute_data: [],
                showExplanation: false,
                disputeReviewId: "",
                is_media_dispute: false,

                dispute_files: [],
                dispute_imgepreview: [],
                get_reveiewhelpCount: [],

                pinStatuses: {},

                dispute_reply_text: "",
                days: undefined,
                hours: undefined,
                minutes: undefined,
                seconds: undefined,
                homeDropdownOpen: {},
                homeShareDropdownOpen: {},

                review: null,
                can_vote: false,
                editFeedError: "",
                feedDropdownOpen: false,
                timer_started: false,
                is_administrative_review: false,
                confirmationToggle: false,
                confirmationReason: '',
                pk_id:'',
                isLoading: false,  
                
                review_id: null,
                review_user_name: null,
                review_profile_pic: null,
                review_target_name: null,
                review_rating: null,
                review_time_stamp: null,
                review_description: null,
                reviewmedia_set: null,
                showEmbedLinks: false,
                showUploadedDocuments: false,
                showUploadedMedia: false,
                disputeValidationError: '',
                currentPage: false,
                resulted: false,
                embedLinks: ['']
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.state.disputeThisReviewModalToggle && nextProps?.get_review_status) {
            this.setState({
              disputeThisReviewModalToggle: false,
            });      
          }
        
        if (
            nextProps.get_dispute_data &&
            Object.keys(nextProps.get_dispute_data).length > 0
          ) {

            if ( nextProps.get_dispute_data?.results?.length === 0 && this.state.currentPage  ) {
                toast("Dispute is under Administrative Review", {
                    autoClose: 3500,
                    className: "black-background",
                    bodyClassName: "red-hunt",
                    progressClassName: "cc",
                  });
                this.props.dispute_modal_status({modal:false})
                this.setState({isLoading: false})
              } 

            else {
                this.setState({
                get_dispute_data: nextProps.get_dispute_data.results,
                dispute_reply_text: "",
                dispute_files: [],
                dispute_imgepreview: [],
                showEmbedLinks: false,
                showUploadedDocuments: false,
                showUploadedMedia: false,

                });
                if (!this.state.timer_started) {
                this.timerSettings();
                this.setState({ timer_started: true });
                }
                this.setState({isLoading: false})
          }
        }
        

        // if (nextProps?.get_dispute_modal_status) {
        //     if( nextProps?.get_dispute_modal_status?.modal ) {
        //     }
        //     if ( nextProps?.get_dispute_modal_status?.modal === false)
        //      {
        //         this.setState({ voteReviewModalToggleFour: false })
        //      }
        // }
    }

    componentDidMount = () => {    
        this.handleHelpAndCastVote(this.props.review)
    }

    // countdown counter
    timeDiffCalc(dateFuture, dateNow) {
        let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
        let difference = {};
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        difference["days"] = days;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;
        difference["hours"] = hours;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        difference["minutes"] = minutes;
        difference["seconds"] = Math.floor(diffInMilliSeconds);

        return difference;
    }

    timerSettings = () => {
        this.interval = setInterval(() => {
        const { get_dispute_data } = this.state;
        if (this.state.voteReviewModalToggleFour === false) {
            this.clearTimerSettings();
        }

        let now = new Date();
        let today = new Date(now);
        let dbDate = new Date(
            get_dispute_data &&
            get_dispute_data[0] &&
            get_dispute_data[0].voting_open_till
        );
        let newDate = dbDate;
        if (dbDate > today) {
            this.setState({ can_vote: true });

            if((newDate.getDate() + 7) > today) {
                this.setState({ resulted: true })
            }
            let countdown = this.timeDiffCalc(dbDate, today);
            this.setState({
            days: countdown["days"],
            hours: countdown["hours"],
            minutes: countdown["minutes"],
            seconds: countdown["seconds"],
            });
        } else {
            this.setState({ can_vote: false });
            this.clearTimerSettings();
        }
        }, 1000);
    };
    //countdown clear counter
    clearTimerSettings() {
        if (this.interval) {
        clearInterval(this.interval);
        }
    }
    
    handleHelpAndCastVote = async (review) => {
        if ( review === null) {
            this.setState({ voteReviewModalToggleFour : false })
            return null
        }
        else {
            this.setState({currentPage: true})
        }
        if(this.props?.source === "NonActioned") {
            this.setState({
                review_id: review?.id,
                review_user_name: (review?.created_by?.user?.first_name + review?.created_by?.user?.last_name),
                review_profile_image: review?.created_user_media?.user?.user?.current_profile_pic,
                review_target_name: review?.associated_to?.name,
                review_rating: review?.ratings_sets,
                review_time_stamp: review?.review_date_time,
                review_description: review?.review_text,
                reviewmedia_set: review?.reviewmedia_set,
                review_user_link: review?.created_by?.vanity_url,
                review_pathname: review?.associated_to?.content_type?.category?.toLowerCase(),
                review_slug: review?.associated_to?.slug,
                review_state: review?.associated_to?.id,
                })
                this.setState({ voteReviewModalToggleFour : true, review: review, reviewId: review?.id })
                this.props.get_dispute_discussion(review?.id);
        }
        else {
            this.setState({
                review_id: review?.action?.id,
                review_user_name: (review?.action?.created_by?.user?.first_name + review?.action?.created_by?.user?.last_name),
                review_profile_image: review?.action?.createdby_current_profile_pic,
                review_target_name: review?.target?.name,
                review_rating: review?.action?.ratings_sets,
                review_time_stamp: review?.time_stamp,
                review_description: review?.action?.review,
                reviewmedia_set: review?.action?.reviewmedia_set,
                review_user_link: review?.actor?.username,
                review_pathname: review?.target?.category?.toLowerCase(),
                review_slug: review?.target?.slug,
                review_state: review?.target?.id
                })
                this.setState({ voteReviewModalToggleFour : true, review: review, reviewId: review?.action?.id })
                this.props.get_dispute_discussion(review.action.id);
        }
        
        this.props.dispute_modal_status({modal:true, source: this.props.source})
    }

    disputeValidate = (rd = "") => {
        const {
          disputeReview,
          dispute_files,
          // eslint-disable-next-line no-unused-vars
          dispute_imgepreview,
          is_media_dispute,
        } = this.state;
        this.setState({ is_administrative_review: false });
        if (disputeReview["reason"] === "This review is fake") {
          if (rd === "") {
            this.setState({ is_media_dispute: false });
          } else {
            this.setState({ is_media_dispute: true });
          }
        } else {
          this.setState({ is_media_dispute: true });
        }
        if (disputeReview["comment"] === "") {
          this.setState({ is_media_dispute: false });
        }
    
        if (
          is_media_dispute &&
          dispute_files.length === 0 &&
          disputeReview["reason"] === "This review is fake"
        ) {
          this.setState({ is_media_dispute: false });
        }
    
        if (disputeReview["reason"] === "Request Administrative Review") {
          this.setState({ is_administrative_review: true });
        }
      };

    handleChange = (e) => {
        const { disputeReview } = this.state;
        this.setState({disputeValidationError:''})
        disputeReview[e.target.name] = e.target.value;
        this.setState({ disputeReview });
        this.disputeValidate();
      };

    handleImageChange = async (e) => {
        e.preventDefault();
        this.setState({disputeValidationError:''})
        // const {dispute_imgepreview,dispute_files} = this.state
        if (e.target.files.length > 1) {
          for (let i = 0; i < e.target.files.length; i++) {
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
              this.setState({
                file: file,
                imagePreview_Url: reader.result,
              });
              this.setState({
                dispute_files: [...this.state.dispute_files, file],
                dispute_imgepreview: [
                  ...this.state.dispute_imgepreview,
                  reader.result,
                ],
              });
            };
            reader.readAsDataURL(file);
            this.disputeValidate(reader);
          }
        } else {
          let reader = new FileReader();
          let file = e.target.files[0];
          reader.onloadend = () => {
            this.setState({
              file: file,
              imagePreview_Url: reader.result,
            });
    
            this.setState({
              dispute_files: [...this.state.dispute_files, file],
              dispute_imgepreview: [
                ...this.state.dispute_imgepreview,
                reader.result,
              ],
            });
          };
          reader.readAsDataURL(file);
          this.disputeValidate(reader);
        }
      };
    
    deleteImage = (index) => {
        var image_array = [...this.state.dispute_files];
        image_array.splice(index, 1);
        this.setState({ dispute_files: image_array });
    
        var preview_array = [...this.state.dispute_imgepreview];
        preview_array.splice(index, 1);
        this.setState({ dispute_imgepreview: preview_array });

        // check Images and documents
        let media_count = 0;
        let doc_count = 0;
        this.state.dispute_imgepreview.map((image, index) => {    
             if (image.toString().includes("data:image") || 
                image.toString().includes("data:video") ) {
                media_count++;
                }
                else {
                doc_count++;
                }               
        });
        if (media_count === 0) {
            this.setState({showUploadedMedia: false })
        } 
        if (doc_count === 0) {
            this.setState({showUploadedDocuments: false })
        }
        this.disputeValidate();
      };
      
    handleReplyChange = (e) => {
        const { value } = e.target;
        this.setState({ dispute_reply_text: value });
        };

    isValidated = () => {
        const { dispute_reply_text } = this.state
        this.setState({ is_administrative_review: false, disputeValidationError: '' });        
        if (dispute_reply_text === ""){
            this.setState({ disputeValidationError: "Please provide explanation to support your vote"})
            return false
        }        
        return true
    }

    handleDisputeReply = (e) => {
        const { dispute_files, dispute_reply_text, reviewId, embedLinks } = this.state;
        if(this.isValidated()) {
            // this.setState({isLoading: true});
            let params = {};
            params["flag_id"] = e[0].flag_id;
            params["flag_content_id"] = e[0].pk;
            params["dispute_reply_file"] = dispute_files;
            params["review_id"] = reviewId;
            params["comment"] = dispute_reply_text;
            params['embedLinks'] = embedLinks
            this.props.add_dispute_reply(params);
        }
        };

    handleDeleteDispute = () => {
        this.setState({ confirmationReason: 'delete_dispute',
                        confirmationToggle: true });
        // this.props.delete_dispute(this.state.disputeReviewId);
        };

    handleDelete =() => {
        const { confirmationReason, pk_id, reviewId } = this.state
        // this.setState({isLoading: true})
        if ( confirmationReason === 'delete_dispute' ) {
            this.props.delete_dispute(reviewId);
            this.props.dispute_modal_status({modal:false, refresh: true})            
        }
        else if( confirmationReason === 'delete_dispute_reply' ) {
            this.props.delete_dispute_reply(pk_id, reviewId);      
        }
        this.setState({confirmationToggle: false})
    
        }
    
    handleDeleteReply = (pk) => {
        this.setState({ confirmationReason: 'delete_dispute_reply',
                        pk_id : pk,
                        confirmationToggle: true });
        // this.props.delete_dispute_reply(pk, this.state.disputeReviewId);
        };

    handleChangeLinks = (e, ind) => {
        const { embedLinks } = this.state;        
        embedLinks[ind] = e.target.value;
        this.setState({ embedLinks })
        }

    handleEmbedLink= (e,option) => {
        e.preventDefault()
        if ( option === '+') {       
            let val = this.state.embedLinks;
            val.push('')     
                this.setState({ embedLinks: val})
            }
        else {
            let val = this.state.embedLinks;
            val.pop()     
                this.setState({ embedLinks: val})
            }
        }


    render() {
        const { } = this.props;
        let {dispute_reply_text,
            get_dispute_data,
            confirmationToggle,
            confirmationReason,
            isLoading,
            review_user_name,
            review_target_name,
            review_rating,
            review_time_stamp,
            review_description,
            showUploadedDocuments,
            showUploadedMedia,
            showEmbedLinks,
            disputeValidationError,
            reviewmedia_set,
            review_profile_image,
            review_user_link,
            review_pathname,
            review_slug,
            review_state,
            embedLinks } = this.state;

        return (
            <div>

                {/* Discussion and reply against dispute by community user */}
                <Modal
                    size="xl"
                    // scrollable
                    className="drawer-modal"
                    isOpen={this.state.voteReviewModalToggleFour}
                    // isOpen = {true}
                    toggle={() =>
                        this.setState({
                        voteReviewModalToggleFour: !this.state.voteReviewModalToggleFour,
                        })
                    }
                    >
                    { isLoading ?
                        <div className="loader_div">
                        <div className="inner-loader">
                            <img src={Loaderimg} alt="" />
                        </div>
                    </div>
                    :
                        ''
                    }
                    <form className="d-flex flex-column h-100">
                        <div className="modal-header flex-wrap p-0">
                        {/* Dispute review intro design */}
                        <div className="row mb-5 form flex-grow-1">
                            <div className="col-lg-4">
                            <div className="bg-dark text-white text-center p-3">
                                <div className="fs-24 ff-headings text-uppercase">
                                Please watch the video to see how Review Disputes work
                                </div>

                                <div className="mt-3">
                                <Row className="justify-content-between align-items-center">
                                    <Col
                                    xs="12"
                                    className="d-flex justify-content-center align-items-center"
                                    >
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <div>
                                        <img
                                            className="img-fluid"
                                            src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                            alt=""
                                        />
                                        </div>
                                    </div>
                                    <div
                                        className="video-holder play-icon d-inline-block mx-2"
                                        role="button"
                                        onClick={() =>
                                        this.setState({
                                            watchHowDisputeReviewWorksModalToggle: true,
                                        })
                                        }
                                    >
                                        <img
                                        height="80"
                                        src={require("../../../assets/images/dispute_jury.webp")}
                                        alt="dispute"
                                        />
                                    </div>
                                    <div className="text-center d-flex align-items-center justify-content-center">
                                        <div>
                                        <img
                                            className="img-fluid"
                                            src={require("../../../assets/images/icons/star/torquise/fill.png")}
                                            alt=""
                                        />
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-8 ml-auto">
                            <div className="bg-warning text-dark p-2 fs-14">
                                <div className="d-flex flex-wrap mx-n2">
                                <div className="text-white px-2">
                                    <span className="font-weight-normal">
                                    You're voting this 
                                    <span className="font-weight-bold">
                                        Disputed Review 
                                    </span>
                                    </span>
                                </div>
                                </div>
                            </div>
                            <div className="bg-white p-3 fs-14 text-dark">
                                    <div className="d-flex mx-n2 mb-2">
                                    <div className="px-2">
                                        <a href="#" className="text-decoration-none">
                                        <img
                                            className="img-circle _50x50"
                                            src={review_profile_image ? review_profile_image : require("../../../assets/images/icons/user-circle.png")}
                                            alt=""
                                        />
                                        </a>
                                    </div>
                                    <div className="px-2 flex-grow-1">
                                        <div className="px-2 flex-grow-1">
                                        <div className="ff-alt">
                                            <a
                                            href={`/people/${review_user_link}`}
                                            className="text-reset ff-base font-weight-bold"
                                            >
                                            {review_user_name} 
                                            </a>
                                            <span> wrote a review for </span>
                                            <Link
                                            to={{
                                                pathname: `/${review_pathname}/${
                                                    review_slug
                                                    ? review_slug
                                                    : "null"
                                                }`,
                                                state: { id: review_state },
                                            }}
                                            className="text-reset font-weight-bold"
                                            >
                                            {review_target_name}
                                            </Link>{" "}
                                            and gave it
                                            <br />
                                            <Ratings
                                            ratings={review_rating }
                                            />
                                            <span> {review_time_stamp}</span>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="ff-alt my-4">
                                    <i className="icon-comma-left"></i>
                                    <span className="comment-body-renderer">
                                        {" "}
                                        {ReactHtmlParser(review_description)}
                                    </span>
                                    <i className="icon-comma-right"></i>
                                    </div>
                                    
                                    
                                    <div className="mb-3">
                                    <ReactRespCarousel className="rr-carousel" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                        {reviewmedia_set && reviewmedia_set.length > 0 ? (
                                            reviewmedia_set.map((data, index1) => {                                            
                                                    return (
                                                        <div key={index1}>
                                                            {data.type === "image" ?
                                                                <img width="100" src={data.url} alt=""/>
                                                            :
                                                                data.type === "video" ?
                                                                <video width="100%" controls>
                                                                    <source src={data.url} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    );
                                                
                                            })
                                        ) :
                                            ''
                                        }
                                    </ReactRespCarousel>
                                    {/* <ReactRespCarousel className="rr-carousel type-media" infiniteLoop={true} showArrows={true} showStatus={false} showIndicators={false}>
                                        
                                        <div>
                                        <video width="100%" controls>
                                            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        </div>
                                        <div>
                                        <img width="100" src="https://stagingdatawikireviews.s3.amazonaws.com/media/content/IMG_3255.77335c8153ce1a59da287d5e67a5e64d2ce9d4fd.JPG" alt="" />
                                        </div>
                                        <div>
                                        <video width="100%" controls>
                                            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        </div>
                                        <div>
                                        <img width="100" src="https://stagingdatawikireviews.s3.amazonaws.com/media/content/IMG_3255.77335c8153ce1a59da287d5e67a5e64d2ce9d4fd.JPG" alt="" />
                                        </div>
                                        <div>
                                        <video width="100%" controls>
                                            <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        </div>
                                    </ReactRespCarousel>
                                     */}
                                    </div>

                                </div>
                                </div>
                        </div>
                        <div className="w-100">
                            <div>
                            <Button
                                color="primary"
                                // onClick={() =>
                                // this.setState({
                                //     voteReviewModalToggleFour: !this.state
                                //     .voteReviewModalToggleFour,
                                // })
                                // }
                                onClick={() => this.props.dispute_modal_status({modal:false})
                                }
                            >
                                <FontAwesomeIcon className="mr-2" icon="angle-left"  />
                                back to listing
                            </Button>
                            </div>
                        </div>
                        </div>
                        <ModalBody className="p-0 fs-14 text-dark bg-transparent">
                        <div className="bg-dark text-white">
                            <Row noGutters>
                            <Col xs={12} lg="auto">
                                <div
                                className="d-flex flex-column justify-content-center bg-tertiary text-white text-center px-5 py-4 h-100"
                                >
                                <div>
                                    <img
                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                    alt=""
                                    />
                                    <h2 className="my-2 fs-50 text-shadow">
                                    REVIEW DISPUTE
                                    </h2>
                                    <img
                                    src={require("../../../assets/images/icons/star/blue/fill.png")}
                                    alt=""
                                    />
                                </div>
                                </div>
                            </Col>
                            <Col xs={12} className="col-lg">
                                <div className="d-flex flex-column justify-content-center bg-dark text-white h-100 p-3">
                                <Row>
                                    <Col>
                                    <div className="pr-md-5">
                                        {get_dispute_data &&
                                        get_dispute_data[0] &&
                                        get_dispute_data[0].is_business_owner ? (
                                        <h3 className="text-light">
                                            Business Owner dispute.
                                        </h3>
                                        ) : (
                                        <h3 className="text-light">
                                            Community dispute.
                                        </h3>
                                        )}
                                        <div className="fs-14">
                                        <div className="text-primary fs-16 font-weight-bold">
                                            Step 1. 
                                            {/* Your Vote is Needed. */}
                                        </div>
                                        <p className="mb-0">
                                            Please review the information submitted by all parties and provide your rebuttal and evidence supporting your position.  Please take note of the time shown on the right that is left for you to submit this information.  
                                        </p> 
                                        
                                        </div>
                                    </div>
                                    </Col>
                                    {/* { new Date(get_dispute_data[0] && get_dispute_data[0].voting_open_till) < new Date() 
                                    ?  */}
                                    {this.state.can_vote ? (
                                    <Col md="auto" className="align-self-end">
                                        <div className="text-light font-weight-bold">
                                        {/* Submission time ends in */}
                                        Dispute Rebuttal <br/>and <br/> Evidence Submission ends in
                                        </div>
                                        <div className="d-flex mx-n2 flex-nowrap">
                                        <div className="px-2">
                                            <div className="text-white">
                                            <div className="fs-32 ff-headings">
                                                {this.state.days}
                                            </div>
                                            <div className="font-weight-bold">days</div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-center">
                                                {this.state.hours} &nbsp; &nbsp;&nbsp;:
                                            </div>
                                            <div className="font-weight-normal">
                                                hours
                                            </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-center">
                                                {this.state.minutes} &nbsp; &nbsp; &nbsp; :
                                            </div>
                                            <div className="font-weight-normal">
                                                minutes
                                            </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-left">
                                                {this.state.seconds}
                                            </div>
                                            <div className="font-weight-normal">
                                                seconds
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </Col>
                                    ) : (
                                    <Col md="auto" className="align-self-end">
                                        <div className="text-light font-weight-bold">
                                        Submission time has ENDED!
                                        </div>
                                        <div className="d-flex mx-n2 flex-nowrap">
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings">00</div>
                                            <div className="font-weight-normal">days</div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-right">
                                                00
                                            </div>
                                            <div className="font-weight-normal">
                                                hours
                                            </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-right">
                                                00
                                            </div>
                                            <div className="font-weight-normal">
                                                minutes
                                            </div>
                                            </div>
                                        </div>
                                        <div className="px-2">
                                            <div className="text-muted">
                                            <div className="fs-32 ff-headings text-right">
                                                00
                                            </div>
                                            <div className="font-weight-normal">
                                                seconds
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </Col>
                                    )}
                                </Row>
                                </div>
                            </Col>
                            </Row>
                        </div>
                        <div className="body-bg px-3 py-3">
                            <Row>
                            <Col>
                            </Col>
                            <Col xs="auto">                    
                            </Col>
                            </Row>
                        </div>
                        <div className="bg-white px-3 py-4">
                            <Row>
                            <Col md={4}>
                                <div className="text-dark fs-16 font-weight-bold mb-2">
                                Dispute History
                                </div>
                                <ul className="d-flex flex-column list-unstyled">
                                <li className="py-2 border-bottom">
                                    <div className="d-flex mx-n1">
                                    <div className="px-1">
                                        <div className="ff-alt">
                                        <Moment format="MMM DD,YYYY">
                                            {get_dispute_data &&
                                            get_dispute_data[0] &&
                                            get_dispute_data[0].created_on}
                                        </Moment>
                                        </div>
                                    </div>
                                    <div className="px-1">
                                        <a
                                        href="#"
                                        className="text-dark ff-base font-weight-bold"
                                        >
                                        {get_dispute_data[0] &&
                                            get_dispute_data[0].user_name}
                                        </a>
                                        &nbsp; disputed as "{" "}
                                        {get_dispute_data[0] &&
                                        get_dispute_data[0].flag_name}{" "}
                                        "
                                    </div>
                                    </div>
                                </li>

                                {/* repeat this for dispute discussion */}
                                {get_dispute_data &&
                                    get_dispute_data.map((discussion) => (
                                    <li className="py-2 border-bottom">
                                        {discussion.child_key_name === 1 ||
                                        discussion.child_key_name === "1" ? (
                                        <div className="d-flex mx-n1">
                                            <div className="px-1">
                                            <div className="ff-alt">
                                                <Moment format="DD/MM/YYYY">
                                                {discussion && discussion.created_on}
                                                </Moment>
                                            </div>
                                            </div>
                                            <div className="px-1">
                                            <a
                                                href="#"
                                                className="text-dark ff-base font-weight-bold"
                                            >
                                                {discussion && discussion.user_name}
                                            </a>
                                            &nbsp; reverted edits by{" "}
                                            {discussion && discussion.content_flagged}"
                                            </div>
                                        </div>
                                        ) : (
                                        ""
                                        )}
                                    </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col md={8}>
                                <div className="mb-3">
                                <div className="text-primary fs-16 font-weight-bold mb-2">
                                    Step 2. 
                                    Dispute Review Reason, Explanation & Evidence.
                                    
                                </div>
                                <div>
                                    <div className="bg-light p-2 text-dark mb-2">
                                    <div className="text-dark">
                                    <span className="text-dark">
                                    {get_dispute_data &&
                                        get_dispute_data[0] &&
                                        get_dispute_data[0].is_business_owner ? (                                        
                                             ' Business Owner'
                                        
                                        ) : (
                                            ' Community Member'
                                        )}
                                        &nbsp;
                                        ( {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].user_name} )

                                        Initiated dispute on 
                                        &nbsp;
                                        <span className="text-reset">
                                                {/* {discussion.created_on} */}
                                                <Moment format="MMM DD,YYYY">
                                                    {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].created_on}
                                                </Moment>
                                                &nbsp; at  &nbsp;
                                                <Moment format="HH:MM">
                                                    {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].created_on}
                                                </Moment>
                                        </span>
                                        &nbsp; and 
                                        </span>
                                        {" "}
                                        Flagged for
                                        <strong>
                                        {" "}
                                        "{" "}
                                        {get_dispute_data &&
                                            get_dispute_data[0] &&
                                            get_dispute_data[0].flag_name}{" "}
                                        "{" "}
                                        </strong>
                                    </div>
                                    <hr className="my-2" />
                                    <div>
                                        <div className="d-flex mx-n2">
                                        <div className="px-2">
                                            <img
                                            className="img-circle _50x50"
                                            src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                            alt=""
                                            />
                                        </div>
                                        <div className="px-2">
                                            <div className="mb-2">
                                            by{" "}
                                            <a 
                                                href={`/people/${get_dispute_data && get_dispute_data[0]?.user_details?.username}`} 
                                                className="text-reset">
                                                <strong>
                                                {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].user_name}
                                                </strong>
                                            </a>
                                            &nbsp;|&nbsp;
                                            <span className="fs-12 text-muted">
                                                Created on&nbsp;
                                                <span className="text-reset">
                                                {/* {discussion.created_on} */}
                                                <Moment format="MMM DD,YYYY">
                                                    {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].created_on}
                                                </Moment>
                                                &nbsp; , &nbsp;
                                                <Moment format="HH:MM">
                                                    {get_dispute_data &&
                                                    get_dispute_data[0] &&
                                                    get_dispute_data[0].created_on}
                                                </Moment>
                                                </span>
                                            </span>
                                            {( this.props?.my_profile?.user?.email) ===
                                            (get_dispute_data && get_dispute_data[0]?.user_details?.email) &&
                                             this.state.can_vote ? (
                                                <DeleteBtn
                                                onClick={() => this.handleDeleteDispute()}
                                                />
                                            ) : (
                                                ""
                                            )}
                                            </div>

                                            <div className="wb-break-word">
                                            "{" "}
                                            {get_dispute_data &&
                                                get_dispute_data[0] &&
                                                get_dispute_data[0].content_flagged}{" "}
                                            "
                                            </div>
                                            <div className="bg-light p-3">
                                            <div className="mb-2">
                                                {get_dispute_data && get_dispute_data[0]?.media === "No media found" ?
                                                ''
                                                :
                                                get_dispute_data &&
                                                get_dispute_data[0] &&
                                                get_dispute_data[0]?.media != "No media found" &&
                                                get_dispute_data[0].media?.map((media) => (
                                                    <div>
                                                    {media.includes(".jpeg") ||
                                                    media.includes(".jpg") ||
                                                    media.includes(".png") ? (
                                                        <div className="mb-2">
                                                        <img
                                                            className="img-fluid"
                                                            src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                            alt=""
                                                        />
                                                        </div>
                                                    ) : (
                                                        <div className="mb-2 d-inline-block">
                                                        <div className="d-flex mx-n2">
                                                            <div className="px-2">
                                                            <FontAwesomeIcon
                                                                icon="paperclip"
                                                                fixedWidth
                                                            />
                                                            </div>
                                                            <div className="px-2 col">
                                                            <span className="font-weight-bold text-truncate">
                                                                <a
                                                                className="text-reset font-weight-bold"
                                                                href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                >
                                                                {
                                                                    media
                                                                    .toString()
                                                                    .split("/")[2]
                                                                }
                                                                </a>
                                                            </span>
                                                            </div>
                                                            <div className="px-2 ml-auto">
                                                            <span className="mr-2">
                                                                |
                                                            </span>
                                                            <a
                                                                href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-reset"
                                                            >
                                                                <FontAwesomeIcon icon="external-link-alt" />
                                                            </a>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    )}
                                                    </div>
                                                ))}                                                
                                                
                                                { get_dispute_data[0]?.embed_links?.length > 0 ?
                                                <div className="fs-14 text-dark font-weight-bold">
                                                    <hr/> Embed Links:                                                
                                                </div>
                                                :
                                                ''
                                                }
                                                
                                                { get_dispute_data[0]?.embed_links?.split(',')?.map((link) =>   
                                                    <div> 
                                                        <Link
                                                            to={{
                                                                pathname: `${link}`
                                                            }}
                                                            target="_blank"
                                                            className="text-reset"
                                                            >                                                     
                                                            {link}
                                                        </Link> 
                                                    </div>
                                                )
                                                }
                                                


                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {get_dispute_data && get_dispute_data.length > 1 ? (
                                    <div className="text-tertiary fs-16 font-weight-bold mt-4">
                                    Discussion between parties
                                    </div>
                                ) : (
                                    ""
                                )}
                                
                                {/* <div className="text-center mb-2">
                                    <div className="text-dark">Dec 16, 2013</div>
                                </div> */}
                                <div>
                                    {get_dispute_data && get_dispute_data.length > 1 ? (
                                    <>
                                    <hr className="mt-2" />
                                    <div className="p-2 text-dark">
                                        {get_dispute_data &&
                                        get_dispute_data.map((discussion) => (
                                            <div className="mb-3">
                                            {discussion.child_key_name === 1 ||
                                            discussion.child_key_name === "1" ? (
                                                <div className="d-flex mx-n2">
                                                <div className="px-2">
                                                    <img
                                                    className="img-circle _30x30"
                                                    src="https://userdatawikireviews.s3.amazonaws.com/media/thumbnails/PANO_20200111_165638.dcf3bff63e1ebdf3db9823eae44c8ffd7ca89c65.jpg"
                                                    alt=""
                                                    />
                                                </div>
                                                <div className="px-2 col">
                                                    <div className="mb-3">
                                                    <div className="d-flex mx-n2 mb-1">
                                                        <div className="px-2 col">
                                                        <a
                                                            href={`/people/${discussion?.user_details?.username}`}
                                                            className="text-reset"
                                                        >
                                                            <strong>
                                                            {discussion.user_name}
                                                            </strong>
                                                        </a>
                                                        &nbsp;
                                                        {/* <span className="fs-12 text-muted">
                                                    Business Owner
                                                </span> */}
                                                        </div>
                                                        <div className="col-auto">
                                                        <div className="text-tertiary d-inline-block mr-2">
                                                            <span className="text-reset">
                                                            <Moment format="MMM DD,YYYY">
                                                                {discussion.created_on}
                                                            </Moment>
                                                            &nbsp; , &nbsp;
                                                            <Moment format="HH:MM">
                                                                {discussion.created_on} 
                                                            </Moment>
                                                            </span>
                                                        </div>
                                                        {( this.props?.my_profile?.user?.email) ===
                                                            (discussion?.user_details?.email)  &&
                                                            this.state.can_vote ? (
                                                            <DeleteBtn
                                                            onClick={() =>
                                                                this.handleDeleteReply(
                                                                discussion.pk
                                                                )
                                                            }
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {discussion.content_flagged}
                                                    </div>
                                                    </div>
                                                    {(discussion && discussion?.media === "No media found") ||
                                                    discussion?.media?.length === 0 ?
                                                    ''
                                                    :                                          
                                                    <div className="bg-light p-3">
                                                    <div className="mb-2">
                                                        {discussion &&
                                                        discussion?.media != "No media found" &&
                                                        discussion.media?.map((media) => (
                                                            <div>
                                                            {media.includes(".jpeg") ||
                                                            media.includes(".jpg") ||
                                                            media.includes(".png") ? (
                                                                <div className="mb-2">
                                                                <img
                                                                    className="img-fluid"
                                                                    src={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                    alt=""
                                                                />
                                                                </div>
                                                            ) : (
                                                                <div className="mb-2 d-inline-block">
                                                                <div className="d-flex mx-n2">
                                                                    <div className="px-2">
                                                                    <FontAwesomeIcon
                                                                        icon="paperclip"
                                                                        fixedWidth
                                                                    />
                                                                    </div>
                                                                    <div className="px-2 col">
                                                                    <span className="font-weight-bold text-truncate">
                                                                        <a
                                                                        className="text-reset font-weight-bold"
                                                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        >
                                                                        {
                                                                            media
                                                                            .toString()
                                                                            .split("/")[2]
                                                                        }
                                                                        </a>
                                                                    </span>
                                                                    </div>
                                                                    <div className="px-2 ml-auto">
                                                                    <span className="mr-2">
                                                                        |
                                                                    </span>
                                                                    <a
                                                                        href={`https://userdatawikireviews.s3-us-west-2.amazonaws.com/${media}`}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                        className="text-reset"
                                                                    >
                                                                        <FontAwesomeIcon icon="external-link-alt" />
                                                                    </a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    </div>
                                                    
                                                    }
                                                    { discussion?.embed_links?.split(',')?.length > 0 ?
                                                        <div className="fs-14 text-dark font-weight-bold">Links</div>
                                                        :
                                                        ''
                                                        }
                                                        
                                                        { discussion?.embed_links?.split(',')?.map((link) =>   
                                                            <div>                                                       
                                                                {link}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                            </div>
                                        ))}
                                    </div>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                    {/* <hr /> */}
                                    {this.state.can_vote ? (
                                    <div>
                                        <div className="attachable-container mb-3">
                                        <Input
                                            type="textarea"
                                            className="primary text-dark fs-14 font-weight-bold ph-normal"
                                            bsSize="sm"
                                            rows="3"
                                            onChange={this.handleReplyChange}
                                            value={dispute_reply_text}
                                            placeholder="Please provide a written explanation in as much detail as possible and upload any and all images/video documents to fully support your vote."
                                        />
                                        <div className="attachable-area">
                                            <hr className="bg-primary mt-0 mb-2" />
                                            <div className="d-flex mx-n1">
                                                <div className="px-1">
                                                    <Label
                                                    title="Embed Link"
                                                    className="text-primary"
                                                    role="button"
                                                    onClick={()=> { this.setState({showEmbedLinks: !this.state.showEmbedLinks})}}
                                                    >
                                                    <FontAwesomeIcon
                                                        icon="link"
                                                        fixedWidth
                                                    />
                                                    </Label>
                                                </div>
                                                <div className="px-1">
                                                    <Input
                                                    type="file"
                                                    id="attach_files_modal"
                                                    multiple
                                                    style={{ display: "none" }}
                                                    onChange={ (e) => {
                                                        this.setState({ showUploadedDocuments: true });
                                                        this.handleImageChange(e);
                                                    }}
                                                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                
                                                    />
                                                    <Label
                                                    for="attach_files_modal"
                                                    title="Upload Documents"
                                                    className="text-primary"
                                                    role="button"
                                                    >
                                                    <FontAwesomeIcon
                                                        icon="paperclip"
                                                        fixedWidth
                                                    />
                                                    </Label>
                                                </div>
                                                <div className="px-1">
                                                    <input
                                                    type="file"
                                                    name="imageUpload"
                                                    id="imageUpload"
                                                    style={{ display: "None" }}
                                                    onChange={ (e) => {
                                                        this.setState({ showUploadedMedia: true });
                                                        this.handleImageChange(e); } }
                                                    multiple
                                                    accept='image/*,video/*'
                                                    />
                                                    <label
                                                    for="imageUpload"
                                                    title="Upload Media"
                                                    className="text-primary"
                                                    role="button"
                                                    >
                                                    <div className="d-flex justify-content-center" style={{ width: '20px' }}>
                                                        <img
                                                        src={require("../../../assets/images/icons/feed-cam.png")}
                                                        alt=""
                                                        />
                                                    </div>
                                                    </label>
                                                </div>
                                                </div>
                                           </div>
                                        </div>

                                        {/* Inset Embed links here */}

                                        { showEmbedLinks ? 
                                            <div> 
                                                { embedLinks?.map((item,index) => 
                                                    <div className="mb-3">
                                                        <div className="fs-14 text-dark font-weight-bold">Embed links</div>
                                                        <div>
                                                        {/* Repeat this */}
                                                        <div className="d-flex flex-nowrap mx-n1 mb-2 col-lg-6 px-0">
                                                            <div className="px-1 col">
                                                                { item ?  

                                                                    <Input className="primary" 
                                                                        type="text" 
                                                                        bsSize="sm" 
                                                                        name={`embedLinks${index}`}
                                                                        id="embed_link"
                                                                        value={item}
                                                                        onChange={(e) => this.handleChangeLinks(e,index)} />

                                                                :

                                                                    <Input className="primary" 
                                                                        type="text" 
                                                                        bsSize="sm" 
                                                                        name={`embedLinks${index}`}
                                                                        id="embed_link"
                                                                        placeholder={`Insert embed link`} 
                                                                        onChange={(e) => this.handleChangeLinks(e,index)} />

                                                                }
                                                            
                                                            </div>
                                                            <div className="px-1">
                                                            <Button color="primary" size="sm" title="Add" className="mr-0" onClick={(e) => this.handleEmbedLink(e,'+')}>
                                                                <FontAwesomeIcon icon="plus"  />
                                                            </Button>
                                                            {/* Hide below if one remains */}
                                                            {embedLinks.length > 1 ?
                                                                <Button color="danger" size="sm" title="Remove" className="ml-2" onClick={(e) => this.handleEmbedLink(e,'-')}>
                                                                    <FontAwesomeIcon icon="minus"  />
                                                                </Button>
                                                                :
                                                                ''
                                                            }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    
                                                ) }
                                                </div>
                                                
                                            :
                                                ''
                                        }
                                        {/* Uploaded Documents shown here */}
                                        { showUploadedDocuments ? 
                                            <div className="mb-3">
                                            <div className="fs-14 text-dark font-weight-bold">Uploaded Documents</div>
                                            <div className="border mb-3">
                                            <Row xs={2} sm={3} lg={4} noGutters>
                                                {this.state.dispute_imgepreview.map((image, index) =>    
                                                (image.toString().includes("data:image") || 
                                                    image.toString().includes("data:video") )  ?
                                                    ''
                                                :                            
                                                <Col>                                
                                                    <div
                                                    className="d-flex pr-3 m-2"
                                                    role="button"
                                                    onClick={() =>
                                                        this.setState({ viewMyPhotosModal: true })
                                                    }
                                                    >
                                                        <div className="flex-grow-1 mw-100">                                   
                                                            <div
                                                                    className="bg-light p-2 text-truncate border fs-13"
                                                                    title={
                                                                    this.state.dispute_files &&
                                                                    this.state.dispute_files[index] &&
                                                                    this.state.dispute_files[index].name
                                                                    }
                                                                >
                                                                    <img
                                                                    width={24}
                                                                    className="mr-2"
                                                                    src={
                                                                        "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/file_icon.png"
                                                                    }
                                                                    alt="Icon"
                                                                    />{" "}
                                                                    {this.state.dispute_files &&
                                                                    this.state.dispute_files[index] &&
                                                                    this.state.dispute_files[index].name}
                                                                    
                                                                </div>
                                                        </div>
                                                        <div className="mx-n3 mt-2">
                                                            <Button
                                                            color="delete-media _alt"                                            
                                                            size="sm"
                                                            title="Remove Media"
                                                            onClick={() => this.deleteImage(index)}
                                                            >
                                                            <FontAwesomeIcon icon="trash-alt" size="sm" />
                                                            </Button>
                                                        </div>                                            
                                                    </div>                                    
                                                </Col>
                                                
                                                
                                                )}
                                            </Row>
                                            </div>
                                        </div>
                                        :
                                            ''
                                        }
                                        {/* Uploaded media shown here */}
                                        { showUploadedMedia ? 
                                            <div className="mb-3">
                                            <div className="fs-14 text-dark font-weight-bold">Uploaded media</div>
                                            <div className="border">
                                            <Row noGutters>
                                                {this.state.dispute_imgepreview.map((image, index) => (
                                                (image.toString().includes("data:image") || 
                                                image.toString().includes("data:video") )  ?
                                                <Col xs="auto">
                                                    <div
                                                    className="d-flex pr-3 m-2"
                                                    role="button"
                                                    onClick={() =>
                                                        this.setState({ viewMyPhotosModal: true })
                                                    }
                                                    >
                                                    <div className="flex-grow-1 mw-100">
                                                        {image.toString().includes("data:image") ? (
                                                        <div className="project-thumbnail">
                                                            <img
                                                                className="img-fluid img-thumbnail"
                                                                src={image}
                                                                alt="dispute"
                                                                title="Image Name"
                                                            />
                                                            
                                                        </div>
                                                        ) : image.toString().includes("data:video") ? (
                                                        <div
                                                            className="bg-light p-2 text-truncate border fs-13"
                                                            title={
                                                            this.state.dispute_files &&
                                                            this.state.dispute_files[index] &&
                                                            this.state.dispute_files[index].name
                                                            }
                                                        >
                                                            <img
                                                            width={24}
                                                            className="mr-2"
                                                            src={
                                                                "https://userdatawikireviews.s3-us-west-2.amazonaws.com/images/dispute/videoicon.jpg"
                                                            }
                                                            alt="Icon"
                                                            />{" "}
                                                            {this.state.dispute_files &&
                                                            this.state.dispute_files[index] &&
                                                            this.state.dispute_files[index].name}

                                                            
                                                        </div>
                                                        ) : null } 
                                                    </div>
                                                    <div className="mx-n3 mt-2">
                                                        <Button
                                                        color="delete-media _alt"
                                                        size="sm"
                                                        title="Remove Media"
                                                        onClick={() => this.deleteImage(index)}
                                                        >
                                                        <FontAwesomeIcon icon="trash-alt" size="sm" />
                                                        </Button>
                                                    </div>
                                                    
                                                    </div>
                                                </Col>
                                                :
                                                ''
                                                ))}
                                            </Row>
                                            </div>
                                        </div>
                                        :
                                            ''
                                        }
                                        <div className="text-right">
                                            <p className="text-danger text-right fs-14">
                                                {disputeValidationError}
                                            </p>             
                                            <Button
                                            color="primary"
                                            onClick={() => this.handleDisputeReply(get_dispute_data)}
                                            >
                                            Post
                                            </Button>
                                            {( this.props?.my_profile?.user?.email) ===
                                                (get_dispute_data && get_dispute_data[0]?.user_details?.email)  &&
                                                this.state.can_vote ? (
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.handleDeleteReply(get_dispute_data && get_dispute_data[0]?.pk)}
                                                    >
                                                    Cancel Dispute
                                                </Button>
                                                ) : 
                                                (
                                                    ""
                                                )
                                            }
                                        </div>
                            
                            </div>
                                    ) : (
                                        this.state.resulted ? 
                                        <div className="text-primary fs-16 font-weight-bold mb-2">
                                            Dispute is resolved! <a> Click here </a> for results.
                                        </div>
                                        :
                                        <div className="text-primary fs-16 font-weight-bold mb-2">
                                            Jury Voting is in progess!
                                        </div>
                                        
                                    )}
                                </div>
                                </div>
                            </Col>
                            </Row>
                        </div>
                        </ModalBody>
                        <ModalFooter className="bg-white"> 
                                         
                        </ModalFooter>
                    </form>
                    </Modal>

                {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                        isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                        toggle={() =>
                            this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state
                                .watchHowDisputeReviewWorksModalToggle,
                            })
                        }
                        >
                        <ModalHeader
                            className="text-uppercase px-0"
                            toggle={() =>
                            this.setState({
                                watchHowDisputeReviewWorksModalToggle: !this.state
                                .watchHowDisputeReviewWorksModalToggle,
                            })
                            }
                        >
                            SEE HOW REVIEW DISPUTES WORK
                        </ModalHeader>
                        <ModalBody className="p-0 bg-transparent">
                            <div className="text-center">
                            <video controls autoPlay width="100%">
                                <source
                                src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                                type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                            </div>
                        </ModalBody>
                    </Modal>

                 {/* SEE HOW REVIEW DISPUTES WORK */}
                <Modal
                    isOpen={this.state.watchHowDisputeReviewWorksModalToggle}
                    toggle={() =>
                        this.setState({
                        watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                    }
                    >
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() =>
                        this.setState({
                            watchHowDisputeReviewWorksModalToggle: !this.state
                            .watchHowDisputeReviewWorksModalToggle,
                        })
                        }
                    >
                        SEE HOW REVIEW DISPUTES WORK
                    </ModalHeader>
                    <ModalBody className="p-0 bg-transparent">
                        <div className="text-center">
                        <video controls autoPlay width="100%" seekable>
                            <source
                            src="https://stagingdatawikireviews.s3-us-west-2.amazonaws.com/media/Dispute.mp4"
                            type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </ModalBody>
                </Modal>
                
                {/* Confirmation Modal */}
                <Modal size="sm" 
                    isOpen={confirmationToggle} 
                    className="text-center" 
                    modalClassName="backdrop-opaque top-shifted transition-none">
                    <div className="bg-white modal-header">
                        <h5 className="modal-title mx-auto text-dark">Confirmation</h5>
                        <button
                        className="btn btn-sm"
                        aria-label="Close"
                        
                        >
                        <span aria-hidden="true" onClick={()=> this.setState({confirmationToggle: false})} >×</span>
                        </button>
                    </div>
                    <ModalBody className="text-dark">
                        {confirmationReason === 'delete_dispute'
                        ?
                        'Do you want to remove your Dispute?'
                        :
                        confirmationReason === 'delete_dispute_reply' ?
                            'Do you want to remove your Reply?'
                        :
                            ''
                        }
                    </ModalBody>
                    <ModalFooter className="bg-white">
                    <div className="text-center w-100">
                    <Button
                        color="primary"
                        size="sm"
                        className="btn-confirm"
                        onClick={()=> this.setState({confirmationToggle: false})}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        size="sm"
                        className="btn-confirm"
                        onClick={()=>this.handleDelete()}
                        
                    >
                        Yes
                    </Button>
                    </div>
                </ModalFooter>
            </Modal>
                  
            </div>
        )
    }
}

const mapState = (state) => ({
    my_profile: state.user.my_profile,
    get_review_status: state.dispute.get_review_status,
    get_dispute_data: state.dispute.get_dispute_data,
    get_dispute_modal_status: state.dispute.get_dispute_modal_status,
});

const mapProps = (dispatch) => ({
    add_dispute_review: (params) => dispatch(add_dispute_review(params)),
    get_dispute_discussion: (params) => dispatch(get_dispute_discussion(params)),
    add_dispute_reply: (params) => dispatch(add_dispute_reply(params)),
    delete_dispute: (id) => dispatch(delete_dispute(id)),
    delete_dispute_reply: (id, reviewId) => dispatch(delete_dispute_reply(id, reviewId)),
    dispute_modal_status: (params) => dispatch(dispute_modal_status(params)),
    // get_reviewgroup: (assetType, postId, type) => dispatch(get_reviewgroup(assetType, postId, type)),    
})


export default connect(mapState, mapProps)(DisputedReview);