const initialState = {
    national: null,
    browse_by_category: null,
    city: null,
    projects: null,
    taxonomy: null,
    top_categories: null,
    current_location: null,
    get_search_home: null,
    add_temp_location: null,
    fetch_search_result: null,
    fetch_add_search_list: null,
    fetch_filter_list: null,
    fetch_search_cities_list: null,
    fetch_subcategories_list: null,
    fetch_additonal_attributes_list: null,
    fetch_categories_listing: null,
    fetch_footer_list: null,
    fetch_my_notification:null,
    get_notification:null,
    search_location: null,
};

const home = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_COLLAGE_NATIONAL":
            state.national = action.payload;
            return { ...state };

        case "BROWSE_BY_CATEGORY":
            state.browse_by_category = action.payload;
            return { ...state };

        case "FETCH_COLLAGE_CITY":
            state.city = action.payload;
            return { ...state };

        case 'FETCH_COLLAGE_PROJECTS':
            state.projects = action.payload;
            return { ...state };

        case 'FETCH_CATEGORY_DISPATCH':
            state.taxonomy = action.payload;
            return { ...state };

        case 'FETCH_TOP_CATEGORY_LIST':
            state.top_categories = action.payload;
            return { ...state };

        case "FETCH_CURRENT_LOCATION":
            state.current_location = action.payload;
            return { ...state };

        case "GET_SEARCH_HOME":
            state.get_search_home = action.payload;
            return { ...state };

        case "ADD_TMPLOCATION":
            state.current_location = action.payload;
            state.add_temp_location = action.payload;
            return { ...state };

        case "FETCH_SEARCH_RESULTS":
            state.fetch_search_result = action.payload;
            return { ...state };

        case "FETCH_FILTER_RESULTS":
            state.fetch_filter_list = action.payload;
            return { ...state };

        case "FETCH_SEARCH_CITIES":
            state.fetch_search_cities_list = action.payload;
            return { ...state };

        case "FETCH_CATEGORIES":
            state.fetch_categories_listing = action.payload;
            return { ...state };

        case "FETCH_SUBCATEGORIES":
            state.fetch_subcategories_list = action.payload;
            return { ...state };

        case "FETCH_ADDITIONAL_ATTRIBUTES":
            state.fetch_additonal_attributes_list = action.payload;
            return { ...state };

        case "ADD_SEARCH_LIST":
            state.fetch_add_search_list = action.payload;
            return { ...state };

        case "FETCH_FOOTER_RESULTS":
            state.fetch_footer_list = action.payload;
            return { ...state };

        case "GET_NOTIFICATION_DATA":
            state.fetch_my_notification = action.payload;
            return { ...state };
        case "FETCH_NOTIFICATION_DATA":
            state.get_notification = action.payload;
            return { ...state };

        case "GET_SEARCH_LOCATION_DATA":
            state.search_location = action.payload;
            return { ...state };
            
        case "SEARCH_RESULTS_LOADING":
            state.searchingResultsLoading = action.payload;
            return { ...state };

        default:
            return state;
    }
}

export default home;
