import React, { useEffect, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap';
import AdminSidebar from './adminSidebar';
import Reviews from './admin_pages/reviews/reviews';
import Users from './admin_pages/users';
 
const reviewTabs = {
  products: "products",
  business: "business",
  movies: "movies",
}

const AdminHome = () =>{
  const [state, setState] = useState({ mainViewType: "users" });
  
  const toggleTab = (tab) => {
    setState((prevState) => ({ ...prevState, mainViewType: tab }));
  }
  return (
    <div className='admin'>
      <AdminSidebar activeTab={state.mainViewType} toggleTab={toggleTab}/>
      <div className='admin-container'>
        <div className='main-content'>
          {/*  */}
          <TabContent activeTab={state.mainViewType}>
            <TabPane tabId="users">
              <Users/>
            </TabPane>
            <TabPane tabId="products">
              <Reviews tabType={reviewTabs.products} currentTab={state.mainViewType} />
            </TabPane>
            <TabPane tabId="business">
              <Reviews tabType={reviewTabs.business} currentTab={state.mainViewType} />
            </TabPane>
            <TabPane tabId="movies">
              <Reviews tabType={reviewTabs.movies} currentTab={state.mainViewType} />
            </TabPane>
          </TabContent> 
        </div>
      </div>
    </div>
  )
}

export default AdminHome;