const initialState = {
    pending_request_count: null,
    sent_friend_request_count: null,
    sent_friend_request: null,
    following_count: null,
    following: null,
    import_count: null,
    connections: null,
    find_connections: null,
    fetch_invited_items: null,
    search_select_connection: null,
    already_on_wikireviews: null,
    providers_on_wikireviews: null,
    enable_tab_forcefully: false,
    enable_facebook: false,
    enable_tiwitter: false,
	get_search_friends:null
};

const connections = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case "FETCH_PENDING_REQUESTS_COUNT":
            state.pending_request_count = action.payload;
            return { ...state };

        case "FETCH_PENDING_REQUESTS":
            state.pending_request = action.payload;
            return { ...state };

        case "FETCH_SENT_FRIEND_REQUEST_COUNT":
            state.sent_friend_request_count = action.payload;
            return { ...state };

        case "FETCH_SENT_FRIEND_REQUEST":
            state.sent_friend_request = action.payload;
            return { ...state };

        case "FETCH_FOLLOWING_COUNT":
            state.following_count = action.payload;
            return { ...state };

        case "FETCH_FOLLOWING":
            state.following = action.payload;
            return { ...state };

        case "FETCH_IMPORT_COUNT":
            state.import_count = action.payload;
            return { ...state };

        case "FETCH_CONNECTIONS":
            state.connections = action.payload;
            return { ...state };

        case "FETCH_FIND_CONNECTION":
            state.find_connections = action.payload;
            return { ...state };

        case "SEARCH_FIND_IMPORT":
            state.imported_contacts = action.payload;
            return { ...state };

        case "CLEAR_FIND_CONNECTIONS":
            state.find_connections = [];
            return { ...state };

        case "FETCH_SELECTED_CONNECTION":
            state.search_select_connection = action.payload;
            return { ...state };

        case "CLEAR_SEARCH_CONNECTIONS":
            state.search_select_connection = null;
            return { ...state };

        case "FETCH_INVITED":
            state.fetch_invited_items = action.payload;
            return { ...state };

        case "ALREADY_ON_WIKIREVIEWS":
            state.already_on_wikireviews = action.payload;
            return { ...state };
			
		case "PROVIDERS_ON_WIKIREVIEWS":
            state.providers_on_wikireviews = action.payload;
            return { ...state };
			
			

        case "FORCEFULLY_ENABLE_TAB":
            state.enable_tab_forcefully = action.payload;
            return { ...state };

        case "FACEBOOK_STATUS":
            state.enable_facebook = action.payload;
            return { ...state };

        case "TIWITTER_STATUS":
            state.enable_tiwitter = action.payload;
            return { ...state };
		
        case "SEARCH_FRIENDS":
            state.get_search_friends = action.payload;
            return { ...state };

        case "IS_LOADING_CONNECTIONS":
            state.is_loading_connections = action.payload;
            return { ...state };

        case "IS_LOADING_FETCH_INVITED":
            state.is_loading_fetch_invited = action.payload;
            return { ...state };

        default:
            return state;
    }
};

export default connections;
