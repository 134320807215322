import React from "react";

export const Ratings = ({ ratings }) => {
  return (
    <>
      <span className="d-inline-block hoverable-rating" title={ratings.avg_rating[1]}>
        {ratings?.avg_rating?.length > 0 ? (
          <img
            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${ratings.avg_rating[0]}`}
            alt=""
          />
        ) : (
          <img
            src="https://userdatawikireviews.s3.amazonaws.com/images/star/blue/0rating.png"
            alt=""
          />
        )}
        {
          <div className="detail-rating-onhover">
            <div className="text-dark font-weight-bold mb-2">
              Detailed Star Rating
            </div>
            {ratings &&
              Object.entries(ratings).map(([key, value]) => {
                return (
                  <div key={key}>
                    <div className="d-flex flex-nowrap align-items-center" title={value[1]}>
                      <img
                        title={value[1]}
                        className="mr-1"
                        src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${value[0]}`}
                        alt={`${value[1]} Rating`}
                      />
                      <span className="ff-alt">{key}</span>
                    </div>
                  </div>
                );
              })}
          </div>
        }
      </span>
    </>
  );
};
