import React from 'react'
import CollapseBasic from '../../atoms/collapse';
import { withRouter } from 'react-router';

const WhatReviewersSay = ({ what_reviewers_say }) => {

    return (
        <div className="bg-white-shaded-md text-secondary mb-3">
            <CollapseBasic title="What Reviewers have to say" containerClass="bg-transparent type-secondary" bodyClass="text-secondary-dark" isOpen={true}>
                <div className='whatReviewersSay'>
                    <ul className="list-unstyled mb-2 fs-14">
                        {
                            what_reviewers_say.reviews.map((item, index) => (
                                <li className="d-flex mb-2" key={index}>
                                    <span className="fs-14 font-weight-bold">“{item.review}”</span><span className='fs-14'>&nbsp;({item.votes})</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </CollapseBasic>
        </div>
    )
}



export default withRouter((WhatReviewersSay));

