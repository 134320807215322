/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import AppHeader from "../oragnisms/dashboard/appHeader";
import { Container, Row, Col, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserFeedsPanel from '../atoms/userFeedsRightPanel';
import { fetch_professional_category_collage_items, fetch_professionals_data_list, clear_professional, fetch_rating_filtered_professionals } from '../../actions/professionals';
// import {
//     fetch_filtered_professionals
// } from '../../actions/productsListing';
import Loader from '../atoms/loader';
import ReactHtmlParser from 'react-html-parser';
import {
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";
import { add_to_list, remove_from_list, share_by_email } from '../../actions/business';
import { get_professional_pins, get_uploaded_media } from '../../actions/itemDetail';
import { capitalizeFirstLetter, pushUniqueObjectsInArray, getShareReviewURL } from '../../utils/utilities';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getSubCategoryDescription, getSubCategoryTitle } from '../../utils/seo-utilities';
import ModalComponent from '../atoms/modalComponent';
import ShareByEmail from '../atoms/shared/shareByEmail';
class ProfessionalsCategory extends Component {
    constructor(props) {
        super(props)

        this.enableScrollTillEnd = true;

        this.state = {
            currentPageIndex: 1,
            loadingState: true,
            checkNextPagination: false,
            myConnectionActivity: 'all',
            viewEditReviewStep: 'writeReview',
            category: '',
            subCategory: '',
            collage_items: [],
            data_list: [],
            homeDropdownOpen: {},
            homeShareDropdownOpen: {},
            host: window.location.host,
            protocol: window.location.protocol,
            shareTitle: '',
            shareVia: 'WikiReviews',
            fbHashTag: '#WikiReviews',
            twitterHashTags: ['WikiReviews'],
            shareByEmailModalToggle: false,
            shareName: '',
            shareEmail: '',
            shareId: 0,
            shareError: {},
            categoryBlueImage: "",
            categoryNeturalImage: "",
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            uploadedMediaSet: {
                embedLinks: {},
                uploadedFiles: [],
            },
            isEnableFileUpload: false,
            this_user: null,
            starRatingFilterEnabled: false,
            starRatingFilterValue: null,
        }
        this.onMouseEnterCategory = this.onMouseEnterCategory.bind(this);
        this.onMouseLeaveCategory = this.onMouseLeaveCategory.bind(this);
        this.onMouseShareEnterCategory = this.onMouseShareEnterCategory.bind(this);
        this.onMouseShareLeaveCategory = this.onMouseShareLeaveCategory.bind(this);
    }

    componentDidMount = () => {
        const { match } = this.props

        let imageOne = "";
        let imageTwo = "";
        if (match?.params?.category == "accountants") {
            imageOne = "accountants_neutral_turquoise.jpg";
            imageTwo = "accountants_neutral_blue.jpg";
            document.title = `Accountants Reviews on Wikireviews`;
        } else if (match?.params?.category == "dentists") {
            imageOne = "dentists_neutral_turquoise.jpg";
            imageTwo = "dentists_neutral_blue.jpg";
            document.title = `Dentists Reviews on Wikireviews`;
        } else if (match?.params?.category == "doctors") {
            imageOne = "doctors_neutral_turquoise.jpg";
            imageTwo = "doctors_neutral_blue.jpg";
            document.title = `Doctors Reviews on Wikireviews`;
        } else if (match?.params?.category == "actors") {
            imageOne = "actors_neutral_turquoise.jpg";
            imageTwo = "actors_neutral_blue.jpg";
            document.title = `Actor and Actress Reviews on Wikireviews`;
        } else if (match?.params?.category == "lawyers") {
            imageOne = "lawyers_neutral_turquoise.jpg";
            imageTwo = "lawyers_neutral_blue.jpg";
            document.title = `Lawyers Reviews on Wikireviews`;
        }
        this.props.clear_professional();
        this.setState({ data_list: [], category: match?.params?.category, categoryBlueImage: imageOne, categoryNeturalImage: imageTwo }, () => {
            let content = match?.params?.category;
            this.props.fetch_professional_category_collage_items({ content_type: content });
            // this.props.fetch_professional_category_collage_items({ content_type__category: content });
            // this.props.fetch_professionals_data_list({ content_type: content, page: this.state.currentPageIndex });
            this.props.fetch_professionals_data_list({ content_type__category: content, page: this.state.currentPageIndex });
            this.setState({ loadingState: true });
        })
        window.scrollTo(0, 0)
        window.addEventListener('scroll', this.handleScroll, { passive: false });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTillHeight = window.pageYOffset + 400;
        let lastElemHeight = document.getElementById("last-index")?.offsetTop;
        const { currentPageIndex, checkNextPagination, starRatingFilterEnabled, starRatingFilterValue } = this.state;
        const { match } = this.props;
        const content = match?.params?.category;

        if (scrollTillHeight > lastElemHeight && checkNextPagination && !this.props.isLoading) {

            if (this.enableScrollTillEnd) {
                this.enableScrollTillEnd = false;
                const tempCurrentPageIndex = currentPageIndex + 1;
                this.setState({ currentPageIndex: tempCurrentPageIndex, loadingState: true }, () => {

                    if (starRatingFilterEnabled) {
                        const queryParams = {
                            content_type: this.state.category === 'actors' ? this.state.category : 'professionals',
                            stars: starRatingFilterValue,
                            cat: capitalizeFirstLetter(this.state.category),
                            subcat: this.state.subCategory,
                            to_api: true,
                            showReviews: true,
                            location: false,
                            page: tempCurrentPageIndex,
                            rating_filter: true
                        };
                        this.props.fetch_rating_filtered_professionals(queryParams);

                    } else {
                        this.props.fetch_professionals_data_list({ content_type__category: content, page: tempCurrentPageIndex });
                    }
                    //     cPage: true,
                    //     cat: '',
                    //     content_type: 'products',
                    //     location: false,
                    //     page: this.state.currentPageIndex,
                    //     showReview: true,
                    //     to_api: true
                    // });
                    
                });
            }
        }
    }

    componentWillReceiveProps = (nextProps) => {
        const { data_list, loadingState } = this.state;
        if (nextProps.this_user) {
            this.setState({this_user: nextProps.this_user.id})
        }

        if (nextProps?.collage_items) {
            this.setState({ collage_items: nextProps?.collage_items?.results })
        }

        if (nextProps?.current_location) {
            if (this.state.current_location?.lat !== nextProps?.current_location?.lat) {
                this.setState({ current_location: nextProps?.current_location }, () => {
                    this.props.fetch_professional_category_collage_items({ content_type: this.state.category });
                })
            }

        }

        // if (nextProps.data_list && nextProps.data_list.results && nextProps.data_list.results.length > 0) {
        //     this.setState({ data_list: nextProps.data_list });
        // }

        // if (nextProps?.data_list?.results && this.state.data_list?.length) 
        // {
        //     let data_list = this.state.data_list;
        //     data_list = data_list.concat(nextProps.data_list?.results);
        //     this.enableScrollTillEnd = true;
        //     this.setState({
        //         data_list,
        //         checkNextPaination: nextProps.data_list?.next
        //     });
        // } else {

        //     this.setState({
        //         data_list: nextProps?.data_list?.results,
        //         checkNextPaination: nextProps.data_list?.next
        //     });
        // }
        if (nextProps?.data_list?.results && !nextProps.isLoading) {
            const newDataList = data_list.length ? pushUniqueObjectsInArray(data_list, nextProps?.data_list?.results, 'id') : nextProps?.data_list?.results;
            this.enableScrollTillEnd = true;
            this.setState({
                loadingState: false,
                data_list: newDataList,
                checkNextPagination: nextProps.data_list?.next
            });
        }

        // if (nextProps?.data_list?.results && !this.props.data_list?.results) {
        //     // const newDataList = data_list.length ? pushUniqueObjectsInArray(data_list, nextProps?.data_list?.results, 'id') : nextProps?.data_list?.results;
        //     // this.enableScrollTillEnd = true;
        //     this.setState({
        //         loadingState: false,
        //         data_list: nextProps?.data_list?.results,
        //         // checkNextPagination: nextProps.data_list?.next
        //     });
        // }

        if("isLoading" in nextProps){
            this.setState({
                loadingState: nextProps.isLoading
            });
        }

    }

    componentDidUpdate(prevProps) {
        const previousContent = prevProps?.match?.params?.category
        const newContent = this.props?.match?.params?.category
        if (previousContent != newContent) {
            let imageOne = "";
            let imageTwo = "";
            if (newContent == "accountants") {
                imageOne = "accountants_neutral_turquoise.jpg";
                imageTwo = "accountants_neutral_blue.jpg";
            } else if (newContent == "dentists") {
                imageOne = "dentists_neutral_turquoise.jpg";
                imageTwo = "dentists_neutral_blue.jpg";
            } else if (newContent == "doctors") {
                imageOne = "doctors_neutral_turquoise.jpg";
                imageTwo = "doctors_neutral_blue.jpg";
            } else if (newContent == "actors") {
                imageOne = "actors_neutral_turquoise.jpg";
                imageTwo = "actors_neutral_blue.jpg";
            } else if (newContent == "lawyers") {
                imageOne = "lawyers_neutral_turquoise.jpg";
                imageTwo = "lawyers_neutral_blue.jpg";
            }
            this.props.clear_professional();

            this.setState({ data_list: [], category: newContent, categoryBlueImage: imageOne, categoryNeturalImage: imageTwo, loadingState: true }, () => {
                this.props.fetch_professional_category_collage_items({ content_type: newContent });
                // this.props.fetch_professional_category_collage_items({ content_type__category: newContent });
                // this.props.fetch_professionals_data_list({ content_type: newContent, page: 1 })
                this.props.fetch_professionals_data_list({ content_type__category: newContent, page: 1 })
            })
        }
    }

    toggleCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = !expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseEnterCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    onMouseLeaveCategory = (expand, id) => {
        let { homeDropdownOpen } = this.state;
        homeDropdownOpen[id] = expand;
        this.setState({ homeDropdownOpen }, () => {

        });
    }

    toggleShareCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = !expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareEnterCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    onMouseShareLeaveCategory = (expand, id) => {
        let { homeShareDropdownOpen } = this.state;
        homeShareDropdownOpen[id] = expand;
        this.setState({ homeShareDropdownOpen }, () => {

        });
    }

    handleStarFilter = (star) => {
        this.setState({
            loadingState: true,
            data_list: [],
            starRatingFilterEnabled: true,
            starRatingFilterValue: star,
            currentPageIndex: 1
        });
        this.props.fetch_rating_filtered_professionals({
            content_type: this.state.category === 'actors' ? this.state.category : 'professionals',
            stars: star,
            cat: capitalizeFirstLetter(this.state.category),
            subcat: this.state.subCategory,
            to_api: true,
            showReviews: true,
            location: false,
            page: 1,
            rating_filter: true
        });
        window.scrollTo(0, 0);
    }

    handleOnChangeShareByEmail = (e) => {
        let errors = { ...this.state.shareError };
        if (e.target.value) {
            delete errors[e.target.name];
        }

        this.setState({
            [e.target.name]: e.target.value,
            shareError: errors
        });
    }

    handleOnSubmitShareByEmail = ({ shareEmail, shareName, shareNote }) => {
        const key = 'listing_id';
        let url = `/api/professionalShareByemail/`          
        let review_id = this.state.shareId;
        let data;
        if (review_id) {
          data = {
            review_id: review_id,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        } else {
          data = {
            [key]: this.state.shareId,
            mix_uid: `${shareEmail.split('@')[0]}_${this.state.shareId}_1597643911786`,
            email: shareEmail,
            user_name: shareName,
            // listing_type: this.props.mainCategory,
            optional_note: shareNote
          };
        }
    
        this.props.share_by_email(url, data);
      }

    handleOnClickShareByEmail = (id) => {
        this.setState({
            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
            shareName: '',
            shareEmail: '',
            shareError: {},
            shareId: id
        });
    }

    handleOnClickAddToList = (type, status, obj) => {

        if (this.state.data_list?.results || this.state.data_list) {

            let { data_list } = this.state;
            let message = '',
                url = '',
                nextUrl = '',
                data = { type };

            let itemDetails = obj;
            let pinList = itemDetails?.user_recommending?.pin_list_value ? [...itemDetails?.user_recommending?.pin_list_value] : [];
            const newContent = this.props?.match?.params?.category
            if (status) {
                message = `${itemDetails.firstname} has been deleted from your ${type}`;
                //url = `/api/professionalpins/?id=${itemDetails.listing_id}&type=${type}`;
                url = `/api/professionalpins/${itemDetails.id}/?type=${type}`;
                this.props.remove_from_list(url, message, nextUrl,newContent);
                pinList = pinList.filter(item => {
                    return item !== type.split(' ').join('_');
                });
            } else {
                //data.professionaluser_entry = itemDetails.listing_id;
                data.professionaluser_entry = itemDetails.id;
                message = `${itemDetails.firstname} has been added to your ${type}`;
                url = `/api/professionalpins/`;
                this.props.add_to_list(url, data, message, nextUrl,newContent);
                pinList.push(type.split(' ').join('_'));
            }
            
           
            itemDetails.user_recommending = {
                pin_list_value: pinList
            };
            if (data_list?.results) {
                data_list.results = data_list.results.map(item => {
                    //if (item.listing_id === obj.listing_id) {
                    if (item.id === obj.id) {
                        return itemDetails;
                    }
                    return item;
                });
            } else {
                data_list = data_list.map(item => {
                    //if (item.listing_id === obj.listing_id) {
                    if (item.id === obj.id) {
                        return itemDetails;
                    }
                    return item;
                });

            }

            this.setState({
                data_list
            });
        }
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
    }

    onPublishReview = () => {
        this.setState({
            toggleItemCreateReview: {},
            toggleItemReadMore: {},
            data_list: []
        }, () => {
            this.props.fetch_professionals_list({ content_type__category: this.state.tabType, page: this.state.currentPageIndex });
        })
    }

    closeAfterCancel = () => {
        this.setState({ toggleItemCreateReview: {} })
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        const key = 'id'
        if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
            let mediaSetStored = uploadedMediaSetCopy?.uploadedFiles?.concat(mediaSet)
            const mediaSetUnique = [...new Map(mediaSetStored?.map(item =>
                [item[key], item])).values()];
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    uploadedFiles: mediaSetUnique
                }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);
            })
        }
        if (links && Array.isArray(links) && links.length > 0) {
            let linksStored = uploadedMediaSetCopy?.embedLinks?.concat(links)            
            this.setState(prevState => ({
                uploadedMediaSet: {
                    ...prevState.uploadedMediaSet,
                    embedLinks: linksStored
                }
            }), () => {
                this.props.get_uploaded_media(uploadedMediaSet);
            })
        }
    }

    handleOnClickUploadModalOk = () => {
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }
    renderItems = () => {
        const { data_list, category, toggleItemReadMore, } = this.state;
        const { match } = this.props;
        const content = match?.params?.category;
        if (data_list && Array.isArray(data_list) && data_list?.length) {
            return (
                <>
                    {data_list.map((data, index) => (<div
                        id={`${data_list?.length == index + 1 ? 'last-index' : `current-index-${index}`}`}
                        className="mb-3" key={index}>
                        {/* show listing data */}
                        <div className="bg-white text-dark p-3 mb-1">
                            <div className="d-flex mx-n3 mb-2">
                                <div className="px-3 mb-3 mb-lg-0">
                                    <a href="#" className="text-decoration-none">
                                        <img className="img-circle _90x90"
                                            src={data.professional_profileimage && data.professional_profileimage.length > 0 && data.professional_profileimage[0] != "Not Available" ? data.professional_profileimage[0].location : require("../../assets/images/icons/user-circle.png")} 
                                            alt={data?.firstname + " " + data?.lastname}
                                            onError={(error) => (error.target.src = require("../../assets/images/icons/user-circle.png"))} />
                                    </a>
                                </div>
                                <div className="px-3 col">
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 col">
                                            <div className="ff-base">
                                                <Link
                                                    to={{ pathname: `/${category}/${data?.id}`, state: { id: data?.id } }}
                                                    className="fs-16 text-royal-blue ff-base font-weight-bold text-decoration-none">
                                                    {data?.firstname} {data?.lastname}
                                                </Link>
                                                <div className="mt-1">
                                                    <span className="d-block">
                                                        <img title={data?.ratings_score?.avg_rating[1]}
                                                            src={require(`../../assets/images/icons/star/blue/${data?.ratings_score?.avg_rating[0]}`)}
                                                            // src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${data?.ratings_score?.avg_rating[0]}`} 
                                                            alt={data?.ratings_score?.avg_rating[1]}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 col-auto">
                                            <div className="d-inline-flex flex-nowrap mx-n1">
                                                <div className="px-1">
                                                    <UncontrolledDropdown onMouseOver={() => this.onMouseShareEnterCategory(true, data?.id)}
                                                        onMouseLeave={() => this.onMouseShareLeaveCategory(false, data?.id)}
                                                        isOpen={this.state.homeShareDropdownOpen[data?.id]}
                                                        toggle={() => this.toggleShareCategory(this.state.homeShareDropdownOpen[data?.id])}>
                                                        <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                            <div className="share-it-icon"></div>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="type-tertiary-alt" right>
                                                            <DropdownItem className="fs-14">
                                                                <FacebookShareButton
                                                                    // url={`${this.state.protocol}//${this.state.host}/${category}/${data?.slug ? data?.slug : 'null'}`}
                                                                    url={`${getShareReviewURL(data?.content_type_name, data?.slug, data?.id)}`}
                                                                    quote={data?.firstname ? `Checkout ${data?.firstname} ${data?.lastname} on` : ''}
                                                                    hashtag={this.state.fbHashTag}>
                                                                    {'Facebook'}
                                                                </FacebookShareButton>
                                                            </DropdownItem>
                                                            {/* <DropdownItem className="fs-14">Google +</DropdownItem> */}
                                                            <DropdownItem className="fs-14">
                                                                <TwitterShareButton
                                                                    url={`${getShareReviewURL(data?.content_type_name, data?.slug, data?.id)}`}
                                                                    title={data?.firstname ? `Checkout ${data?.firstname} ${data?.lastname} on` : ''}
                                                                    via={this.state.shareVia}
                                                                    hashtags={this.state.twitterHashTags}>
                                                                    {'Twitter'}
                                                                </TwitterShareButton>
                                                            </DropdownItem>
                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickShareByEmail(data?.latest_review?.id)}>By Email</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                                <div className="px-1">
                                                    <UncontrolledDropdown onMouseOver={() => this.onMouseEnterCategory(true, data?.id)}
                                                        onMouseLeave={() => this.onMouseLeaveCategory(false, data?.id)}
                                                        isOpen={this.state.homeDropdownOpen[data?.id]}
                                                        toggle={() => this.toggleCategory(this.state.homeDropdownOpen[data?.id])}>
                                                        <DropdownToggle className="p-0 text-dark" size="sm" color="transparent">
                                                            <div className="thumbpin-icon"></div>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="type-tertiary-alt" right>
                                                            {/* Old */}
                                                            {this.state.category !== "actors" ?
                                                                <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('want list', data?.pins_status?.want_list, data)}>
                                                                    <FontAwesomeIcon icon="heart" className="mr-2" fixedWidth />

                                                                    {data?.pins_status?.want_list ? 'Added' : 'Add'} to want list
                                                                </DropdownItem>
                                                                :
                                                                ''
                                                            }
                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('favourite list', data?.pins_status?.favourite_list, data)}>
                                                                <FontAwesomeIcon icon="star" className="mr-2" fixedWidth />
                                                                {data?.pins_status?.favourite_list ? 'Added' : 'Add'} to favorites
                                                            </DropdownItem>
                                                            <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('watch list', data?.pins_status?.watch_list, data)}>
                                                                <FontAwesomeIcon icon="eye" className="mr-2" fixedWidth />
                                                                {data?.pins_status?.watch_list ? 'Added' : 'Add'} to watch list
                                                            </DropdownItem>
                                                            {/* new */}
                                                            {/* <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('want list', data?.pins_status?.includes('want_list'), data)}><FontAwesomeIcon icon="heart" className="mr-2" fixedWidth /> {data?.pins_status?.includes('want_list') ? 'Added' : 'Add'} to want list</DropdownItem>
                                                                                <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('favourite list', data?.pins_status?.includes('favourite_list'), data)}><FontAwesomeIcon icon="star" className="mr-2" fixedWidth /> {data?.pins_status?.includes('favourite_list') ? 'Added' : 'Add'} to favorites</DropdownItem>
                                                                                <DropdownItem className="fs-14" onClick={() => this.handleOnClickAddToList('watch list', data?.pins_status?.includes('watch_list'), data)}><FontAwesomeIcon icon="eye" className="mr-2" fixedWidth /> {data?.pins_status?.includes('watch_list') ? 'Added' : 'Add'} to watch list</DropdownItem> */}
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>
                                            <div className="text-dark font-weight-bold mb-2">
                                                Detailed Star Rating
                                            </div>
                                            {Object.entries(data?.ratings_score).map(([key, value]) =>
                                                <div>
                                                    {key === 'total_review' || key === 'avg_rating' ? '' :
                                                        <div className="d-flex flex-nowrap align-items-start mb-1">
                                                            <img title={value[1]} className="mr-1" src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${value[0]}`} alt="no Rating" />
                                                            <span className="ff-alt text-dark lh-1-2">{key}</span>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="text-right mt-3">
                                        <Link to={{ pathname: `/${category}/${data?.id}`, state: { id: data?.id } }}
                                            className="text-dark font-weight-bold">view more</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Show All Reviews */}
                        {data?.latest_review?.review ?
                            <div className="mb-1">
                                {/* Repeat this */}
                                <div className="bg-white text-dark p-3 mb-1">
                                    <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2">
                                            <a href="#" className="text-decoration-none">
                                                {data?.latest_review?.created_by?.current_profile_pic && (
                                                    <img
                                                        className="img-circle _30x30"
                                                        src={data?.latest_review?.created_by?.current_profile_pic ? data?.latest_review?.created_by?.current_profile_pic : `https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg`}
                                                        onError={(err) => err.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/movie-poster-avatar.jpg`}
                                                        alt="thumbnail"
                                                    />
                                                )}
                                            </a>
                                        </div>
                                        <div className="px-2 flex-grow-1">
                                            <div className="ff-base text-dark">
                                                <a
                                                    href={`/people/${data?.latest_review?.created_by.username}/`}
                                                    className="text-reset font-weight-bold">
                                                    {`${data?.latest_review?.created_by?.name || ''}`}
                                                </a>
                                                {/* <span className="text-reset ff-base font-weight-bold mr-2">
                                                                    {`${data?.latest_review?.created_by?.name || ''}`}
                                                                </span> */}
                                                {data?.latest_review?.created_by?.ratings_set?.avg_rating &&
                                                    Array.isArray(data?.latest_review?.created_by?.ratings_set?.avg_rating) &&
                                                    data?.latest_review?.created_by?.ratings_set?.avg_rating?.length ?
                                                    <>
                                                        &nbsp; gave it &nbsp;
                                                        <img
                                                            title={data?.latest_review?.created_by?.ratings_set?.avg_rating[1]}
                                                            src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${data?.latest_review?.created_by?.ratings_set?.avg_rating[0]}`}
                                                            alt={data?.latest_review?.created_by?.ratings_set?.avg_rating[1]}
                                                        />
                                                    </>
                                                    : null}
                                                <br />
                                            </div>

                                            {/* User Reply */}
                                            <div className="ff-alt mt-2 wb-break-word">
                                                {data?.latest_review && data?.latest_review?.review !== '' ?
                                                    ReactHtmlParser((toggleItemReadMore[data?.id]) ?
                                                        (data?.latest_review?.review) :
                                                        (data?.latest_review?.review).substring(0, 120)) : null}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-right mt-3">                                       
                                        <Link to={{ pathname: `/${category}/${data?.id}`, state: { id: data?.id } }}
                                            className="text-primary font-weight-bold cursor-pointer" hidden={data?.is_logged_user_review}>
                                            {this.state.this_user === data?.latest_review?.user?.id ? "Write your follow-up" : "Write your own"}
                                        </Link>
                                        <span className="mx-2"></span>
                                        {/*<span
                                                                onClick={() => {
                                                                    if (toggleItemReadMore[data?.listing_id] == undefined) {
                                                                        toggleItemReadMore[data?.listing_id] = true;
                                                                    } else {
                                                                        toggleItemReadMore[data?.listing_id] = !toggleItemReadMore[data?.listing_id];
                                                                    }
                                                                    this.setState({ toggleItemReadMore });
                                                                }}
                                                                className="text-dark font-weight-bold cursor-pointer">
                                                                {toggleItemReadMore[data?.listing_id] ? 'less more' : 'read more'}
                                                            </span>*/}
                                        <Link to={{ pathname: `/${category}/${data?.id}`, state: { id: data?.id } }}
                                            className="text-primary font-weight-bold cursor-pointer"><span className="text-dark font-weight-bold cursor-pointer">read more</span></Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="mb-1">
                                <div className="bg-white text-dark p-3 mb-1">
                                    <div className="d-flex mx-n2 mb-2">
                                        <div className="px-2 flex-grow-1">
                                            <div className="ff-alt mt-2 wb-break-word">
                                                Be the First to Write A Review About This Listing! (And Get Your First Reviewer Badge!)
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-right mt-3">
                                        <Link to={{ pathname: `/${category}/${data?.id}`, state: { id: data?.id } }}
                                            className="text-primary font-weight-bold cursor-pointer">
                                            Write your own
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>))}
                    {
                        (this.props.isLoading) ?
                            <div className="bg-white p-3 text-secondary-dark">
                                <h2>{`Loading more...`}</h2>
                            </div> : ''
                    }
                    <div style={{height: "150px"}}></div>
                    
                </>
            )
        }
    }

    render() {
        let { data_list, category, collage_items, toggleItemReadMore, } = this.state;

        return (
            <div>
                <HelmetMetaData
                    title={getSubCategoryTitle("Professional", this.state.category)}
                    description={getSubCategoryDescription("Professional", this.state.category)}
                />
                {
                    this.state.loadingState ? <Loader /> : ''
                }
                <AppHeader isEnableCover={false} />
                <header style={{ overflowY: 'hidden' }}>
                    <Row noGutters xs={1} md={4}>
                        <Col className="d-flex flex-column">
                            <Link
                                to={{
                                    pathname: `/${collage_items[0]?.content_type_name}/${collage_items[0]?.listing_id}`,
                                    state: { id: collage_items[0]?.listing_id }
                                }}
                            >
                                <div className="flex-grow-1 featured-item">
                                    <img src={collage_items[0] && collage_items[0].professional_profileimage && collage_items[0].professional_profileimage.length > 0 && collage_items[0].professional_profileimage[0] && collage_items[0].professional_profileimage[0] != "Not Available" ? collage_items[0]?.professional_profileimage[0].location : `https://userdatawikireviews.s3.amazonaws.com/images/${this.state.categoryBlueImage}`}
                                        className="feature-project-image _full"
                                        alt={`${collage_items[0] && collage_items[0]?.professional_profileimage && collage_items[0]?.professional_profileimage.length > 0 && collage_items[0]?.professional_profileimage[0] && collage_items[0].professional_profileimage[0].caption ? collage_items[0].professional_profileimage[0].caption : 'no-image'}`}
                                    />
                                   
                                    <div className="featured-details hoverable">
                                        <Link
                                            to={{
                                                pathname: `/${collage_items[0]?.content_type_name}/${collage_items[0]?.listing_id}`,
                                                state: { id: collage_items[0]?.listing_id }
                                            }}
                                            className="text-reset mb-2 d-block text-decoration-none"
                                        >
                                            <h2 className="fs-25 text-uppercase">{collage_items[0]?.name}</h2>
                                        </Link>
                                        {/* <div className="featured-item-credit">
                                        by <span>{collage_items[0]?.latest_review?.created_by}</span>
                                    </div> */}
                                        <div className="mt-2">
                                            <img
                                                title={collage_items[0]?.total_rating?.avg_rating[1]}
                                                src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[0]?.total_rating?.avg_rating[0]}`}
                                                alt={collage_items[0]?.total_rating?.avg_rating[1]}
                                            />
                                        </div>

                                        <div className="fs-13 hover-appear mt-2">
                                            <div>
                                                {collage_items[0]?.latest_review?.review
                                                    ?
                                                    <span>
                                                        <i className="icon-quote-left"></i>
                                                        <span className="comment-body-renderer _type-collage">
                                                            {collage_items[0]?.latest_review?.review.length < 80 ? ReactHtmlParser(collage_items[0]?.latest_review?.review) : <> {ReactHtmlParser(collage_items[0]?.latest_review?.review.substring(0, 80))}... </>}
                                                        </span>
                                                        <i className="icon-quote-right"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        Be the first one to review.
                                                </span>

                                                }
                                            </div>

                                            {/* <div className="text-right">
                                            <span className="d-inline-block mr-2">Omkar Katkarr</span>
                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="d-flex flex-column">
                            {collage_items && collage_items.slice(1, 3).map((item) =>
                                (
                                    <Link
                                        to={{
                                            pathname: `/${item?.content_type_name}/${item?.listing_id}`,
                                            state: { id: item?.listing_id }
                                        }}
                                    >
                                        <div className="flex-grow-1 featured-item">

                                            <img src={item && item.professional_profileimage && item.professional_profileimage.length > 0 && item.professional_profileimage[0] && item.professional_profileimage[0] != "Not Available" ? item?.professional_profileimage[0].location : `https://userdatawikireviews.s3.amazonaws.com/images/${this.state.categoryNeturalImage}`}
                                                className="feature-project-image _half"
                                                alt={`${item && item?.professional_profileimage && item?.professional_profileimage.length > 0 && item?.professional_profileimage[0] && item.professional_profileimage[0].caption ? item.professional_profileimage[0].caption : 'no-image'}`}
                                            />
                                            <div className="featured-details hoverable">
                                                <Link
                                                    to={{
                                                        pathname: `/${item?.content_type_name}/${item?.listing_id}`,
                                                        state: { id: item?.listing_id }
                                                    }}
                                                    className="text-reset mb-2 d-block text-decoration-none"
                                                >

                                                    <h2 className="fs-25 text-uppercase">{item?.name}</h2>

                                                </Link>
                                                {/* <div className="featured-item-credit">
                                        by <span>{item?.latest_review?.created_by}</span>
                                    </div> */}
                                                <div className="mt-2">
                                                    <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                </div>

                                                <div className="fs-13 hover-appear mt-2">
                                                    <div>
                                                        {item?.latest_review?.review
                                                            ?
                                                            <span>
                                                                <i className="icon-quote-left"></i>
                                                                <span className="comment-body-renderer _type-collage">
                                                                    {item?.latest_review?.review.length < 80 ? ReactHtmlParser(item?.latest_review?.review) : <> {ReactHtmlParser(item?.latest_review?.review.substring(0, 80))}... </>}
                                                                </span>
                                                                <i className="icon-quote-right"></i>
                                                            </span>
                                                            :
                                                            <span>
                                                                Be the first one to review.
                                                </span>

                                                        }
                                                    </div>

                                                    {/* <div className="text-right">
                                            <span className="d-inline-block mr-2">Omkar Katkarr</span>
                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                        </div> */}
                                                </div>
                                            </div>
                                        </div>

                                    </Link>
                                ))}
                        </Col>
                        <Col className="d-flex flex-column">
                            <Link
                                to={{
                                    pathname: `/${collage_items[3]?.content_type_name}/${collage_items[3]?.listing_id}`,
                                    state: { id: collage_items[3]?.listing_id }
                                }}
                            >
                                <div className="flex-grow-1 featured-item">
                                    <img src={collage_items[3] && collage_items[3].professional_profileimage && collage_items[3].professional_profileimage.length > 0 && collage_items[3].professional_profileimage[0] && collage_items[3].professional_profileimage[0] != "Not Available" ? collage_items[3]?.professional_profileimage[0].location : `https://userdatawikireviews.s3.amazonaws.com/images/${this.state.categoryBlueImage}`}
                                        className="feature-project-image _full"
                                        alt={`${collage_items[3] && collage_items[3]?.professional_profileimage && collage_items[3]?.professional_profileimage.length > 0 && collage_items[3]?.professional_profileimage[0] && collage_items[3].professional_profileimage[0].caption ? collage_items[3].professional_profileimage[0].caption : 'no-image'}`}
                                    />
                                    <div className="featured-details hoverable">
                                        <Link
                                            to={{
                                                pathname: `/${collage_items[3]?.content_type_name}/${collage_items[3]?.listing_id}`,
                                                state: { id: collage_items[3]?.listing_id }
                                            }}
                                            className="text-reset mb-2 d-block text-decoration-none"
                                        >
                                            <h2 className="fs-25 text-uppercase">{collage_items[3]?.name}</h2>
                                        </Link>
                                        {/* <div className="featured-item-credit">
                                        by <span>{collage_items[3]?.latest_review?.created_by}</span>
                                    </div> */}
                                        <div className="mt-2">
                                            <img title={collage_items[3]?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${collage_items[3]?.total_rating?.avg_rating[0]}`} alt={collage_items[3]?.total_rating?.avg_rating[1]} />
                                        </div>

                                        <div className="fs-13 hover-appear mt-2">
                                            <div>
                                                {collage_items[3]?.latest_review?.review
                                                    ?
                                                    <span>
                                                        <i className="icon-quote-left"></i>
                                                        <span className="comment-body-renderer _type-collage">
                                                            {collage_items[3]?.latest_review?.review.length < 80 ? ReactHtmlParser(collage_items[3]?.latest_review?.review) : <> {ReactHtmlParser(collage_items[3]?.latest_review?.review.substring(0, 80))}... </>}
                                                        </span>
                                                        <i className="icon-quote-right"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        Be the first one to review.
                                                </span>

                                                }
                                            </div>

                                            {/* <div className="text-right">
                                            <span className="d-inline-block mr-2">Omkar Katkarr</span>
                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="d-flex flex-column">
                            {collage_items && collage_items.slice(4, 6).map((item) =>
                                (
                                    <Link
                                        to={{
                                            pathname: `/${item?.content_type_name}/${item?.listing_id}`,
                                            state: { id: item?.listing_id }
                                        }}
                                    >
                                        <div className="flex-grow-1 featured-item">
                                            <img src={item && item.professional_profileimage && item.professional_profileimage.length > 0 && item.professional_profileimage[0] && item.professional_profileimage[0] != "Not Available" ? item?.professional_profileimage[0].location : `https://userdatawikireviews.s3.amazonaws.com/images/${this.state.categoryNeturalImage}`}
                                                className="feature-project-image _half"
                                                alt={`${item && item?.professional_profileimage && item?.professional_profileimage.length > 0 && item?.professional_profileimage[0] && item.professional_profileimage[0].caption ? item.professional_profileimage[0].caption : 'no-image'}`}
                                                onError={(error) =>
                                                    (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/${this.state.categoryNeturalImage}`)
                                                }
                                            />
                                            <div className="featured-details hoverable">
                                                <Link
                                                    to={{
                                                        pathname: `/${item?.content_type_name}/${item?.listing_id}`,
                                                        state: { id: item?.listing_id }
                                                    }}
                                                    className="text-reset mb-2 d-block text-decoration-none"
                                                >
                                                    <h2 className="fs-25 text-uppercase">{item?.name}</h2>
                                                </Link>
                                                {/* <div className="featured-item-credit">
                                        by <span>{item?.latest_review?.created_by}</span>
                                    </div> */}
                                                <div className="mt-2">
                                                    <img title={item?.total_rating?.avg_rating[1]} src={`https://userdatawikireviews.s3.amazonaws.com/images/star/white/${item?.total_rating?.avg_rating[0]}`} alt={item?.total_rating?.avg_rating[1]} />
                                                </div>

                                                <div className="fs-13 hover-appear mt-2">
                                                    <div>
                                                        {item?.latest_review?.review
                                                            ?
                                                            <span>
                                                                <i className="icon-quote-left"></i>
                                                                <span className="comment-body-renderer _type-collage">
                                                                    {item?.latest_review?.review.length < 80 ? ReactHtmlParser(item?.latest_review?.review) : <> {ReactHtmlParser(item?.latest_review?.review.substring(0, 80))}... </>}
                                                                </span>
                                                                <i className="icon-quote-right"></i>
                                                            </span>
                                                            :
                                                            <span>
                                                                Be the first one to review.
                                                </span>

                                                        }
                                                    </div>

                                                    {/* <div className="text-right">
                                            <span className="d-inline-block mr-2">Omkar Katkarr</span>
                                            <img title="4.9" src="https://userdatawikireviews.s3.amazonaws.com/images/star/white/5rating.png" alt="4.9 Rating" />
                                        </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                ))}
                        </Col>
                    </Row>
                </header>

                <section className="position-relative" style={{ zIndex: 3, marginTop: '-40px' }}>
                    <Container>
                        <div>
                            <Row className="align-items-end">
                                <Col xs="auto" style={{ minWidth: '170px' }}>
                                    <span
                                        onClick={() => {
                                            let locationItems = window.location.pathname.split('/');
                                            if (locationItems && Array.isArray(locationItems) && locationItems?.length && locationItems[1]) {
                                                let item_name = locationItems[1];
                                                if (item_name && this.props?.history) {
                                                    if (item_name == "professionals") {
                                                        this.props.history.push(`/${item_name}`);
                                                    }
                                                }
                                            }
                                        }}
                                        className="shade-link d-inline-block _black _padded cursor-pointer">
                                        {'Back to Category'}
                                    </span>
                                </Col>
                                <Col>
                                    <div className="text-center text-white black-shade mx-auto" style={{ maxWidth: '500px' }}>
                                        <h1 className="p-0 m-0 fs-34" style={{ 'text-transform': 'capitalize' }}>{category}</h1>
                                    </div>
                                </Col>
                                <Col xs="auto" style={{ minWidth: '170px' }}>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </section>

                {/* Main Content Starts */}
                <section className="py-4 fs-14">
                    <Container>
                        <Row>
                            <Col md={2}>
                                <div className="mb-3">
                                    <div className="font-weight-bold fs-16 mb-2 text-dark">Star Rating</div>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(5)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="mr-1"
                                                    src={require("../../assets/images/icons/star/blue/star_6.png")}
                                                    // src="https://userdatawikireviews.s3.amazonaws.com/images/star_6.png"
                                                    alt="5 Rating" />
                                                <span>only</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(4)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1"
                                                    src={require("../../assets/images/icons/star/blue/star_5.png")}
                                                    alt="4 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(3)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src={require("../../assets/images/icons/star/blue/star_4.png")} alt="3 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(2)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src={require("../../assets/images/icons/star/blue/star_3.png")} alt="2 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(1)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src={require("../../assets/images/icons/star/blue/star_2.png")} alt="1 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup className="mb-1" check>
                                        <Label className="text-dark ff-alt font-weight-normal" check>
                                            <Input type="radio"className='star-filter-input-checkbox' name="filterByStarRating" onClick={() => this.handleStarFilter(0)} />{' '}
                                            <div className="d-flex align-items-center">
                                                <img className="mr-1" src={require("../../assets/images/icons/star/blue/star_1.png")} alt="0 Rating" />
                                                <span>& up</span>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <hr className="bg-tertiary" />
                                </div>

                                <div>
                                    <div className="bg-white text-center">
                                        <a rel="noopener noreferrer" href="http://wikireviews.com/blog" target="_blank" className="text-reset">
                                            <img className="img-fluid blog-img" src={"https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_05.png"} alt="" />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                {/* Listing in Professional Category */}

                                {/* Repeat this */}
                                {this.renderItems()}


                                {/* Write Review */}
                            </Col>
                            <Col md={4}>
                                {category ?
                                    <UserFeedsPanel category={category} />
                                    :
                                    ''
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Share By Email Modal Popup */}
                <ModalComponent
                    size="sm"
                    isScrollable={true}
                    isOpen={this.state.shareByEmailModalToggle}
                    toggleHandler={() =>
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })}
                    header={"Share by Email"}
                    headerClass="text-uppercase px-0"
                    body={<ShareByEmail handleOnSubmitShareByEmail={(value) => this.handleOnSubmitShareByEmail(value)} modalToggler={() => {
                        this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle,
                        })
                    }} />}
                /> 
            </div>
        )
    }
}

const mapState = (state) => ({
    this_user: state.user.my_profile,
    collage_items: state.professionals.category_collage_items,
    data_list: state.professionals.professionals_data_list,
    isLoading: state.professionals.isLoading,
    pins_data: state.item_detail.pins_data,
    professionals_by_rating: state.products_listing.professionals_list,
    current_location: state.home.current_location,
});

const mapProps = (dispatch) => ({
    fetch_professional_category_collage_items: (data) => dispatch(fetch_professional_category_collage_items(data)),
    fetch_professionals_data_list: (data) => dispatch(fetch_professionals_data_list(data)),
    // fetch_filtered_professionals: (data) => dispatch(fetch_filtered_professionals(data)),
    fetch_rating_filtered_professionals: (data) => dispatch(fetch_rating_filtered_professionals(data)),
    share_by_email: (url, data) => dispatch(share_by_email(url, data)),
    add_to_list: (url, data, message, nextUrl,newContent) => dispatch(add_to_list(url, data, message, nextUrl,newContent)),
    remove_from_list: (url, message, nextUrl,newContent) => dispatch(remove_from_list(url, message, nextUrl,newContent)),
    get_professional_pins: (id) => dispatch(get_professional_pins(id)),
    clear_professional: () => dispatch(clear_professional()),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
});

export default connect(mapState, mapProps)(ProfessionalsCategory);

