import React, { Component } from 'react';
import { callApi } from '../../utils/apiCaller';
import CollapseBasic from './collapse';
import moment from 'moment';
export default class RatingsByEmployee extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listingItems: {}
        }
    }

    componentDidMount() {
        this.fetchItemsByEmployee();
    }

    fetchItemsByEmployee = async () => {
        try {
            let url = `/api/professionalworkhistory/?&taxonomy__category=work`;
            let response = await callApi(url, 'GET');
            if (response && response?.code !== 500) {
                this.setState({ listingItems: response?.results })
            }
        } catch (err) {
            console.log({ err })
        }
    }

    render() {
        let { listingItems } = this.state;
        return (
            <div className="bg-white-shaded-md text-secondary mb-3">
                <CollapseBasic title="Ratings by employer" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={false}>
                    <ul className="list-unstyled text-secondary-dark">
                        {listingItems && Array.isArray(listingItems) && listingItems?.length ?
                            listingItems.map((item, index) => {
                                return index <= 5 && (
                                    <li className="py-2 border-bottom border-secondary mb-1">
                                        {item?.ratings_by_employer?.avg_rating[0] && (
                                            <img
                                                src={`https://userdatawikireviews.s3.amazonaws.com/images/star/gold/${item?.ratings_by_employer?.avg_rating[0]}`}
                                                alt={item?.ratings_by_employer?.avg_rating[1]}
                                            />
                                        )}
                                        <div className="mt-2">
                                            <div className="mb-2">
                                                <span className="mr-1">{item?.title}</span>{` at `}
                                                <strong className="ml-1">{`${item?.company}`}</strong>
                                            </div>
                                            <span>
                                                {item?.from_date_label == null ? '' : moment(item?.from_date_label).format('MMMM yyyy')}
                                                {` - `}
                                                {item.is_present ? item.is_present_label : item?.to_date_label !== null ? moment(item?.to_date_label).format('MMMM yyyy') : ''}
                                            </span>
                                        </div>
                                    </li>
                                )
                            })
                            :
                            null
                        }
                    </ul>
                </CollapseBasic>
            </div >
        )
    }
}
