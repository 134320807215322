/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, } from 'reactstrap';
import CollapseBasic from '../../../component/atoms/collapse';
import { Chart } from "react-google-charts";
import GenderTable from '../../molecules/shared/DetailedStarRatings/genderTable';

const barColor = "#d1bb78"
const axisStyle = {
    fontName: 'Arial',
    fontSize: '10',
    color:'#69510c'            
}

const data = [
    [
        "Element",
        "Density",
        { role: "style" },
        {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
        },
    ],
    ["5 Stars", 5, `${barColor}`, null],
    ["4 Stars", 6, `color: ${barColor}; opacity: 0.7`, null],
    ["3 Stars", 2, `color: ${barColor}; opacity: 0.6`, null],
    ["2 Stars", 1, `color: ${barColor}; opacity: 0.5`, null],
    ["1 Stars", 5, `color: ${barColor}; opacity: 0.4`, null],
];

const options1 = {  
    height: 150,
    chartArea: { top:0,width: '70%',height:'90%',backgroundColor: {
        fill: "transparent"
      } },
    bar: { groupWidth: "25%" },
    legend: { position: "none" },
    hAxis: {
        viewWindow: {
            min: 0
        }, 
        gridlines: { color: 'transparent', count: 4 },
        format: 'decimal',
        baselineColor: '#d1bb78',
        textStyle: axisStyle
    },
    vAxis: {
        viewWindow: {
            min: 0
        },
        ticks: [{ v: 0, f: '0 Star' }, { v: 1, f: '1 Star' }, { v: 2, f: '2 Stars' }, { v: 3, f: '3 Stars' }, { v: 4, f: '4 Stars' }, { v: 5, f: '5 Stars' }],
        gridlines: { color: 'transparent'},
        textStyle: axisStyle    
    },
    backgroundColor: 'transparent',
    baselineColor: '#d1bb78'
};

const chartEvents = [
    {
        eventName: "select",
        callback({ chartWrapper }) {
            // removed console from here.
        }
    }
];

const options = {
    axesDefaults:
    {
        useSeriesColor: true,
        min: 1,
        tickInterval: 1,
        tickOptions: {
            formatString: '%d'
        }
    },
    'width': '100%',
    'height': 150   ,
    chartArea: { top:10,width: '70%',height:'80%',backgroundColor: {
        fill: "transparent"
      }, },
    hAxis: {  
        gridlines: { color: 'transparent' },       
        textStyle: axisStyle
    },
    vAxis: {        
        titleTextStyle: { color: '#d1bb78' },
        ticks: [{ v: 0, f: '0 Star' }, { v: 1, f: '1 Star' }, { v: 2, f: '2 Stars' }, { v: 3, f: '3 Stars' }, { v: 4, f: '4 Stars' }, { v: 5, f: '5 Stars' }],
        gridlines: { color: 'transparent' },
        baselineColor: '#d1bb78',
        textStyle: axisStyle
    },
    legend: { position: "none" },
    isStacked: true,
    annotation: {
        fontsize: 2
    },
    colors: ['#d1bb78'],
    backgroundColor: 'transparent',
    
};

const genderData = [
    {
        name: 'Total',
        male: 4,
        female: 3.5,
        prefer: 3
    },
    {
        name: '50+',
        male: 0.5,
        female: 1.5,
        prefer: 2
    },
    {
        name: '36-49',
        male: 3,
        female: 3.5,
        prefer: 4.5
    },
    {
        name: '26-35',
        male: 0,
        female: 2.5,
        prefer: 4
    },
    {
        name: '18-25',
        male: 1,
        female: 3.5,
        prefer: 0.5
    },
    {
        name: '13-17',
        male: 4,
        female: 3.5,
        prefer: 3
    }

]

export default class DetailStarRating extends Component {
    constructor(props) {
        super(props)

        this.state = {
            setGraphValue: 'Overall Rating',
            dropdownSortBy: false,
        }
    }

    handleChangeText = (item) => {
        this.setState({ setGraphValue: item });
    }

    toggleCategory(item) {
        this.setState(prevState => ({
            [item]: !prevState[item]
        }));
    }

    onMouseEnterCategory(item) {
        this.setState({ [item]: true });
    }

    onMouseLeaveCategory(item) {
        this.setState({ [item]: false });
    }

    render() {
        const { total_rating, setRatingFrom, handleChangeRatings, chart_star_ratings } = this.props;
        let { setGraphValue } = this.state;
        let dataItems = [];        
        let conditionalRating = setRatingFrom === "Overall Rating" ? "avg_rating" : setRatingFrom;
        let dummy_chart_star_ratings = {
            "Atmosphere": [
                ["month", "Reviews"],
                ["Aug 22", 2.5],
                ["Sep 22", 3],
                ["Oct 22", 4],  
              ],
              "Bathroom Cleanliness": [
                ["month", "Reviews"],
                ["Aug 22", 1.5],
                ["Sep 22", 2],
                ["Oct 22", 3.5],  
              ],
              "Food Quality":[
                ["month", "Reviews"],
                ["Aug 22", 2.5],
                ["Sep 22", 3],
                ["Oct 22", 4],  
              ],
              "Overall Rating":[
                ["month", "Reviews"],
                ["Aug 22", 0.5],
                ["Sep 22", 4.5],
                ["Oct 22", 1],  
              ],
              "Promptness in Serving Meal":[
                ["month", "Reviews"],
                ["Aug 22", 4.5],
                ["Sep 22", 3],
                ["Oct 22", 3],  
              ],
              "Restaurant Cleanliness":[
                ["month", "Reviews"],
                ["Aug 22", 2.5],
                ["Sep 22", 1],
                ["Oct 22", 4],  
              ]

        }
        if (chart_star_ratings && chart_star_ratings[setRatingFrom]) {
            // dataItems = chart_star_ratings[setRatingFrom] // uncomment when api gets data
            dataItems = dummy_chart_star_ratings[setGraphValue]
        }
        
        return (
            <div className="bg-white-shaded-md text-secondary mb-3 ">
                <CollapseBasic title="Detailed Star Rating" containerClass="bg-transparent type-secondary" bodyClass="text-secondary" isOpen={true}>
                    <div className="text-right wk-business-sort-by">
                        <UncontrolledDropdown onMouseOver={() => this.onMouseEnterCategory('dropdownSortBy')}
                            onMouseLeave={() => this.onMouseLeaveCategory('dropdownSortBy')}
                            isOpen={this.state.dropdownSortBy}
                            toggle={() => this.toggleCategory('dropdownSortBy')}>
                            <DropdownToggle className="text-secondary-dark bg-transparent font-weight-bold" color="transparent" size="sm" caret>
                                <span className="pr-2">
                                    {/* Sort By */}
                                    {setRatingFrom}
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="type-secondary _alt _bold wk-sort-dropdown-new">
                                <DropdownItem className="fs-14" onClick={() => { handleChangeRatings('Overall Rating') }}>
                                    {'Overall Rating'}
                                </DropdownItem>
                                {total_rating && Object.keys(total_rating).length ?
                                    Object.keys(total_rating).filter(dt => dt !== 'avg_rating' && dt !== 'total_review').map((item, index) => (
                                        <DropdownItem
                                            className="fs-14"
                                            onClick={() => {
                                                this.handleChangeText(item);
                                                handleChangeRatings(item);
                                            }} key={index}>
                                            {item}
                                        </DropdownItem>
                                    ))
                                    : null}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>

                    <div>
                        <div className="font-weight-bold mb-2">
                            <span className="text-secondary-dark">{setRatingFrom == 'Overall Rating ' ? ' ' : setRatingFrom}</span>
                            {setRatingFrom == 'Overall Rating' ? '' : ' Average Rating'}
                        </div>
                        {total_rating?.avg_rating && total_rating?.[conditionalRating] && total_rating?.[conditionalRating][0] ? (
                            <img
                                src={require(`../../../assets/images/icons/star/gold/${total_rating?.[conditionalRating][0]}`)}
                                alt="" />
                        ) : null}
                        <hr className="bg-light mt-3" />  
                    </div>
                    <div>
                        <div className="font-weight-bold mb-2">
                            <span className="text-secondary-dark">{setRatingFrom} </span>
                            {setRatingFrom == 'Overall Rating' ? ' Distribution' : ' Rating Distribution'}
                        </div>                       
                        {/* Chart Area */}
                        <Chart
                            chartType="BarChart"
                            width="100%"
                            // height="400px"
                            data={data}
                            options={options1}
                            loader={<div>Loading Chart</div>}
                        />
                        <hr className="bg-light mt-3" />  
                        <div className="font-weight-bold mb-2">
                            <span className="text-secondary-dark">{setRatingFrom} </span>
                            {'Trend'}
                        </div>                        
                        <Chart
                            chartType="LineChart"
                            data={dataItems}
                            options={options}
                            graphID="LineChart"
                            width="100%"
                            // height="400px"
                            chartEvents={chartEvents}
                            loader={<div>Loading Chart</div>}
                        />                                               
                    </div>                    
                    <GenderTable genderData={genderData}/>
                </CollapseBasic>
            </div>
        )
    }
}
