import { isNull } from 'lodash';
import React, { Component } from 'react';

import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { click_tour_start, set_tour_step, toggle_create_new_project, toggle_detail_star_rating } from '../../../../actions/utils';
import { preDefinedSiteTourSteps } from '../../../../utils/utilities';

const stepPage = {
    1: "/home",
    2: "/business/2238476/",
    3: "/business/2238476/",
    4: "/myprofile",
    5: "/movies/",
    6: "/projectslist"
}
class SiteTour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            steps: preDefinedSiteTourSteps,
            stepNo: this.props.stepData?.No ? this.props.stepData?.No : 0,
            tourStart: false,
            completeTour: false,
            stepIndex: 0,
            tourStartFrom: {},
            showJoyride: false,
        };
    }


    checkLoaderVisibility = () => {
        const loaderDiv = document.querySelector(".loader_div");
        if (isNull(loaderDiv)) {
            this.setState({ showJoyride: true });
        }
        else {
            this.setState({ showJoyride: false });
        }
    };

    componentWillReceiveProps(nextProps) {
        const { stepNo } = this.state;
        const { stepData } = nextProps;
        if (stepData) {
            if (stepData?.No) {
                if (stepPage[stepData?.No] != nextProps.history?.location?.pathname) {
                    let state = { id: 2238476 };
                    state.expandReview = stepData?.No == 2 ? true : false;
                    this.props.history.push(stepPage[stepData?.No], (stepData?.No == 2 || stepData?.No == 3) && state);
                }
                else {
                    if (stepNo != stepData?.No) {
                        try {
                            this.intervalCheck = setInterval(async () => {
                                this.checkLoaderVisibility();
                            }, 100);
                        } catch (e) {
                            console.log(e);
                        }
                        this.setState({ tourStart: true, stepNo: stepData?.No, stepIndex: 0, });

                    }
                }
            }
            if (stepData?.tour_start_from) {
                this.setState({ tourStartFrom: stepData?.tour_start_from });
            }
        }

    }

    handleEndTour = (crpEndTour = false) => {
        const { tourStartFrom, stepNo } = this.state;

        if (crpEndTour) {
            this.setState({ tourStart: false }, () => this.setState({ tourStart: true, stepIndex: 4 }));
        } else {
            this.setState({ tourStart: false, stepIndex: 0, stepNo: 0, tourStartFrom: {} }, () => {
                if (this.props.stepData?.begin || stepNo == 2) { this.props.toggleStarRating({ enable_rating: false, disable_create_review: true }); }

                this.props.set_tour_step({ begin: false, No: 0 });

                (stepNo == 6) && this.props.toggleCreateNewProject({ status: false });

                if (this.intervalCheck) {
                    clearInterval(this.intervalCheck);
                }

                if (this.props.history?.location?.pathname !== tourStartFrom?.pathname) {
                    this.props.history.push(tourStartFrom?.pathname, tourStartFrom?.state && tourStartFrom?.state);
                }
            });
        }


    }

    renderHomeStepLocationDesc = () => {
        return (
            <>
                <div className="form-group">
                    <input type="text" name="location" id="location" placeholder="New" className="form-control" />
                    <ul className="cities">
                        <li>
                            <span><img src={require("../../../../assets/images/website-tour/HomeTour/address-icon.png")} alt='' /><b>New York City </b><small>New York,verenigde Staten</small></span><p></p>
                        </li>
                        <li>
                            <span><img src={require("../../../../assets/images/website-tour/HomeTour/address-icon.png")} alt='' /><b>New Delhi</b> <small>Delhi,India</small></span><p></p>
                        </li>
                        <li>
                            <span><img src={require("../../../../assets/images/website-tour/HomeTour/address-icon.png")} alt='' /><b>New York City</b><small> New York,verenigde Staten</small></span><p></p>
                        </li>
                    </ul>

                </div>
            </>
        )
    }

    renderHomeStepCategoryDesc = () => {
        return (<>
            <ul className="catlist">
                <li>
                    <img src={require("../../../../assets/images/website-tour/HomeTour/attach.png")} alt='' />
                    <p>results in <b>all categories</b></p>
                </li>
                <li>
                    <img src={require("../../../../assets/images/website-tour/HomeTour/products-top-search.png")} alt='' />
                    <p>results in all <b>business</b></p>
                </li>
                <li>
                    <img src={require("../../../../assets/images/website-tour/HomeTour/products-top-search.png")} alt='' />
                    <p>results in all <b>products</b></p>
                </li>
            </ul>
        </>)
    }

    renderHomeStepSearchDesc = () => {

        return (<>
            <div className="form-group food-group">
                <div className="input-group">
                    <span className="input-group-addon br0 bgblue"><img src={require("../../../../assets/images/website-tour/HomeTour/restaurant.png")} alt='' />
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-caret-down"></i> */}
                    </span>
                    <input type="text" placeholder="Pizza" name="search" id="search" className="form-control" />
                    <i className="fa fa-search"></i>
                </div>
                <div className="rest-food">
                    <ul className="restfoodlist">
                        <li>
                            <div className="food-left">
                                <img src={require("../../../../assets/images/website-tour/HomeTour/dummy-rectangle.jpg")} alt='' />
                            </div>
                            <div className="food-right">
                                <h3>L'assos Pizza</h3>
                                <div className="food-review">
                                    <span><i className="fa fa-ban"></i>&nbsp;|&nbsp;<i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i></span>
                                    <p>Restaurants, Gluten-Free</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>)
    }
    renderMyProfileStepUsernameDesc = () => {

        return (<>
            <div className="input-box">
                <input type="text" />
                <i className='fa fa-pencil-alt' />
            </div>
        </>)
    }

    handleChangeStep = () => {
        const { stepNo, steps } = this.state;
        const begin = this.props.stepData?.begin ? this.props.stepData?.begin : false;
        (Object.keys(stepPage).length > stepNo && steps[stepNo]?.length) && begin
            ? this.setState({ tourStart: false }, () => { this.props.set_tour_step({ begin: begin, No: stepNo + 1, skipStep: true }); (stepNo == 2) && this.props.toggleStarRating({ enable_rating: false, disable_create_review: true }); })
            : this.handleEndTour(stepNo == 6 ? true : false);
    }



    //checkStepAndMoveNext()=>{ return (null)};
    handleJoyrideCallback = (CallBackProps) => {
        const { steps, stepNo } = this.state;
        let { status, type, step, index, action, lifecycle, size } = CallBackProps;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];
        if ([EVENTS.STEP_AFTER].includes(type)) {
            if (stepNo == 3 && index == 3 && !this.props.stepData?.begin) {
                this.handleEndTour();
            }
            else {
                (stepNo == 2 && index == 0) && this.props.toggleStarRating({ enable_rating: false, disable_create_review: false });
                (stepNo == 2 && index == 1) && this.props.toggleStarRating({ enable_rating: true, disable_create_review: false });
                this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
            }

        }
        if (finishedStatuses.includes(status)) {
            const { begin, skipStep } = this.props.stepData;
            this.setState({ tourStart: true });
            if (begin && status != "paused") {
                this.handleChangeStep();
            }
            else if (status == "paused") {
                this.setState({ tourStart: true, stepIndex: this.state.stepIndex ? this.state.stepIndex : 0, stepNo });
            }
            else {
                this.handleEndTour();
            }
        }
        else if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {

            if (stepNo == 2 && index == 1) {
                this.props.toggleStarRating({ enable_rating: false, disable_create_review: false }).then(() => {
                    this.setState({ tourStart: true, stepIndex: index - 1 });
                })
            }

            if (stepNo == 2 && index == 2) {
                this.props.toggleStarRating({ enable_rating: true, disable_create_review: false }).then(() => {
                    this.setState({ tourStart: true, stepIndex: index });
                })
            }
            if (stepNo == 6 && index == 2) {
                this.props.toggleCreateNewProject({ status: true }).then(() => {
                    this.setState({ tourStart: true, stepIndex: index });
                })
            }

            else if (index <= size) {
                this.setState({ tourStart: true, stepIndex: index + 1 });
            }
            else {
                this.handleEndTour();
            }
        }
    };

    Tooltip = ({
        continuous,
        index,
        isLastStep,
        step,
        backProps,
        closeProps,
        primaryProps,
        skipProps,
        tooltipProps, }) => {
        const { stepNo } = this.state;
        if (step?.target === '.step-1_home') {
            return (
                <div className={`cs-toolpop ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                    <h4>{`Hi ${this.props.my_profile?.user?.first_name}!`} <br /> Welcome to WikiReviews!</h4>
                    <p className='welcomeContent'>Get familiar with the interface.<br /> Take a <b>short tour</b> with us!</p>
                    <div className='mainAction'>
                        <Button {...primaryProps}
                        title=""
                         color="primary" size="lg">
                            {'OK'}
                        </Button>
                    </div>
                    <span className='actionCenter'>
                        <Button className='actionTextLink' color="link" onClick={() => this.handleChangeStep()}>
                            {'Skip this part and learn how to create a review'}
                        </Button>
                        <Button className='actionTextLink' color="link" onClick={() => this.handleEndTour()}>
                            {`Or I'll explore on my own`}
                        </Button>
                    </span>
                </div>)
        }
        if (step?.target === '.step-1_crp') {
            return (
                <div className={`cs-toolpop crp-start ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                    <div className='d-flex px-2' >
                        <img src={require('../../../../assets/images/website-tour/HomeTour/crp-profile.png')} alt='' className='crp-profile-image mr-4' />
                        <h4>Community Review Projects</h4>
                    </div>
                    <p className='welcomeContent'>We call this CRP for short. This is where you can upload your own personal projects and get feedback on it from the community. From logos for your new business to before and after pictures, you can upload any project and see what the community really thinks.</p>
                    <div className='mainAction'>
                        <Button {...primaryProps}
                        title=""
                        color="primary" size="lg">
                            {'OK'}
                        </Button>
                    </div>
                    <span className='actionCenter'>
                        <Button className='actionTextLink' color="link" onClick={() => this.handleEndTour()}>
                            {`Skip or take tour later`}
                        </Button>
                    </span>
                </div>)
        }
        else if (step?.target === '.last_step_crp') {
            return (
                <div className={`cs-toolpop tourEndThanks ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                    <img src={require('../../../../assets/images/website-tour/CRPTour/endTourIcon.png')} alt='' className='baloons-image' />
                    <h4>
                        Thank you for taking our site tour!
                    </h4>
                    <div className='d-flex align-items-center time-image-outer mb-3' >
                        <img src={require('../../../../assets/images/website-tour/CRPTour/endTour_sec_icon.png')} alt='' />
                        <p className='welcomeContent'>
                            You can always come back to the tour by clicking on the "Site Tour" icon on the top right corner.
                        </p>
                    </div>
                    <div className='mainAction'>
                        <Button color="primary"
                        title=""
                        size="lg" onClick={() => {
                            this.handleEndTour()
                        }}>
                            {'OK'}
                        </Button>
                    </div>
                </div>)
        }
        else if (step?.target === '.step-1') {
            return (
                <div className={`cs-toolpop ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                    <div className='startBannerHeader'>
                        {step?.icon && <div className="startBannerIcon">
                            <img src={step.icon} alt="icon" />
                        </div>}
                        <h4 dangerouslySetInnerHTML={{ __html: step.content }} />
                        {
                            step?.subContent && <p dangerouslySetInnerHTML={{ __html: step?.subContent }} />
                        }
                    </div>

                    <div className='mainAction'>
                        <Button {...primaryProps} 
                        title=""
                        color="primary" size="lg">
                            {'Ok'}
                        </Button>
                    </div>
                    <span className='actionCenter'>
                        <Button className='actionTextLink' color="link" onClick={() => this.handleChangeStep()}>
                            {step?.nextStepContext}
                        </Button>
                        <Button className='actionTextLink' color="link" onClick={() => this.handleEndTour()}>
                            {'Or I’ll explore on my own'}
                        </Button>
                    </span>
                </div>)
        }
        else if (step?.secImg || step?.sec_content || step?.secDesc) {
            return (<div className={`cs-toolpop ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                <div>
                    <div className="stat-bubble with-line" data-type="overall">
                        {` `}{` `}
                    </div>
                    <div className="stat-bubble with-line" data-type="reviews">
                        {` `}{` `}
                    </div>
                    <div className="stat-bubble with-line" data-type="branches">
                        {` `}{` `}
                    </div>
                </div>
                <div className="container sec-container">
                    <div className="intro-block py-0" >
                        <div className="infoContainer pb-0">
                            {step.icon && <div className="icon">
                                <img src={step.icon} alt="icon" />
                            </div>}
                            <div className="intro-block pb-0" data-type="stat-intro">
                                <span dangerouslySetInnerHTML={{ __html: step.content }} />
                                {step?.descImg && <img src={step?.descImg} alt="" />}
                                {step?.primary_desc && this[step?.primary_desc]()}
                            </div>
                        </div>
                        <span className="secImg pt-0">
                            {step?.secImg && <img src={step.secImg} alt="" className="w-100 border" />}
                            {step?.sec_content && <span dangerouslySetInnerHTML={{ __html: step?.sec_content }} />}
                            {step?.secDesc && this[step?.secDesc]()}
                        </span>
                        <span className="actionContainer justify-content-center mb-3">
                            {continuous && (
                                <Button
                                 {...primaryProps}
                                 title=""
                                 color="primary">
                                    {step?.nextActionText ? step?.nextActionText : 'Next'}
                                </Button>
                            )}
                            {
                                <span className='ml-3' onClick={() => this.handleChangeStep()}>
                                    {step.skipText ? step.skipText : 'Skip this step'}
                                </span>
                            }
                            {
                                <span onClick={() => this.handleEndTour(stepNo == 6 ? true : false)}>
                                    {'End tour'}
                                </span>
                            }
                        </span>
                    </div>
                </div>
            </div>)
        }
        else {
            return (<div className={`cs-toolpop ${step?.customStyle && step?.customStyle}`} {...tooltipProps}>
                <div>
                    <div className="stat-bubble with-line" data-type="overall">
                        {` `}{` `}
                    </div>
                    <div className="stat-bubble with-line" data-type="reviews">
                        {` `}{` `}
                    </div>
                    <div className="stat-bubble with-line" data-type="branches">
                        {` `}{` `}
                    </div>
                </div>
                <div className={`container ${step?.reverse ? 'position-reverse' : 'position'}`}>
                    {step.icon && <div className={`${step?.iconBottom ? `iconBottom` : step?.reverse ? `iconReverse` : `icon`}`}>
                        <img src={step.icon} alt="icon" />
                    </div>}
                    <div className="intro-block" data-type="stat-intro">
                        <span dangerouslySetInnerHTML={{ __html: step.content }} />
                        {step?.descImg && <img src={step?.descImg} alt="" className='w-100 h-auto' 
                        />}
                        {step?.primary_desc && this[step?.primary_desc]()}
                        <span className="actionContainer">
                            {continuous && (
                                <Button {...primaryProps}
                                title="" 
                                color="primary" className="bt-btn ">
                                    {step?.nextActionText ? step?.nextActionText : 'Next'}
                                </Button>
                            )}
                            {
                                <span onClick={() => this.handleChangeStep()}>
                                    {step.skipText ? step.skipText : 'Skip this step'}
                                </span>
                            }
                            {
                                <span onClick={() => this.handleEndTour(stepNo == 6 ? true : false)}>
                                    {'End tour'}
                                </span>
                            }
                        </span>
                    </div>
                </div>
            </div>)
        }
    }

    render() {
        return (<>

            {this.state.showJoyride && (<ReactJoyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps[this.state.stepNo - 1]}
                stepIndex={this.state.stepIndex}
                run={this.state.tourStart}
                scrollOffset={250}
                continuous={true}
                tooltipComponent={this.Tooltip}
                showProgress
                hideBackButton={false}
                disableOverlayClose={true}
                locale={{ close: 'Close' }}
                styles={{
                    options: {
                        arrowColor: "#fff",
                        backgroundColor: "#fff",
                        overlayColor: "rgba(0, 0, 0, 0.6)",
                        primaryColor: "mediumaquamarine",
                        textColor: "#555",
                        zIndex: 100000000,
                    },
                }}
            />)}
        </>

        )

    }
}
const mapState = (state) => ({
    stepData: state.utils.step,
    stepsIndex: state.utils.index,
    my_profile: state.user.my_profile,
});

const mapProps = (dispatch) => ({
    clickTourStart: (data) => dispatch(click_tour_start(data)),
    set_tour_step: (step) => dispatch(set_tour_step(step)),
    toggleStarRating: (data) => dispatch(toggle_detail_star_rating(data)),
    toggleCreateNewProject: (data) => dispatch(toggle_create_new_project(data)),
})

export default withRouter(connect(mapState, mapProps)(SiteTour));