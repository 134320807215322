const initialState = {
    products_collage_items: null,
    products_categories: null,
    products_sub_categories: null,
    products_sub_categories1: null,
    products_sub_categories2: null,
    products_reviews: null,
    professionals_list: null,
    products_category_collage_list: null,
    products_items: null,
    fetch_star_filter_data: null,
    isLoading: true
};

const products_listing = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;

        case "FETCH_PRODUCTS_COLLAGE_ITEMS":
            state.products_collage_items = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PRODUCTS_CATEGORIES":
            state.products_categories = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PRODUCTS_SUB_CATEGORIES":
            state.products_sub_categories = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PRODUCTS_REVIEWS":
            state.products_reviews = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PROFESSIONALS_LIST":
            state.professionals_list = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PRODUCTS_CATEGORY_COLLAGE_LIST":
            state.products_category_collage_list = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_PRODUCTS_LIST":
            state.products_items = action.payload;
            state.isLoading = false
            return { ...state };

        case "FETCH_FILTERED_DATA":
            state.fetch_star_filter_data = action.payload;
            state.isLoading = false
            return { ...state };
        case "IS_LOADING":
            state.isLoading = action.payload
            return { ...state };
        case "IS_CATEGORIES_LOADING":
            state.isCategoryLoading = action.payload
            return { ...state };
        default:
            return state;
    }
}

export default products_listing;