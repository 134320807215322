import { callApi, } from "../utils/apiCaller";
import { toast } from "react-toastify";

export const ADD_DISPUTE_STATUS = "ADD_DISPUTE_STATUS";
export const GET_DISPUTE_DATA = "GET_DISPUTE_DATA";
export const DISPUTE_MODAL_STATUS = "DISPUTE_MODAL_STATUS";
export const ALL_DISPUTED_REVIEWS = "ALL_DISPUTED_REVIEWS";
export const GET_JURY_DUTY_DATA = "GET_JURY_DUTY_DATA";
export const GET_JURY_DATA = "GET_JURY_DATA";
export const REVIEW_DATA = "REVIEW_DATA";
export const GET_DISPUTE_DRAFT = "GET_DISPUTE_DRAFT";
export const DELETE_DISPUTE_DRAFT = "DELETE_DISPUTE_DRAFT";
export const DELETE_ADMINISTRATIVE_DISPUTE = "DELETE_ADMINISTRATIVE_DISPUTE";
export const GET_JURY_DUTY_HISTORY = "GET_JURY_DUTY_HISTORY";
export const GET_JURY_RESULT = "GET_JURY_RESULT";
export const JURY_MODAL_STATUS = "JURY_MODAL_STATUS";
export const ADMINISTRATIVE_REVIEWS ="ADMINISTRATIVE_REVIEWS";
export const ADMINISTRATIVE_DISPUTED_REVIEW = "ADMINISTRATIVE_DISPUTED_REVIEW";
export const PROFILE_DISPUTE_COUNT = "PROFILE_DISPUTE_COUNT";

export const add_dispute_action = (data) => {
    return {
      type: ADD_DISPUTE_STATUS,
      payload: data,
    };
};
  
export const add_dispute_review = (data, file='home') => {  

    let formData = new FormData();    
    formData.append("reason", data.reason);
    formData.append("comment", data.comment);
    formData.append("review_id", data.review_id);
    formData.append("embedLinks", data.embedLinks);
    formData.append("is_draft", data.is_draft)
    formData.append("was_draft", data.was_draft)
    formData.append("review_owner", data.review_owner)
    formData.append("is_administrative_review", data.is_administrative_review)
    formData.append("is_community_flagged", data.is_community_flagged)
    if( data.is_administrative_review){
      formData.append("admin_reason", data.admin_reason)
    }
    for (let i = 0; i < data.dispute_file.length; i++) {
    formData.append("dispute_file", data.dispute_file[i]);
    }
    
    return async (dispatch) => {
      callApi(`/api/disputes/`, "POST", formData, true).then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
          if (response.Message) {
            toast(response.Message, {
              autoClose: 3000,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
          }
          else {              
                toast(`Your Review Dispute has been submitted`, {
                  autoClose: 3000,
                  className: "black-background",
                  bodyClassName: "red-hunt",
                  progressClassName: "cc",
                });
            }                     
          }
          return dispatch(add_dispute_action(response)); 
      });
    };    
};

export const get_dispute_action = (data) => {
    return {
        type: GET_DISPUTE_DATA,
        payload: data,
    };
};

export const get_dispute_discussion = (review_id) => {
    return (dispatch) => {
        callApi(`/api/disputes/?review_id=${review_id}`, "GET").then((response) => {
        if (response && response.code !== 400 && response.code !== 500) {
            return dispatch(get_dispute_action(response));
        }
        });
    };
};

export const add_dispute_reply = (data) => {
    let formData = new FormData();
    formData.append("review_id", data.review_id);
    formData.append("flag_content_id", data.flag_content_id);
    formData.append("flag_id", data.flag_id);
    formData.append("comment", data.comment);
    formData.append("embedLinks", data.embedLinks);
  
    for (let i = 0; i < data.dispute_reply_file.length; i++) {
      formData.append("dispute_reply_file", data.dispute_reply_file[i]);
    }
  
    return (dispatch) => {
      callApi(`/api/disputes-history/`, "POST", formData, true).then(
        (response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            if (response.done) {
              toast("Your reply has been added Successfully!", {
                autoClose: 6500,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
              });
            }
          }
          return dispatch(get_dispute_discussion(data.review_id));
        }
      );
    };
  };

export const delete_dispute = (review_id) => {
    return (dispatch) => {
        callApi(`/api/disputes/?review_id=${review_id}`, "DELETE").then(
        (response) => {
            if (response && response.code !== 400 && response.code !== 500) {
            toast(`Dispute has been removed!`, {
                autoClose: 3000,
                className: "black-background",
                bodyClassName: "red-hunt",
                progressClassName: "cc",
            });
            }
        }
        );
    };
};

export const delete_dispute_reply = (pk, review_id) => {
    return (dispatch) => {
      callApi(`/api/disputes-history/?flag_content_id=${pk}`, "DELETE").then(
        (response) => {
          if (response && response.code !== 400 && response.code !== 500) {
            toast(`Reply has been removed!`, {
              autoClose: 3000,
              className: "black-background",
              bodyClassName: "red-hunt",
              progressClassName: "cc",
            });
          }
          return dispatch(get_dispute_discussion(review_id));
        }
      );
    };
  };

export const dispute_modal_status = (params) => {
  return {
    type: DISPUTE_MODAL_STATUS,
    payload: params,
  };
}

export const get_all_disputed_reviews_action = (params) => {
  return {
    type: ALL_DISPUTED_REVIEWS,
    payload: params,
  };
}

export const get_all_disputed_reviews = (type) => {
  return (dispatch) => {
    callApi(`/api/dispute-reviews/?type=${type}`, "GET").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_all_disputed_reviews_action(response));
    }
    });
};
}

export const get_jury_duty_action = (params) => {
  return {
    type: GET_JURY_DUTY_DATA,
    payload: params,
  };
}

export const get_jury_duty = () => {
  return (dispatch) => {
    callApi(`/api/disputes-check-jury/?check_jury_duty=true`, "GET").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_jury_duty_action(response));
    }
    });
};

}

export const jury_duty_action = (data) => {
  return {
    type: GET_JURY_DATA,
    payload: data,
  };
};

export const check_jury_duty = (review_id) => {
  return (dispatch) => {
    callApi(`/api/disputes-check-jury/?review_id=${review_id}`, "GET").then(
      (response) => {
        if (response && response !== 400 && response.code !== 500) {
          return dispatch(jury_duty_action(response));
        }
      }
    );
  };
};

export const cast_jury_vote = (vote_details, review_id) => {
  let data = { vote_details: vote_details };
  return (dispatch) => {
    callApi(`/api/disputes-jury-voting/`, "POST", data).then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        toast(response.status, {
          autoClose: 6500,
          className: "black-background",
          bodyClassName: "red-hunt",
          progressClassName: "cc",
        });
        return dispatch(check_jury_duty(review_id));
      }
    });
  };
};

export const review_details_action = (data) => {
  return {
    type: REVIEW_DATA,
    payload: data,
  };
};

export const get_review_details = (review_id) => {
  return (dispatch) => {
    callApi(`/api/reviews/?id=${review_id}`, "GET").then((response) => {
      if (response && response !== 400 && response.code !== 500) {
        return dispatch(review_details_action(response));
      }
    });
  };
};

export const get_dispute_draft_action = (params) => {
  return {
    type: GET_DISPUTE_DRAFT,
    payload: params,
  };
}

export const get_dispute_draft = (review_id) => {
  return (dispatch) => {
    callApi(`/api/dispute-reviews/?draft=true&review=${review_id}`, "GET").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_dispute_draft_action(response));
    }
    });
  };
}

export const delete_dispute_draft_action = (params) => {
  return {
    type: DELETE_DISPUTE_DRAFT,
    payload: params,
  };
}

export const delete_dispute_draft = (review_id) => {
  return (dispatch) => {
    callApi(`/api/dispute-reviews/?draft=true&review=${review_id}`, "DELETE").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(delete_dispute_draft_action(response));
    }
    });
  };
}

export const delete_administrative_dispute_action = (params) => {
  return {
    type: DELETE_ADMINISTRATIVE_DISPUTE,
    payload: params,
  };
}

export const delete_administrative_dispute = (review_id) => {
  return (dispatch) => {
    callApi(`/api/dispute-reviews/?administrative=true&review=${review_id}`, "DELETE").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(delete_administrative_dispute_action(response));
    }
    });
  };
}

export const get_jury_duty_history_action = (params) => {
  return {
    type: GET_JURY_DUTY_HISTORY,
    payload: params,
  };
}

export const get_jury_duty_history = ( params) => {
  return (dispatch) => {
    callApi(`/api/dispute-jury-duty-history/?type=${params.type}`, "GET").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
      return dispatch(get_jury_duty_history_action(response));
    }
    });
  };
}

export const get_jury_result_action = (params) => {
  return {
    type: GET_JURY_RESULT,
    payload: params,
  };
}

export const get_jury_result = (param) => {
  return (dispatch) => {
    callApi(`/api/dispute-jury-duty-history/?review=${param}&result=true`, "GET").then((response) => {
    if (response && response.code !== 400 && response.code !== 500) {
      return dispatch(get_jury_result_action(response));
    }
    });
  };
}

export const jury_modal_status = (params) => {
    return {
      type: JURY_MODAL_STATUS,
      payload: params,
    };
  }

export const get_administrative_requested_disputes_action = (params) => {
  return {
    type: ADMINISTRATIVE_REVIEWS,
    payload: params,
  };
}

export const get_administrative_requested_disputes = () => {
    return (dispatch) => {
      callApi(`/api/dispute-reviews/?administrative=true&filter=all`, "GET").then((response) => {
      if (response && response.code !== 400 && response.code !== 500) {
        return dispatch(get_administrative_requested_disputes_action(response));
      }
      });
    };
  }

export const handle_admin_request = (params) => {
  return
}

export const get_admin_dispute_discussion_action = (params) => {
  return {
    type: ADMINISTRATIVE_DISPUTED_REVIEW,
    payload: params,
  };    
}

export const get_admin_dispute_discussion = (id) =>  {
    return (dispatch) => {
        callApi(`/api/admin-dispute/?review_id=${id}`, "GET").then((response) => {
            if (response && response.code !== 400 && response.code !== 500) {                
                return dispatch(get_admin_dispute_discussion_action(response));
            }
        });
    };
}

export const get_profile_dispute_count_action = (params) => {
  return {
    type: PROFILE_DISPUTE_COUNT,
    payload: params,
  };    
}

export const get_profile_dispute_count= () =>  {
  return (dispatch) => {
      callApi(`/api/dispute-reviews/?count=true`, "GET").then((response) => {
          if (response && response.code !== 400 && response.code !== 500) {                
              return dispatch(get_profile_dispute_count_action(response));
          }
      });
  };
}

