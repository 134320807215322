import React, { Component }  from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div>
        <div className="wk-static-wrapper">
          <Container>
              <div className="wk-static-sub-head">
                 <h1>Page Not Found</h1>
              </div>             
          </Container>
        </div>
    </div>
    )
}

export default PageNotFound