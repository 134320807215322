import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from "react-redux";
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Button, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Input, InputGroup, InputGroupAddon, DropdownItem, Media, Label, ModalFooter, CustomInput, Form, UncontrolledCollapse } from 'reactstrap';
import CollapseBasic from '../atoms/collapse';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppHeader from '../oragnisms/dashboard/appHeader';
import StarsRating from 'stars-rating';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import EditBtn from "../atoms/editBtn";
import DeleteBtn from "../atoms/deleteBtn";
import { get_id_from_slug, get_type_detail, get_professional_pins, clear_type_details , remove_back_to_list_values, get_uploaded_media, setItemDetailLoading, get_uploaded_media_follow} from '../../actions/itemDetail';
import { get_reviews_listing, delete_review } from '../../actions/accountant';
import CreateReview from '../atoms/createReview';
import DetailReviews from '../oragnisms/accountantDetails/detailReviews';
import About from '../oragnisms/accountantDetails/about';
import LeafletMap from '../oragnisms/accountantDetails/LeafletMap';
import AccountantLocation from '../oragnisms/accountantDetails/accountantLocation';
import dataReview from './reviews';
import ConfirmModal from '../atoms/confirmModal';
import UploadMedia from '../atoms/fileUploadMedia';
import DiscussThisPage from '../oragnisms/movieDetails/discussThisPage';
// import Gallery from '../oragnisms/accountantDetails/gallery';
import Gallery from '../oragnisms/doctorDetails/gallery';
import SocialPinFlag from '../oragnisms/accountantDetails/socialPinFlag';
import TipsAndWarning from '../atoms/tipsAndWarning';
import { callApi } from "../../utils/apiCaller";
import SocialButtonsProfessionals from '../molecules/shared/SocialButtons/socialButtonsProfessionals';

// imports for common left-sidebar
import Background from '../atoms/background';
import FriendsRecommendsThis from '../atoms/friendsRecommendsThis';
import AdditionalInfo from '../atoms/additionalInfo';
import Associations from '../atoms/associations';
import FirstToReview from '../atoms/firstToReview';

import CoWorkers from '../atoms/coWorkers';
import SimillarProfessionals from '../atoms/simillarProfessionals'
import WorkExperience from '../atoms/workExperience'
import { my_profile } from "../../actions/user";
import PublicPageHeader from '../oragnisms/homepage/publicPageHeader';
import DetailedStarRating from '../molecules/shared/DetailedStarRatings/detailedStarRating';
import { setEditExistingMedia } from '../../actions/shared';
import { finalizedMediaInReviewEmbedLinkSetForFollowReview, finalizedMediaInReviewEmbedLinkSetForNewReview, finalizedMediaInReviewMediaSetForNewReview, uploadMediaInReviewEmbedLinkSetForNewReview, uploadMediaInReviewMediaSetForEditReview, uploadMediaInReviewMediaSetForFollowReview, uploadMediaInReviewMediaSetForNewReview } from '../../actions/reviewMedia';
import HelmetMetaData from '../molecules/shared/HelmetMetaData/helmetMetaData';
import { getCategoryDetailDescription, getCategoryDetailTitle } from '../../utils/seo-utilities';
import { getReviewSuffix, highlightSearchTerm } from '../../utils/utilities';

class AccountantDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewProfessionalAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            viewEditReviewStep: 'writeReview',
            viewProfessionalBackgroundType: 'education',
            accountantId: 0,
            accountantDetail: "",
            overallRatingVote: '',
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            enableStickyHeader: false,
            destinationDetails: {
                latitude: 0,
                longitude: 0,
                travelMode: 'DRIVING',
                addressTo: ''
            },
            todestinationDetails: {},
            routes: [],
			typeupload:"Accountants",
			discusstype:"",
            forceShowLoginModal: false,
            mediaSet: [],
            mediaSetFollowUp: [],
            followUpStatus:false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            reviews: [],
            disableCreateReview:true,
            publishReview:false
        }
    }

    resetStates = () => {
        this.setState({
            viewProfessionalAs: 'about',
            viewMediaModalToggle: false,
            createNewDisModalToggle: false,
            mainViewType: 'reviews',
            viewEditReviewStep: 'writeReview',
            viewProfessionalBackgroundType: 'education',
            accountantId: 0,
            accountantDetail: "",
            overallRatingVote: '',
            isEnableConfirmModal: false,
            isEnableFileUpload: false,
            uploadedMediaSet: {
                embedLinks: [],
                uploadedFiles: [],
            },
            uploadedMediaSetFollowUp: {
                embedLinks: [],
                uploadedFiles: [],
            },
            confirmModalText: '',
            confirmModalKey: '',
            confirmModalItemId: null,
            enableStickyHeader: false,
            destinationDetails: {
                latitude: 0,
                longitude: 0,
                travelMode: 'DRIVING',
                addressTo: ''
            },
            todestinationDetails: {},
            routes: [],
            forceShowLoginModal: false,
            mediaSet: [],
            mediaSetFollowUp: [],
            followUpStatus:false,
            newlyUpdatedMedias: [],
            newlyUpdatedEmbedMedias:[],
            deletedMedias:[],
            reviews: [],
            disableCreateReview:true,
            publishReview:false
        })
    }

    toggleConfirmModal = ({ text, isTextDelete, uniqueKey, id }) => {
        let text_str = '';
        if (isTextDelete && !text) {
            text_str = 'Do you want to delete this ?';
        }

        if (text && !isTextDelete) {
            text_str = text
        }

        this.setState({
            isEnableConfirmModal: !this.state.isEnableConfirmModal,
            confirmModalText: text_str,
            confirmModalKey: uniqueKey || '',
            confirmModalItemId: id || null
        });
    }

    getUploadedMedia = ({ mediaSet, links }) => {
        let { uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        let uploadedMediaSetCopy = { ...uploadedMediaSet }
        let uploadedMediaSetFollowUpCopy = {...uploadedMediaSetFollowUp}
        const key = 'id'
        const keyEmbed = 'thumbnail'
        

        if (this.state.reviewOneToggler) {
            if (this.props.isFollowReviewEnable) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.followReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForFollowReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.followReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForFollowReview(embedSetUnique);
                }
            } else {

                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.newReviewMediaSet, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForNewReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            }
        } else {
            if (this.props.isEditReviewEnabled) {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) {
                    const mediaContainer = [...this.props.editReviewMediaSetFinalized, ...mediaSet]
                    const mediaSetUnique = [...new Map(mediaContainer?.map(item =>
                        [item['id'], item])).values()];
                    this.props.uploadMediaInReviewMediaSetForEditReview(mediaSetUnique);
                }
                if (links && Array.isArray(links) && links.length > 0) {
                    const embedContainer = [...this.props.newReviewEmbedLinkSetFinalized, ...links]
                    const embedSetUnique = [...new Map(embedContainer?.map(item =>
                        [item['thumbnail'], item])).values()];
                    this.props.finalizedMediaInReviewEmbedLinkSetForNewReview(embedSetUnique);
                }
            } else {
                if (mediaSet && Array.isArray(mediaSet) && mediaSet.length > 0) { uploadedMediaSetCopy['uploadedFiles'] = uploadedMediaSetCopy['uploadedFiles'].concat(mediaSet); }
                if (links) { uploadedMediaSetCopy['embedLinks'] = uploadedMediaSetCopy['embedLinks'].concat(links); }
                this.setState({ uploadedMediaSet: uploadedMediaSetCopy }, () => { this.props.get_uploaded_media(uploadedMediaSetCopy); });
            }
        }
    
    }

    getuploadUrl = (url) => {
        let { uploadMediaUrl } = this.state;
        uploadMediaUrl = url.uploadUrl;
        this.setState({ uploadMediaUrl });
    }

    componentWillMount() {
        this.hitOnce = false;
        this.checksAndRenderById();
    }

    checksAndRenderById = () => {
        let props = this.props;
        let pathname = props.history.location?.pathname;
        const urlId = props?.match?.params?.id
        let id = props?.history?.location?.state?.id || urlId;
        let serviceParams = props?.match?.params;

        if (id === undefined && serviceParams?.service !== '' && serviceParams?.state !== '' && serviceParams?.city !== '' && serviceParams?.name !== '' && !this.hitOnce) {
            this.hitOnce = true;
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let serviceItems = Object.values(serviceParams).join('/');
            // this.props.get_id_from_slug({ type, slug: serviceItems })
        }

        if (id) {
            this.setState({ accountantId: id, accountantDetails: null, setWallDataItems: {} }, () => {
                window.scrollTo(0, 0)
                this.fetchByItemId({ accountantId: this.state.accountantId });
            });
        }

        if (id === undefined && serviceParams?.id && !this.hitOnce) {
            this.hitOnce = true;
            let items = pathname.split('/');
            let type = items[1] ? items[1] : '';
            let slug = items[2] ? items[2] : '';
            // this.props.get_id_from_slug({ type, slug })

        }
    }

    componentWillReceiveProps(nextProps) {
        let { accountantId } = this.state;

        if (nextProps?.accountant_details) {
            this.setState({ accountantDetail: nextProps?.accountant_details });
        }

        if (nextProps?.back_to_listing) {
            if (nextProps?.back_to_listing?.code === 200 && !accountantId) {
                this.setState({ accountantId: nextProps?.back_to_listing?.entry_id }, () => {
                    this.fetchByItemId({ accountantId: this.state.accountantId });
                });
            }

            if ([404, 400, 500].includes(nextProps?.back_to_listing?.code)) {
                this.props.history.push('/professionals/accountants');
            }
        }

        if (nextProps.get_review_listing && Array.isArray(nextProps.get_review_listing.results)) {
            if (!this.state.initialReviewListing) this.setState({ initialReviewListing: nextProps.get_review_listing.results })

            if (!this.state.initialReviewListing?.length && nextProps.get_review_listing.results?.length)
                this.setState({ initialReviewListing: nextProps.get_review_listing.results })

            if (nextProps.get_review_listing.results.length > 0) {
                this.setState({
                    reviews: nextProps?.get_review_listing?.results
                },()=>{
                    highlightSearchTerm();
                });

                if (!nextProps.get_review_listing.results?.some(item => item?.created_by?.id === this.props?.loggedInUser?.id)) {
                    if (this.state.publishReview) {
                        this.setState({ disableCreateReview: true })
                    } else {
                        if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                    }
                }
            } else if (nextProps.get_review_listing.results.length === 0) {
                this.setState({reviews: nextProps.get_review_listing.results},()=>{
                    highlightSearchTerm();
                })
                if (this.state.publishReview) {
                    this.setState({ disableCreateReview: true })
                } else {
                    if (!this.state.initialReviewListing) this.setState({ disableCreateReview: false })
                }
            }
        } else {
            this.setState({
                reviews: []
            },()=>{
                highlightSearchTerm();
            });
        }

        if(nextProps.auth_data && (nextProps.auth_data !== this.props.auth_data)){
            //this.props.my_profile_info();
            window.location.reload();
        }
        if("isEditReviewEnabled" in nextProps){
            if(this.state.isEditReviewEnabled !==nextProps.isEditReviewEnabled){
                // User has closed thepopup withj publishing
                // revert all the images we just updated.
                this.revertBackTheImages();
            }
            this.setState({isEditReviewEnabled: nextProps.isEditReviewEnabled})
        }
        var currentProductId = this.props.match.params.id;
        var nextProductId = nextProps.match.params.id;
        if(currentProductId!==nextProductId){
            this.fetchByItemId({ accountantId: nextProductId })
            this.forceUpdate();
        }
    }

    revertBackTheImages = () =>{
        const tempReviews = [...this.state.reviews];
        const foundIndex = tempReviews.findIndex(r=> r.id ===this.props.isEditedReviewId)
        
        if(foundIndex >-1){
            let reviewmedia_set_copy =  tempReviews[foundIndex].reviewmedia_set
            this.state.newlyUpdatedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.id===media?.id)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.id!==media.id)
                }                
            })
           
            this.state.newlyUpdatedEmbedMedias.forEach((media)=>{
                if(tempReviews?.[foundIndex]?.reviewmedia_set?.some(ele=>ele?.url===media?.url)){
                    reviewmedia_set_copy = reviewmedia_set_copy.filter(review=>review.url!==media.url)
                }                
            })
            this.state.deletedMedias?.forEach((media)=>{                
                reviewmedia_set_copy.push(media)                              
            })

            tempReviews[foundIndex].reviewmedia_set = reviewmedia_set_copy;

            this.setState({reviews: tempReviews,deletedMedias:[]},()=>{
            })
        }
    }

    componentDidUpdate(prevProps) {
        let nextProps = this.props;
        let { accountantId } = this.state;

        // if (nextProps?.history?.location?.state?.id !== accountantId) {
        //     this.hitOnce = false;
        //     this.checksAndRenderById();
        // }

        // Typical usage (don't forget to compare props):
        if (this.props.current_location !== prevProps.current_location) {
            if (accountantId) {
                this.fetchByItemId({ accountantId })
            }
        }
    }

    componentDidUpdate(prevProps) {
        let nextProps = this.props;
        let { accountantId, loaded, mediaSet,mediaSetFollowUp, uploadedMediaSet,uploadedMediaSetFollowUp } = this.state;
        // Typical usage (don't forget to compare props):
        // if (this.props.current_location !== prevProps.current_location) {
        //     this.hitOnce = false;
        //     this.checksAndRenderById();
        // }


        if (nextProps?.history?.location?.state?.id !== accountantId && loaded === false) {

            this.hitOnce = false;
            this.setState(prevState => ({
                loaded: true
            }), () => { this.checksAndRenderById(); })

        }

        if (this.props.current_location && prevProps.current_location) {
            if ((this.props.current_location.lat !== prevProps.current_location.lat) && (this.props.current_location.lng !== prevProps.current_location.lng)) {
                let { accountantId } = this.state;
                if (accountantId) {
                    this.props.setItemDetailLoading(true);
                    this.fetchByItemId({ accountantId })
                }
            }
        }

        let uploadedFiles = uploadedMediaSet?.uploadedFiles;

        if (Array.isArray(uploadedFiles) && Array.isArray(mediaSet) && uploadedFiles?.length !== mediaSet?.length) {

            this.setState({ mediaSet: uploadedFiles });
        }

        let uploadedFilesFollowUp = uploadedMediaSetFollowUp?.uploadedFiles;

        if (Array.isArray(uploadedFilesFollowUp) && Array.isArray(mediaSetFollowUp) && uploadedFilesFollowUp?.length !== mediaSetFollowUp?.length) {

            this.setState({ mediaSetFollowUp: uploadedFilesFollowUp });
        }

    }

    componentDidMount() {
        this.props.my_profile_info();
        let { accountantId } = this.state;

        if (accountantId) {
            this.fetchByItemId({ accountantId });

        }
        window.addEventListener("scroll", this.handleScroll, { passive: false });
    }

    fetchByItemId = ({ accountantId }) => {
        console.log("fetchByItemIdCalled",accountantId)
        if (accountantId) {
            this.props.setItemDetailLoading(true);
            this.props.get_type_detail({ type: 'accountants', id: accountantId });
            this.props.get_reviews_listing(accountantId)
            this.props.get_professional_pins(accountantId);
        }
    }


    onPublishReview = () => {
        let { accountantId } = this.state;
        this.setState({publishReview:true},()=>{            
            this.fetchByItemId({ accountantId });
        })
        // will call here the fetch reviews list function
    }

    handleOnConfirmModal = () => {
        let { confirmModalKey, accountantDetail, confirmModalItemId, accountantId } = this.state;

        if (confirmModalKey === "delete-accountant-review") {
            let name = accountantDetail?.name;
            this.props.delete_review(confirmModalItemId, accountantId, name , "accountants");
        }

        if (confirmModalKey === "delete-accountant-review-comment" && confirmModalItemId) {
            this.deleteReviewComments(confirmModalItemId);
        }
    }

    deleteReviewComments = async (id) => {
        let { accountantId } = this.state;
        try {
            let url = `/api/profeesionaldeleteuserfeed/?id=${id}&type=comment`;
            let response = await callApi(url, 'DELETE');
            if (response && response.code !== 500) {
                if (accountantId) {
                    this.fetchByItemId({ accountantId })
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleOnClickUploadModalOk = () => {
       
        if (this.state.galleryTypeUpload) {
            this.setState({ callGalleryFunction: true })
        }
    }

    resetUploadedMediaSet = () => {
        const uploadedMediaSet = {
            embedLinks: [],
            uploadedFiles: [],
            mediaSet: [],
            mediaSetFollowUp:[]
        }
        this.setState({ uploadedMediaSet });
    }

    toggleEnableFileUpload = (hideEmbed = false, galleryTypeUpload = false, reviewOneToggler,followUpStatus) => {
        this.setState({ isEnableFileUpload: !this.state.isEnableFileUpload, galleryTypeUpload, hideEmbed });
        this.setState({
            reviewOneToggler: reviewOneToggler? true: false,
            followUpStatus:followUpStatus
        })
    }

    handleDestinationChange = (fromdestinationDetails, toDetail) => {
        this.setState({
            destinationDetails: fromdestinationDetails,
            todestinationDetails: toDetail,
        });
    }

    handleOnChangeRoutes = (routes) => {
        this.setState({
            routes: routes
        })
    }
	
	getnocategory = (discusst) => {
		let { discussType } = this.state;
		if (discusst && discusst != "") {
            this.setState({ discussType:discusst});
        }
	 }

    handleScroll = () => {
        let enableSticky = document.getElementById('enable-sticky');
        let distanceToTop = enableSticky.getBoundingClientRect().top;
        if (distanceToTop < 0) {
            if (this.state.enableStickyHeader !== true) {
                this.setState({ enableStickyHeader: true });
            }
        } else {
            if (this.state.enableStickyHeader !== false) {
                this.setState({ enableStickyHeader: false });
            }
        }
    }

    componentWillUnmount() {
        this.resetStates();
        this.props.clear_type_details();
        this.props.remove_back_to_list_values();
        window.removeEventListener("scroll", this.handleScroll);
    }

    formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        return null
    }

    forceToggleLoginModal = () => {
        this.setState(prevState => ({
          ...this.prevState,
          forceShowLoginModal: !prevState.forceShowLoginModal
        }));
    }

    removeUploadedMediaSet = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSet:{
                    ...prevState.uploadedMediaSet,
                    uploadedFiles:this.state.uploadedMediaSet?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })

        this.setState({deletedMedias:[...this.state.deletedMedias,...[media]]})
    }
    removeUploadedMediaSetFollow = (media)=>{       
        this.setState(prevState=>{
            return {
                ...prevState,
                uploadedMediaSetFollowUp:{
                    ...prevState.uploadedMediaSetFollowUp,
                    uploadedFiles:this.state.uploadedMediaSetFollowUp?.uploadedFiles?.filter(item=>item.id!==media?.id)
                }
            }
        })        
    }

    render() {
        let { accountantId, accountantDetail, overallRatingVote, viewEditReviewStep, reviews, isEnableConfirmModal, isEnableFileUpload, confirmModalText, confirmModalKey, enableStickyHeader,uploadedMediaSet,
            uploadedMediaSetFollowUp,
            followUpStatus } = this.state;

        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }

        const { my_profile, auth_data } = this.props;
        const authId = localStorage.getItem('profileId');

        return (
            <div className="wr-movie-top-banner">
                 <HelmetMetaData
                    title={getCategoryDetailTitle("accountants", this.state.accountantDetail.name || this.state.accountantDetail.firstname )}
                    description={getCategoryDetailDescription(this.state.accountantDetail.name || this.state.accountantDetail.firstname )}
                />
                {isEnableConfirmModal ?
                    <ConfirmModal
                        modalText={confirmModalText}
                        modalKey={confirmModalKey}
                        toggleConfirmModal={this.toggleConfirmModal}
                        handleOnConfirm={this.handleOnConfirmModal}
                    />
                    : null}
                {isEnableFileUpload ?
                    <UploadMedia
                        getUploadedMedia={this.getUploadedMedia}
                        uploadedUrl={this.state.uploadMediaUrl}
                        hideEmbed={this.state.hideEmbed}
                        callbackFunction={this.handleOnClickUploadModalOk}
                        toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                            this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, this.state.reviewOneToggler? true: false);                           
                        }}
						typeUpload={this.state.typeupload}
						discusstype={this.state.discussType}
                    />
                    : null}
                {/* Show/hide on scroll */}
                <div hidden={!enableStickyHeader && accountantDetail}>
                    <div className="bg-darker-shaded text-white p-2 fixed-top" style={{ top: '45px' }}>
                        <Container>
                            <Row>
                                <Col lg={6}>
                                    <div className="d-flex">
                                        <div className="px-2">
                                            <img className="object-fit-cover" width="80" height="80" src={`${accountantDetail && accountantDetail?.professional_profileimage && accountantDetail?.professional_profileimage.length > 0 && accountantDetail?.professional_profileimage[0] && accountantDetail?.professional_profileimage[0].url}`} onError={(error) =>
                                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/circle.png`)
                                            } alt="" />
                                        </div>
                                        <div className="px-2 col">
                                            <div className="d-flex mx-n2 flex-wrap">
                                                <div className="px-2">
                                                    <h2 className="fs-20 text-uppercase">{accountantDetail?.firstname || ''} {accountantDetail?.lastname ? accountantDetail?.lastname : ''}</h2>
                                                </div>
                                                <div className="px-2">
                                                    <img className="mr-2" title={accountantDetail?.total_rating?.avg_rating[1]} src={accountantDetail?.total_rating && accountantDetail?.total_rating?.avg_rating && accountantDetail?.total_rating?.avg_rating[1] !== 'No Rating' ? require(`../../assets/images/icons/star/white/${Math.floor(accountantDetail?.total_rating?.avg_rating[1])}rating.png`) : require('../../assets/images/icons/star/white/norating.png')} alt="" />
                                                    <span className="fs-14">{accountantDetail?.review_count} {getReviewSuffix(accountantDetail?.review_count)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 flex-100">
                                            <ul className="list-unstyled d-flex flex-wrap mx-n1 mt-1 fs-14 ff-alt">
                                                {accountantDetail?.taxonomy_list?.map((tl, i) => (
                                                    <li className="text-uppercase font-weight-bold px-1" key={i}>
                                                        <span>{tl}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="ml-4 mt-2">
                                        <div className="fs-12 d-flex mx-n2">
                                            <div className="px-2 col-auto">
                                                <div className="ff-alt lh-1-2">
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 && accountantDetail.address[0].name ? <>{accountantDetail.address[0].name}<br /></> : ""}
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 ? <>{accountantDetail.address[0].address1}<br /></> : ""}
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 && accountantDetail.address[0].city ? <>{accountantDetail.address[0].city}</> : ""}
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 && accountantDetail.address[0].state ? <>{accountantDetail.address[0].state}</> : ""}
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 && accountantDetail.address[0].zipcode ? <>{accountantDetail.address[0].zipcode}<br /></> : ""}
                                                    {accountantDetail && accountantDetail.address && accountantDetail.address.length > 0 && accountantDetail.address[0].country ? <>{accountantDetail.address[0].country}</> : ""}
                                                </div>
                                            </div>
                                            <div className="px-2 col">
                                                {accountantDetail?.professionalphone_set && accountantDetail?.professionalphone_set.length > 0 ?
                                                    <>{accountantDetail.professionalphone_set.map((phoneData, index) => {
                                                        return (
                                                            <span className="ff-alt d-block" key={index}>
                                                                {phoneData ? this.formatPhoneNumber(phoneData.phone) : ""}
                                                            </span>
                                                        )
                                                    })}</> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                {(my_profile && my_profile.id && authId && (authId == my_profile.id)) ? (<AppHeader isEnableCover={false} logoutToHome={true}/>
                ) : (<PublicPageHeader isEnableCover={false} forceShowLoginModal={this.state.forceShowLoginModal} forceToggleLoginModal={this.forceToggleLoginModal}/>)}
                <header className="position-relative">
                    <div className="featured-blurred-bg no-blurr" style={{ backgroundImage: `url(${accountantDetail && accountantDetail.media_dict && accountantDetail.media_dict.length > 0 && Array.isArray(accountantDetail.media_dict) && accountantDetail.media_dict[0] ? accountantDetail.media_dict[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/circle.png'})` }}></div>
                    <TabContent className={(this.state.viewProfessionalAs === 'gallery') ? 'bg-dark-shaded-sm' : ''} activeTab={this.state.viewProfessionalAs} style={{ minHeight: '500px' }}>
                        <TabPane tabId="about">
                            <About
                                accountantDetail={this.state.accountantDetail !== null ? this.state.accountantDetail : ""}
                                accountantId={this.state.accountantId}
                            />
                        </TabPane>
                        <TabPane tabId="gallery">
                            <Gallery professionalDetails={this.props.accountant_details} toggleEnableFileUpload={this.toggleEnableFileUpload}
                                uploadedMediaSet={this.state.uploadedMediaSet}
                                callGalleryFunction={this.state.callGalleryFunction}
                                resetGalleryFunctionToggle={() => this.setState({ callGalleryFunction: false })}
                                getuploadUrl={this.getuploadUrl} resetUploadedMediaSet={this.resetUploadedMediaSet} forceToggleLoginModal={this.forceToggleLoginModal}
                                mainTaxonomy={"accountants"}/>
                        </TabPane>
                        <TabPane tabId="location">
                            {this.state.viewProfessionalAs === 'location' && (
                                <div className="featured-map">
                                    <LeafletMap
                                        accountantDetails={this.state.accountantDetail !== null ? this.state.accountantDetail : ""}
                                        accountantId={this.state.accountantId}
                                        currentLocationData={this.props.current_location}
                                        destinationDetails={this.state.destinationDetails}
                                        todestinationDetails={this.state.todestinationDetails}
                                        handleOnChangeRoutes={this.handleOnChangeRoutes}
                                    />
                                </div>
                            )}

                            {this.props.accountant_details && (
                                <AccountantLocation
                                    accountantDetails={this.state.accountantDetail !== null ? this.state.accountantDetail : ""}
                                    accountantId={this.state.accountantId}
                                    todestinationDetails={this.state.todestinationDetails}
                                    destinationDetails={this.state.destinationDetails}
                                    routes={this.state.routes}
                                    handleDestinationChange={this.handleDestinationChange}
                                />
                            )}
                        </TabPane>
                    </TabContent>
                    <Container>
                        <Nav className="featured position-relative bg-white" justified pills style={{ marginTop: '-54px', zIndex: 3 }}>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "about"}
                                    onClick={() => {
                                        this.setState({ viewProfessionalAs: "about" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'About'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "gallery"}
                                    onClick={() => {
                                        this.setState({ viewProfessionalAs: "gallery" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Gallery'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100 margin-right-2">
                                <NavLink className="text-truncate" href="#"
                                    active={this.state.viewProfessionalAs === "location"}
                                    onClick={() => {
                                        this.setState({ viewProfessionalAs: "location" })
                                    }}>
                                    <span className="font-weight-bold text-uppercase">
                                        {'Location'}
                                    </span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="mw-100">
                                {/* <SocialPinFlag accountantDetails={this.props.accountant_details} forceToggleLoginModal={this.forceToggleLoginModal}/> */}
                                <SocialButtonsProfessionals categoryId={this.state.accountantId} showHistoryTab categoryType="accountants" categoryDetails={this.props.accountant_details} forceToggleLoginModal={this.forceToggleLoginModal} bgLight={false} />
                            </NavItem>
                        </Nav>
                    </Container>
                </header>

                {/* Main Content */}
                <section className="py-4 fs-14" id="enable-sticky">
                    <Container>
                        <Row>
                            <Col md={4}>
                                {accountantDetail?.content_type_name !== "actors" && (
                                    <div className="text-dark p-3 mb-3" style={{ backgroundColor: '#d5d6e5' }}>
                                        <h3 className="mb-2">
                                            {`Are you ${accountantDetail?.firstname !== undefined ?
                                                `${accountantDetail?.firstname || ""} ${accountantDetail?.lastname || ""} ?`
                                                : accountantDetail?.name !== undefined ? `${accountantDetail?.name} ?` : ''}`}
                                        </h3>
                                        <div className="mt-4 text-right">
                                            <Button size="sm" color="tertiary">claim this profile</Button>
                                        </div>
                                    </div>
                                )}

                                {/* Detailed Star Rating */}
                                
                                <DetailedStarRating
                                    total_rating={accountantDetail?.total_rating || null}
                                    id={accountantId}
                                    bgLight={true}
                                    charts={{ bar: true, line: true, genderTable: true }} professionals /> 

                                {/* Co-workers */}
                                <CoWorkers
                                    random={Math.random()}
                                    id={accountantId}
                                    listing_type={"accountants"}
                                    itemDetail={accountantDetail}
                                    fetchByItemId={this.fetchByItemId}
                                    data={accountantDetail?.professionalsprofessional_set}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Background */}
                                <Background
                                    id={accountantId}
                                    uniqueDeleteKey={'delete-background'}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    categoryType={"accountant"}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Friends Recommendation */}
                                <FriendsRecommendsThis
                                    id={accountantId}
                                    listing_type={'accountants'}
                                />

                                {/* Similar Professionals */}
                                <SimillarProfessionals
                                    id={accountantId}
                                    listing_type={'accountants'}
                                />

                                {/* Tips & Warnings */}
                                <TipsAndWarning
                                    setCollapse={true}
                                    id={this.state.accountantId} name={this.state.accountantDetail} forceToggleLoginModal={this.forceToggleLoginModal}/>


                                {/* Additional Information */}
                                <AdditionalInfo
                                    random={Math.random()}
                                    id={accountantId}
                                    listing_type={"accountants"}
                                    fetchByItemId={this.fetchByItemId}
                                    additional_info={accountantDetail?.additional_info}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Associations */}
                                <Associations
                                    id={accountantId}
                                    listing_type={"accountants"}
                                    fetchByItemId={this.fetchByItemId}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* Possible Employer Affiliation */}
                                <WorkExperience
                                    type={"affiliation"}
                                    id={accountantId}
                                    listing_type={"accountants"}
                                    possibleEmployee={true}
                                    title={"Possible Employer Affiliation"}
                                    fetchByItemId={this.fetchByItemId}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}            
                                />

                                {/* Work Experience */}
                                <WorkExperience
                                    type={"affiliation"}
                                    id={accountantId}
                                    listing_type={"accountants"}
                                    title={"Work Experience"}
                                    fetchByItemId={this.fetchByItemId}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}
                                />

                                {/* First to review */}
                                <FirstToReview
                                    itemDetail={accountantDetail}
                                />

                                {/* Discuss This Page */}
                                <DiscussThisPage
                                    id={accountantId}
                                    isLight={true}
                                    profdiscussion={true}
                                    getUploadedMedia={this.getUploadedMedia}
                                    toggleEnableFileUpload={this.toggleEnableFileUpload}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
									getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                />
                                <div className="bg-white text-center">
                                    <a href="http://wikireviews.com/blog" rel="noopener noreferrer" target="_blank">
                                        <img className="img-fluid blog-img" src="https://userdatawikireviews.s3.amazonaws.com/images/house_ads_heraldblog_02.png" alt="Go to WikiReviews Herald" />
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                {/* // Here Details and Reviews */}
                                                
                                {!(accountantDetail?.is_logged_user_review ?? true) && 
                                <CreateReview
                                    itemDetail={accountantDetail}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    listing_type={'accountants'}
                                    reviews={reviews}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, true, false);                                       
                                    }}
                                    uploadedMediaSet={this.state.uploadedMediaSet}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    onPublishReview={this.onPublishReview}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                    my_profile_data={this.props.my_profile}  
                                    categoryId={accountantDetail?.id}   
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}       
                                />}

                                <DetailReviews
                                    uniqueDeleteKey={'delete-accountant-review'}
                                    uniqueDeleteCommentKey={'delete-accountant-review-comment'}
                                    overallRatingVote={overallRatingVote}
                                    viewEditReviewStep={viewEditReviewStep}
                                    mainViewType={this.state.mainViewType}
                                    onPublishReview={this.onPublishReview}
                                    listing_type={'accountants'}
                                    reviews={reviews}
                                    item_detail={this.props.accountant_details}
                                    uploadedMediaSet={uploadedMediaSet}
                                    uploadedMediaSetFollow={uploadedMediaSetFollowUp}
                                    removeUploadedMediaSet={this.removeUploadedMediaSet}
                                    removeUploadedMediaSetFollow={this.removeUploadedMediaSetFollow}
                                    toggleConfirmModal={this.toggleConfirmModal}
                                    toggleEnableFileUpload={(hideEmbed, galleryTypeUpload,newReviewStatus, followUpStatus)=>{
                                        this.toggleEnableFileUpload(hideEmbed, galleryTypeUpload, newReviewStatus,followUpStatus);                                       
                                    }}
                                    getuploadUrl={this.getuploadUrl}
                                    resetUploadedMediaSet={this.resetUploadedMediaSet}
									getnocategory={this.getnocategory}
                                    forceToggleLoginModal={this.forceToggleLoginModal}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* View Media Modal */}
                <Modal isOpen={this.state.viewMediaModalToggle} toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}>
                    <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader>
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}>
                            <div className="item h-100 d-flex flex-column justify-content-center">
                                <div className="text-center">
                                    <img className="img-fluid" src="https://static.vecteezy.com/system/resources/thumbnails/000/163/568/small/CPA-01.jpg" alt="" />
                                </div>
                            </div>
                        </Carousel>
                    </ModalBody>
                </Modal>

                {/* Create a new discussion Modal */}
                <Modal isOpen={this.state.createNewDisModalToggle} toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                    <ModalHeader className="px-0" toggle={() => this.setState({ createNewDisModalToggle: !this.state.createNewDisModalToggle })}>
                        CREATE NEW DISCUSSION
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input className="border text-dark ph-grey" bsSize="sm" type="text" placeholder="Enter title for discussion" />
                        </FormGroup>
                        <FormGroup>
                            <Input className="border text-dark ph-grey font-weight-bold" bsSize="sm" type="textarea" rows="10" />
                        </FormGroup>
                        <div>
                            <Button color="transparent" size="sm" title="Upload Media"><FontAwesomeIcon icon="camera" /></Button>
                        </div>

                        {/* Uploaded media shown here */}
                        <div className="border mb-3">
                            <Row xs={2} sm={3} md={4} lg={5} noGutters>
                                <Col>
                                    <div className="d-flex pr-3 m-2">
                                        <div>
                                            <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                                        </div>
                                        <div className="mx-n3 mt-2">
                                            <Button color="delete-media _alt" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex pr-3 m-2">
                                        <div>
                                            <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                                        </div>
                                        <div className="mx-n3 mt-2">
                                            <Button color="delete-media _alt" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="d-flex pr-3 m-2">
                                        <div>
                                            <img className="img-fluid img-thumbnail" src={'https://stagingdatawikireviews.s3.amazonaws.com/media/content/images.82805121a31b59ba88646a19ca8044f136f8855c.png'} alt="Uploaded media" />
                                        </div>
                                        <div className="mx-n3 mt-2">
                                            <Button color="delete-media _alt" size="sm" title="Remove Media"><FontAwesomeIcon icon="trash-alt" /> </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <Button color="primary">Submit</Button>
                    </ModalFooter>
                </Modal>

                {/* Share By Email Modal Popup */}
                <Modal
                    size="sm"
                    isOpen={this.state.shareByEmailModalToggle}
                    toggle={() => this.setState({
                        shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                    })}>
                    <ModalHeader
                        className="text-uppercase px-0"
                        toggle={() => this.setState({
                            shareByEmailModalToggle: !this.state.shareByEmailModalToggle
                        })}>
                        {'Share by Email'}
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="text" name="sharer_name" placeholder="Your name" />
                        </FormGroup>
                        <FormGroup>
                            <Input className="primary" bsSize="sm" type="email" name="email" placeholder="Recipient's email" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter className="px-0">
                        <div className="text-right">
                            <Button color="primary">Share</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapState = (state) => ({
    loggedInUser: state.user.my_profile,
    accountant_details: state.item_detail.item_details, // from item_details
    back_to_listing: state.item_detail.back_to_listing, // from item_details
    current_location: state.home.current_location,
    get_review_listing: state.accountant.get_review_listing,
    my_profile: state.user.my_profile,
    auth_data: state.auth.auth_login,
    editExistingMedia : state.shared.editExistingMedia,
    editExistingEmbedMedia : state.shared.editExistingEmbedMedia,
    isEditedReviewId: state.shared.isEditedReviewId,
    isEditReviewEnabled: state.shared.isEditReviewEnabled,
    newReviewMediaSet: state.reviewMedia.newReviewMediaSet,
    newReviewMediaSetFinalized: state.reviewMedia.newReviewMediaSetFinalized,
    newReviewEmbedLinkSet: state.reviewMedia.newReviewEmbedLinkSet,
    newReviewEmbedLinkSetFinalized: state.reviewMedia.newReviewEmbedLinkSetFinalized,
    editReviewMediaSetFinalized:state.reviewMedia.editReviewMediaSetFinalized,
    isFollowReviewEnable:state.shared.isFollowReviewEnable,
    followReviewMediaSet: state.reviewMedia.followReviewMediaSet,     
    followReviewEmbedLinkSetFinalized: state.reviewMedia.followReviewEmbedLinkSetFinalized,
});

const mapProps = (dispatch) => ({
    get_id_from_slug: ({ type, slug }) => dispatch(get_id_from_slug({ type, slug })), // from item_details
    get_type_detail: ({ type, id }) => dispatch(get_type_detail({ type, id })), // from item_details
    get_professional_pins: (id) => dispatch(get_professional_pins(id)),
    get_reviews_listing: (id, type) => dispatch(get_reviews_listing(id, type)),
    delete_review: (id, item_id, name, type) => dispatch(delete_review(id, item_id, name,type)),
    clear_type_details: () => dispatch(clear_type_details()),
    my_profile_info: () => dispatch(my_profile()),
    remove_back_to_list_values: () => dispatch(remove_back_to_list_values()),
    get_uploaded_media: (data) => dispatch(get_uploaded_media(data)),
    get_uploaded_media_follow: (data) => dispatch(get_uploaded_media_follow(data)),
    setItemDetailLoading:(data)=>dispatch(setItemDetailLoading(data)),
    setEditExistingMedia:(data)=>dispatch(setEditExistingMedia(data)),
    uploadMediaInReviewMediaSetForNewReview: (data) => dispatch(uploadMediaInReviewMediaSetForNewReview(data)),
    uploadMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(uploadMediaInReviewEmbedLinkSetForNewReview(data)),
    finalizedMediaInReviewMediaSetForNewReview: (data) => dispatch(finalizedMediaInReviewMediaSetForNewReview(data)),
    finalizedMediaInReviewEmbedLinkSetForNewReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForNewReview(data)),
    uploadMediaInReviewMediaSetForEditReview: (data) => dispatch(uploadMediaInReviewMediaSetForEditReview(data)),
    uploadMediaInReviewMediaSetForFollowReview: (data) => dispatch(uploadMediaInReviewMediaSetForFollowReview(data)),
    finalizedMediaInReviewEmbedLinkSetForFollowReview: (data) => dispatch(finalizedMediaInReviewEmbedLinkSetForFollowReview(data)),
});


export default withRouter(connect(mapState, mapProps)(AccountantDetails));
