import * as actions from "../actions/communityReviewProjects";
const initialState = {
    community_review_projects_data: null,
    project_data: null,
    project_responses: null,
    project_taxonomies: [],
    project_subcategories: [],
    project_subcategories_level: [],
    project_questions: [],
    friends: null,
    similar_projects: null
};

const communityReviewProjects = (state = initialState, action) => {
    switch (action.type) {

        case 'SET_INITIAL_AUTH_NEW':
            return initialState;
            
        case actions.GET_COMMUNITY_REVIEW_PROJECTS:
            state.community_review_projects_data = action.payload
            return { ...state };

        case actions.GET_PROJECT:
            state.project_data = action.payload
            return { ...state };

        case actions.GET_PROJECT_RESPONSES:
            state.project_responses = action.payload
            return { ...state };

        case actions.GET_PROJECT_TAXONOMIES:
            state.project_taxonomies = action.payload
            return { ...state };

        case actions.GET_PROJECT_SUBCATEGORIES:
            state.project_subcategories = action.payload
            return { ...state };

        case actions.GET_PROJECT_SUBCATEGORIES_LEVEL:
            state.project_subcategories_level = action.payload
            return { ...state };

        case actions.GET_CATEGORY_QUESTIONS:
            state.project_questions = action.payload;
            return { ...state };
            
        case actions.GET_FRIENDS:
            state.friends = action.payload;
            return { ...state };
            
        case actions.GET_SIMILAR_PROJECTS:
            state.similar_projects = action.payload;
            return { ...state };
        
        case actions.DELETE_PROJECT_RESPONSES:
            return { ...state,communityReviewProjects: 
                {...state.communityReviewProjects, project_responses: { ...state.community_review_projects_data.project_responses ,results: action.payload} }  };
        
        case actions.PUBLISH_PROJECT_RESPONSES:
            return { ...state,communityReviewProjects: 
                {...state.communityReviewProjects, project_responses: { ...state.community_review_projects_data.project_responses ,results: action.payload} }  };


        default:
            return state;
    }
}

export default communityReviewProjects;