import React, { Component } from 'react';
import {  Container, Modal, ModalBody} from 'reactstrap';
import Carousel from 'react-multi-carousel';
import FancyVideo from 'react-videojs-fancybox';
import { Link } from "react-router-dom";
import { callApi, responseCodeValidator } from '../../../utils/apiCaller';
class Filmography extends Component {
    constructor(props) {
        super(props);
		this.state = {
            actorId:null,
			filmoGraphy: null,
			about:null,
			category:"movies",
            isLoading: false
		}
        
    }

  componentWillReceiveProps(nextProps) {
        const { aboutDetails } = nextProps;
        const {actorId } = this.state;
        if (aboutDetails) {
            this.setState({
                about: aboutDetails,
                actorId: aboutDetails?.id,
            }, () => {
                if (actorId != aboutDetails?.id) {
                    this.fetchFilmoGraphyFromId({ id: aboutDetails?.id })
                }
            });
        }
	}
	
    fetchFilmoGraphyFromId = ({ id }) => {
        if (id) {
            this.setState({ isLoading: true });
            callApi(`/api/filmography/?listing_id=${id}`, 'GET').then((response) => {
                if (response && responseCodeValidator(String(response.code))) {
                    this.setState({ isLoading: false, filmoGraphy: response });
                } else {
                    this.setState({ isLoading: false, filmoGraphy: null });
                }
            });
        }
    }

    resetState = () => {
        this.setState({
            actorId: null,
            filmoGraphy: null,
            about: null,
            category: "movies",
            isLoading: false
        });
    }

    componentWillUnmount() {
        this.resetState();
    }

	openSecondCarousal = (files, index) => {
        // const carouselMedia = files;
        // const array1 = index ? carouselMedia.filter((item, i) => i < index) : [];
        // const array2 = (index !== carouselMedia.length - 1) ? carouselMedia.filter((item, i) => i > index) : [];
        // const newArray = [carouselMedia[index], ...array2, ...array1];

        this.setState({ secondCarouselMedia: files, viewMyPhotosModalnew: true });
    }	

    render() {
		const { filmoGraphy,isLoading } = this.state;
        const responsive = {
            videoModal: {
                mobile: {
                    breakpoint: { max: 9999999, min: 0 },
                    items: 1
                }
            },
            viewGalleryImages: {
                desktop: {
                    breakpoint: { max: 9999999, min: 1200 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1199, min: 576 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 575, min: 0 },
                    items: 1
                }
            }
        }
       return (
            <Container>
				<div className="text-white"
					style={{ height: '450px' }}>
					<Carousel
						responsive={responsive.viewGalleryImages}
						infinite={true}
						swipeable={true}
						showDots={false}
						arrows={true}
						autoPlay={true}
						autoPlaySpeed={5000}
						containerClass="mx-n2 h-100"
						sliderClass={`${filmoGraphy?.length === 0}` ? "mx-auto" : ""}>
						{(filmoGraphy && filmoGraphy.length > 0) ? (
						filmoGraphy.map((film, i) => {
						  return (
						<div className="item px-2 fs-14">
							<div className={`${film.trailer && Object.keys(film.trailer).length > 0 ? 'video-holder' : ''} text-center`} role="button" onClick={() => film.trailer && Object.keys(film.trailer).length > 0 && this.openSecondCarousal([film.trailer], i+1)}>
							<img src={`${film && film.media_dict && film.media_dict.length > 0 && Array.isArray(film.media_dict) && film.media_dict[0] ? film.media_dict[0].url : 'https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png'}`}
                                className="img-fluid object-fit-contain fixed-height-img is-160" 
                                onError={(error) =>
                                (error.target.src = `https://userdatawikireviews.s3.amazonaws.com/images/product-img2.png`)
                                }
                                alt={`${film && film.media_dict && film.media_dict.length > 0 && Array.isArray(film.media_dict) && film.media_dict[0].caption ? film.media_dict[0].caption : 'no-image'}`}
							/>
							</div>
							<div className="text-center mt-3">
								<Link to={{ pathname: `/${this.state.category}/${film.slug && film.slug != "" ? film.slug : null}`, state: { id: film?.id } }}
														className="text-reset font-weight-bold">{film && film?.name}</Link>
								<div className="mt-2">
									<span className="fs-13">{film && film?.additional_info && film.additional_info.duration ? film.additional_info.duration[0].value + "|" : ""}  {film && film?.additional_info && film.additional_info.rating ? film.additional_info.rating[0].value + "|" : ""} | {film && film?.movie_release_date}</span>
								</div>
								<div className="mt-2">
								   <img src={`https://userdatawikireviews.s3.amazonaws.com/images/star/blue/${film && film?.total_rating && film?.total_rating.avg_rating[0] ? film.total_rating.avg_rating[0] : 'norating.png'}`}
                                                                className="img-fluid"
                                                                onError={(error) =>
                                                                    (error.target.src = `../../../assets/images/norating.png`)
                                                                }
                                                                alt={`${film && film?.total_rating && film?.total_rating.avg_rating[1] ? film.total_rating.avg_rating[1] : 'no-rating'}`}
									/>
									<span>&nbsp;</span>
									<span>{(film && film.total_rating && film.total_rating.total_review) ? film.total_rating.total_review : 0} reviews</span>
								</div>
							</div>
						</div>
						);
                        })
                       ) : (isLoading) ?
                           (
                               <div className="item px-4 fs-14">
                                   <div className="d-flex flex-column align-items-center justify-content-center">
                                       <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                           <span>Loading Filmography...</span>
                                       </div>
                                   </div>
                               </div>
                           )
                           : (
                               <div className="item px-4 fs-14">
                                   <div className="d-flex flex-column align-items-center justify-content-center">
                                       <div className="px-5 py-4 text-center bg-dark-shaded-sm ff-base text-white fs-14">
                                           <span>No result found</span>
                                       </div>
                                   </div>
                               </div>
                           )}
					</Carousel>
				</div>

				{/* View Media Modal */}
                <Modal
                    centered
                    isOpen={this.state.viewMyPhotosModalnew}
                    toggle={() => {
                        this.setState({ viewMyPhotosModalnew: !this.state.viewMyPhotosModalnew })
                    }}>
                    {/* <ModalHeader toggle={() => this.setState({ viewMediaModalToggle: !this.state.viewMediaModalToggle })}></ModalHeader> */}
                    <ModalBody className="bg-transparent p-0">
                        <Carousel
                            responsive={responsive.videoModal}
                            infinite={true}
                            autoPlay={false}
                            swipeable={true}
                            showDots={false}
                            arrows={true}
                            sliderClass={true ? "mx-auto" : ""}>
                            {/* Repeat this */}
                            {this.state.secondCarouselMedia && this.state.secondCarouselMedia.length > 0 && this.state.secondCarouselMedia.map((med, i) => {
                                return (
                                <div className="item fs-14 h-100 d-flex flex-column justify-content-center"
                                    key={med.id}
                                >
                                    <div>
                                        <div className="d-flex text-white justify-content-between mt-3">
                                            <div className="mr-2 ff-alt">{med.caption}</div>
                                            <div className="ff-base">{med.label}</div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-3 d-flex flex-column justify-content-center align-items-center media-min-height-container">
                                        {(med.media_type === "image" || med.type === "") && ( 
                                            <img
                                                className="img-fluid shown-media"
                                                src={med.url}
                                                alt=""
                                            />
                                        )}
                                        {med.media_type === "video" && (
                                            <video className="img-fluid shown-media" controls>
                                                <source src={med.url} type="video/mp4" />
                                                {'Your browser does not support the video tag.'}
                                            </video>
                                        )}
                                    </div>
                                    <div className="px-2 mt-3">
                                        <div className="d-flex flex-nowrap mx-n2">
                                            <div className="px-2 col d-flex flex-nowrap">
                                                <div className="mr-2">
                                                    <img
                                                        className="img-circle _30x30"
                                                        src={med?.user?.user?.current_profile_pic
                                                            ? med?.user?.user?.current_profile_pic
                                                            : "https://userdatawikireviews.s3.amazonaws.com/media/content/profilecolor.487afd1723d9e9a9c1574d1a6bf9a8d672d6821c.png"}
                                                        alt=""
                                                    />
                                                    
                                                </div>
                                                <div>
                                                    <div className="text-primary ff-base">
                                                        {` Uploaded by `}
                                                        <a className="text-reset" href="#">
                                                            <span className="font-weight-bold">
                                                                {med.user?.user.first_name} {med.user?.user.last_name}
                                                                        </span>{" "}
                                                        </a>
                                                    </div>
                                                    <div className="text-tertiary ff-base">
                                                        {med.upload_date}
                                                                </div>
                                                </div>
                                            </div>
                                            <div className="px-2 col-auto">
                                                <h2 className="fs-38" style={{ color: "#d4dae6" }}>
                                                    {i+1} of {this.state.secondCarouselMedia.length}
                                                </h2>
                                            </div>
                                            <div className="px-2 col">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            )})}
                        </Carousel>
                    </ModalBody>
                </Modal>
			</Container>
        )
    }
}



export default Filmography;
